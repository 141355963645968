import { Component, OnInit } from '@angular/core';
import { MovementsService } from '../../providers/movements.service';
import { Movement } from '../../interfaces/movement';
import { CategoriesService } from '../../providers/categories.service';
import { ProductsService } from '../../providers/products.service';
import { BrandsService } from '../../providers/brands.service';
import { Brand } from 'src/app/interfaces/brand.interface';
import { Product } from '../../interfaces/product.interface';
import { Categorie } from '../../interfaces/categorie.interface';

@Component({
  selector: 'app-popular-products',
  templateUrl: './popular-products.component.html',
  styleUrls: ['./popular-products.component.scss'],
})
export class PopularProductsComponent implements OnInit {
  movements: Movement[];
  brands: Brand[];
  products: Product[] = this._productsService.getProductsFakeData();
  categories: Categorie[];
  loading: boolean;

  constructor(
    private _movementsService: MovementsService,
    private _categoriesService: CategoriesService,
    private _productsService: ProductsService,
    private _brandsService: BrandsService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.getCollections();
  }

  getMovements() {
    this._movementsService.getMovements().subscribe((movements) => {
      this.movements = movements.map((m, i) => {
        return this.mapMovement(m, i);
      });
      this.sortByPopularProducts();
    });
  }

  mapMovement(movement: Movement, i: number): Movement {
    return {
      ...movement,
      uid: !movement.uid ? movement.id : movement.uid,
      costByUnit: Number(movement.costByUnit),
      productsNumber: Number(movement.productsNumber),
      type: Number(movement.type),
      index: i,
      categorieUid: this.getCategorieUid(movement.productUid),
    };
  }

  getCategorieUid(productUid: string): string {
    const product = this.products.find((p) => {
      return p.uid === productUid;
    });
    if (product && product.categorieUid) {
      return product.categorieUid;
    }
    return '';
  }

  getCollections() {
    this._categoriesService.getCategories().subscribe((cats) => {
      this.categories = cats;
    });
    this._brandsService.getBrands().subscribe((brands) => {
      this.brands = brands;
    });
    this._productsService.getProductsAvailables().subscribe((products) => {
      this.products = products.map((p) => {
        const product = {
          ...p,
          name: `
          <span class="badge bg-dark text-white">${this._brandsService.getBrandName(
            p.brandUid,
            this.brands
          )}</span> ${p.name}`,
          categorieName: ` ${this._categoriesService.getCategorieNamePlural(
            p.categorieUid,
            this.categories
          )}`,
        };
        return product;
      });
      this.loading = false;
    });
    this.getMovements();
  }

  sortByPopularProducts() {
    this.products = this.products.filter((p) => {
      if (this.categorieIsAvailable(p.categorieUid)) {
        return p;
      }
    });
    this.products = this.products.map((p) => {
      const productSales: Movement[] = this.movements.filter(
        (m) => m.productUid === p.uid && m.type == 3
      );
      const sumWithInitial = productSales.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.productsNumber,
        0
      );

      return { ...p, numberOfSales: sumWithInitial };
    });
    this.products = this.products.sort((a, b) => {
      return b.numberOfSales - a.numberOfSales;
    });
    this.products = this._productsService.mapProductsPhotoUrlData(
      this.products
    );
  }

  categorieIsAvailable(categorieUid: string): boolean {
    const value: boolean = this.categories.find(
      (c) => c.uid === categorieUid
    ).show;
    return value;
  }
}
