import { Component, Input, OnInit } from '@angular/core';
import { AlertsService } from '../../providers/alerts.service';
import { Product } from '../../interfaces/product.interface';
import { ProductPhotosService } from '../../providers/product-photos.service';
import { FileUpload } from '../../models/fileUpload.model';

@Component({
  selector: 'app-product-gallery',
  templateUrl: './product-gallery.component.html',
  styleUrls: ['./product-gallery.component.scss'],
})
export class ProductGalleryComponent implements OnInit {
  @Input() modal: any;
  @Input() product: Product;
  urlImgOne: any;
  urlImgTwo: any;
  urlImgThree: any;
  urlImgFour: any;
  imgOne: any;
  imgTwo: any;
  imgThree: any;
  imgFour: any;
  fileUploadOne?: FileUpload;
  fileUploadTwo?: FileUpload;
  fileUploadThree?: FileUpload;
  fileUploadFour?: FileUpload;
  photo1: FileUpload;
  photo2: FileUpload;
  photo3: FileUpload;
  photo4: FileUpload;
  loading: boolean = false;

  constructor(
    private _alertsService: AlertsService,
    private _productPhotosService: ProductPhotosService
  ) {}

  ngOnInit(): void {
    this.getProductPhotosByProductUid();
  }

  selectFile(event: any, imgNumber: number) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      // this.msg = 'You must select an image';
      return;
    }

    const mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      // this.msg = "Only images are supported";
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    const selectedFiles = event.target.files;
    const fileSelected = selectedFiles.item(0);

    reader.onload = (_event) => {
      try {
        if (imgNumber === 1) {
          this.urlImgOne = reader.result;
          this.imgOne = fileSelected;
        }
        if (imgNumber === 2) {
          this.urlImgTwo = reader.result;
          this.imgTwo = fileSelected;
        }
        if (imgNumber === 3) {
          this.urlImgThree = reader.result;
          this.imgThree = fileSelected;
        }
        if (imgNumber === 4) {
          this.urlImgFour = reader.result;
          this.imgFour = fileSelected;
        }
      } catch (error) {
        console.error('Error during file loading:', error);
      }
    };
  }

  async deletePicture(picture: number) {
    const alert: any = await this._alertsService.confirmation();

    if (alert.isConfirmed) {
      if (picture === 1 && !this.photo1)
        this.urlImgOne = null && this.resetData();
      if (picture === 2 && !this.photo2)
        this.urlImgTwo = null && this.resetData();
      if (picture === 3 && !this.photo3)
        this.urlImgThree = null && this.resetData();
      if (picture === 4 && !this.photo4)
        this.urlImgFour = null && this.resetData();
      if (picture === 1 && this.photo1) {
        this.deleteProductPhoto(this.photo1);
      }
      if (picture === 2 && this.photo2) {
        this.deleteProductPhoto(this.photo2);
      }
      if (picture === 3 && this.photo3) {
        this.deleteProductPhoto(this.photo3);
      }
      if (picture === 4 && this.photo4) {
        this.deleteProductPhoto(this.photo4);
      }
    }
  }

  uploadAll(): void {
    if (this.loading === false) {
      if (this.imgOne) {
        this.fileUploadOne = new FileUpload(this.imgOne);
        this.uploadFile(this.fileUploadOne, 1);
      }
      if (this.imgTwo) {
        this.fileUploadTwo = new FileUpload(this.imgTwo);
        this.uploadFile(this.fileUploadTwo, 2);
      }
      if (this.imgThree) {
        this.fileUploadThree = new FileUpload(this.imgThree);
        this.uploadFile(this.fileUploadThree, 3);
      }
      if (this.imgFour) {
        this.fileUploadFour = new FileUpload(this.imgFour);
        this.uploadFile(this.fileUploadFour, 4);
      }
    }
  }

  uploadFile(fileToUpload: FileUpload, photoNumber: number) {
    this._productPhotosService
      .pushFileToStorage(fileToUpload, this.product.uid, photoNumber)
      .subscribe(
        (percentage) => {
          this.loading = true;
          if (photoNumber === 1)
            this.fileUploadOne.percent = fileToUpload.percent = Math.round(
              percentage ? percentage : 0
            );
          if (photoNumber === 2)
            this.fileUploadTwo.percent = fileToUpload.percent = Math.round(
              percentage ? percentage : 0
            );
          if (photoNumber === 3)
            this.fileUploadThree.percent = fileToUpload.percent = Math.round(
              percentage ? percentage : 0
            );
          if (photoNumber === 4)
            this.fileUploadFour.percent = fileToUpload.percent = Math.round(
              percentage ? percentage : 0
            );
          if (Math.round(percentage ? percentage : 0) === 100) {
            this.getProductPhotosByProductUid();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getProductPhotosByProductUid() {
    this._productPhotosService
      .getProductPhotosByProductUid(this.product.uid)
      .subscribe((photos: FileUpload[]) => {
        this.photo1 = photos.find((p) => p.photoNumber === 1);
        this.photo2 = photos.find((p) => p.photoNumber === 2);
        this.photo3 = photos.find((p) => p.photoNumber === 3);
        this.photo4 = photos.find((p) => p.photoNumber === 4);
        this.resetData();
      });
  }

  async deleteProductPhoto(filePhoto: FileUpload) {
    try {
      this._alertsService.loading();
      await this._productPhotosService.deleteFileStorage(filePhoto);
      await this._productPhotosService.deleteProductPhotos(
        filePhoto.uid,
        this.product,
        filePhoto.photoNumber
      );
      this._alertsService.toastAlert('success', `Foto eliminada...`);
      this.getProductPhotosByProductUid();
    } catch (error) {
      this._alertsService.toastAlert('success', `Hubo un error...`);
    }
  }

  resetData() {
    this.loading = true;
    setTimeout(() => {
      this.urlImgOne = null;
      this.urlImgTwo = null;
      this.urlImgThree = null;
      this.urlImgFour = null;
      this.imgOne = null;
      this.imgTwo = null;
      this.imgThree = null;
      this.imgFour = null;
      this.fileUploadOne = null;
      this.fileUploadTwo = null;
      this.fileUploadThree = null;
      this.fileUploadFour = null;
      this.loading = false;
    }, 2500);
  }

  openImage(image: FileUpload) {
    this._alertsService.openImage(image);
  }
}
