<ng-template #productContent let-modal>
    <ng-container *ngIf="openModal === true">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close('Close click')">
                <i class="fa-solid fa-circle-xmark"></i>
            </button>
        </div>
        <app-product-view [modal]="modal" [productUid]="product.uid" [modeModal]="true">
        </app-product-view>
        <div class="d-flex justify-content-end p-3 mb-3">
            <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close('Close click')">
                Cerrar
            </button>
        </div>
    </ng-container>
</ng-template>
<div class="card card-product" *ngIf="product">
    <div class="card-body" style="min-height: 180px;">
        <ng-container *ngIf="!loading && imageLoaded">
            <div class="text-center position-relative">
                <!-- <div class="position-absolute top-0 start-0">
                <span class="badge bg-danger">Sale</span>
            </div> -->
                <a (click)="selectProduct(product.uid)">
                    <img [src]="product.photoUrl" [alt]="product.name" class="mb-3 img-fluid"
                        *ngIf="product.photoUrl && product.photoUrl !== ''">
                    <img src="../../../assets/images/imagen_no_disponible.png" [alt]="product.name"
                        class="mb-3 img-fluid" *ngIf="!product.photoUrl || product.photoUrl === ''">
                </a>

                <div class="card-product-action">
                    <a class="btn-action me-2" (click)="openProductModal(productContent, true)">
                        <i class="fa-regular fa-eye"></i>
                    </a>
                    <!-- <a class="btn-action">
                    <i class="fa-regular fa-heart"></i>
                </a> -->
                </div>

            </div>
            <div class="text-small mb-1">
                <span class="text-decoration-none text-muted">
                    <small>{{product.categorieName}}</small>
                </span>
            </div>
            <h2 class="fs-6"><a (click)="selectProduct(product.uid)" class="text-inherit text-decoration-none"
                    [innerHTML]="product.name">{{product.name}}</a></h2>
            <div>

                <!-- <small class="text-warning"> <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-half"></i></small> <span class="text-muted small">4.5(149)</span> -->
            </div>
            <div class="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <span class="text-dark">{{product.price | currency}}</span>
                    <!-- <span class="text-decoration-line-through text-muted">$24</span> -->
                </div>
                <!-- <div><a class="btn btn-primary btn-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-plus">
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg> Add</a></div> -->
            </div>
        </ng-container>
        <ng-container *ngIf="loading && !imageLoaded">
            <div class="loading-content picture">

            </div>
            <div class="loading-content  t1">

            </div>
            <div class="loading-content  t2">

            </div>
            <div class="loading-content  t3">

            </div>

        </ng-container>
    </div>
</div>