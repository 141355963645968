import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'src/app/providers/alerts.service';
import { CouponsService } from 'src/app/providers/coupons.service';
import { Coupon } from '../../interfaces/coupon.interface';
import { take } from 'rxjs/operators';
import html2canvas from 'html2canvas';
import { Promotion } from 'src/app/interfaces/promotions.interface';
import { PromotionsService } from 'src/app/providers/promotions.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {
  percents: number[] = [5, 10, 15, 20];
  percentSelected: number = 0;
  coupon: string = '';
  coupons: Coupon[] = [];
  view: number;
  couponSelected: Coupon;
  statusValue: string = 'inactive';
  changedValue: string = 'no';
  cancelledValue: boolean = false;
  couponSelect: string = '';
  couponsCopy: Coupon[] = [];
  searchTerm: string = '';
  promotionName: string = '';
  expiredDate: NgbDateStruct;
  promoView: number = 1;
  promotions: Promotion[] = [];
  promotionSelected: string = '';
  editCoupon: boolean;
  clientPhoneNumber: number;

  constructor(
    private _modalService: NgbModal,
    private _alertsService: AlertsService,
    private _couponsService: CouponsService,
    private _promoService: PromotionsService
  ) {}

  ngOnInit(): void {
    this._couponsService
      .getLimitedCollection(0, this.getFilters())
      .subscribe(async (coupons) => {
        this.coupons = coupons;
        this.couponsCopy = this.coupons;
      });

    this._promoService.getPromotions().subscribe((promos) => {
      this.promotions = promos;
    });
  }

  generateKey(): string {
    const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';

    let randomKey = '';
    randomKey += capitalLetters.charAt(
      Math.floor(Math.random() * capitalLetters.length)
    );
    randomKey += lowerCaseLetters.charAt(
      Math.floor(Math.random() * lowerCaseLetters.length)
    );
    randomKey += numbers.charAt(Math.floor(Math.random() * numbers.length));
    randomKey += lowerCaseLetters.charAt(
      Math.floor(Math.random() * lowerCaseLetters.length)
    );
    randomKey += capitalLetters.charAt(
      Math.floor(Math.random() * capitalLetters.length)
    );
    randomKey += numbers.charAt(Math.floor(Math.random() * numbers.length));

    return randomKey;
  }

  openCouponModal(content: any, couponSelected?: Coupon): void {
    if (couponSelected) {
      this.couponSelected = couponSelected;
      this.editCoupon = true;
      this.coupon = couponSelected.coupon;
      this.promotionSelected = couponSelected.promotionUid
        ? couponSelected.promotionUid
        : '';
      this.clientPhoneNumber = couponSelected.clientPhoneNumber
        ? couponSelected.clientPhoneNumber
        : 0;
      this.percentSelected = couponSelected.percentValue;
    } else {
      this.editCoupon = false;
      this.promotionSelected = '';
      this.percentSelected = 0;
      this.coupon = this.generateKey();
      this.clientPhoneNumber = 0;
    }
    this.view = 1;
    this._modalService.open(content, { size: 'sm' });
  }

  openPromotionModal(content: any): void {
    this._modalService.open(content, { size: 'sm' });
  }

  checkIfCouponExists(): void {
    this._couponsService
      .getCouponsByField('coupon', this.coupon)
      .pipe(take(1))
      .subscribe((coupons) => {
        if (coupons.length > 0) {
          this.coupon = this.generateKey();
        }
        this.validIfUserHasCoupon();
      });
  }

  validIfUserHasCoupon(): void {
    const coupon: Coupon = {
      changed: 'no',
      coupon: this.coupon,
      percentValue: this.percentSelected,
      status: 'active',
      cancelled: false,
      created: new Date().getTime(),
      promotionUid: this.promotionSelected,
      clientPhoneNumber: this.clientPhoneNumber,
    };
    this._couponsService
      .getInfoByPhoneNumber(this.promotionSelected, this.clientPhoneNumber)
      .pipe(take(1))
      .subscribe((coupons) => {
        const couponsCollection = coupons.filter(
          (c) => c.uid !== coupon.uid && c.cancelled === false
        );
        if (couponsCollection.length > 0) {
          this._alertsService.toastAlert(
            'error',
            'El usuario ya tiene un cupón'
          );
          this._modalService.dismissAll();
        } else {
          this.saveCoupon(coupon);
        }
      });
  }

  async saveCoupon(coupon: Coupon): Promise<void> {
    try {
      this._alertsService.loading();

      await this._couponsService.addCoupon(coupon);
      this._alertsService.toastAlert(
        'success',
        'Cupón generado correctamente...'
      );
      this._modalService.dismissAll();
      this.promotionSelected = '';
      this.clientPhoneNumber = 0;
    } catch (error) {
      this.promotionSelected = '';
      this.clientPhoneNumber = 0;
      this._modalService.dismissAll();
      this._alertsService.toastAlert(
        'error',
        'Cupón no generado hubo un error...'
      );
    }
  }

  editCouponData(coupon: Coupon) {
    const couponToEdit: any = {
      percentValue: this.percentSelected,
      promotionUid: this.promotionSelected,
      clientPhoneNumber: this.clientPhoneNumber,
      uid: coupon.uid,
    };
    this._couponsService
      .getInfoByPhoneNumber(this.promotionSelected, this.clientPhoneNumber)
      .pipe(take(1))
      .subscribe((coupons) => {
        const couponsCollection = coupons.filter(
          (c) => c.uid !== coupon.uid && c.cancelled === false
        );
        if (couponsCollection.length > 0) {
          this._alertsService.toastAlert(
            'error',
            'El usuario ya tiene un cupón'
          );
          this._modalService.dismissAll();
        } else {
          this.updateCouponStandard(couponToEdit);
        }
      });
  }

  async updateCoupon(
    coupon: Coupon,
    type: 'coupon' | 'changed',
    changed?: 'yes' | 'no'
  ): Promise<void> {
    try {
      this._alertsService.loading();
      const resp = await this._alertsService.confirmation(
        this.getCouponText(coupon)
      );
      if (resp.value) {
        if (type === 'coupon') {
          coupon.status = coupon.status === 'active' ? 'inactive' : 'active';
        }
        if (type === 'changed' && !changed) {
          coupon.changed = coupon.changed === 'yes' ? 'no' : 'yes';
        }
        if (changed) {
          coupon.changed = changed;
        }
        await this._couponsService.editCoupon(coupon);
        this._alertsService.toastAlert(
          'success',
          'Cupón actualizado correctamente...'
        );
      }
    } catch (error) {
      this._alertsService.toastAlert(
        'error',
        'Cupón no actualizado hubo un error...'
      );
    }
  }

  getCouponText(coupon: Coupon): string {
    if (
      coupon.status === 'active' &&
      coupon.changed &&
      coupon.changed === 'no'
    ) {
      return 'El cupón pasara a ser ENTREGADO';
    }
    if (
      coupon.status === 'inactive' &&
      coupon.changed &&
      coupon.changed === 'no'
    ) {
      return 'El cupón pasara a ser CANJEADO';
    }
  }

  openViewCouponModal(content: any, coupon: Coupon): void {
    this.view = 2;
    this.couponSelected = coupon;
    this._modalService.open(content, { size: 'lg' });
  }

  downloadCoupon(): void {
    const htmlElement = document.getElementById('couponElement');
    html2canvas(htmlElement).then((canvas) => {
      const imageLink = document.createElement('a');
      imageLink.href = canvas.toDataURL('image/png');
      imageLink.download = `coupon-${this.couponSelected.coupon}`;
      document.body.appendChild(imageLink);
      imageLink.click();
      document.body.removeChild(imageLink);
    });
  }

  async updateCouponStandard(data: any): Promise<void> {
    try {
      this._alertsService.loading();
      const resp = await this._alertsService.confirmation();
      if (resp.value) {
        await this._couponsService.editCoupon(data);
        this._alertsService.toastAlert(
          'success',
          'Cupón actualizado correctamente...'
        );
      }
      this._modalService.dismissAll();
      this.changeData();
    } catch (error) {
      this._alertsService.toastAlert(
        'error',
        'Cupón no actualizado hubo un error...'
      );
    }
  }

  getFilters() {
    if (this.couponSelect === '4') {
      return [{ field: 'cancelled', operator: '==', value: true }];
    }
    if (this.couponSelect !== '4' && this.couponSelect !== '5') {
      return [
        { field: 'status', operator: '==', value: this.statusValue },
        { field: 'cancelled', operator: '==', value: this.cancelledValue },
        { field: 'changed', operator: '==', value: this.changedValue },
      ];
    }

    if (this.couponSelect === '5') {
      return [];
    }
  }

  changeData(): void {
    this.searchTerm = '';
    if (this.couponSelect === '') {
      this.statusValue = 'inactive';
      this.repeatedData();
    }
    if (this.couponSelect === '1') {
      this.statusValue = 'active';
      this.repeatedData();
    }
    if (this.couponSelect === '2') {
      this.statusValue = 'inactive';
      this.repeatedData();
    }
    if (this.couponSelect === '3') {
      this.statusValue = 'inactive';
      this.changedValue = 'yes';
      this.cancelledValue = false;
    }
    if (this.couponSelect === '4') {
      this.cancelledValue = true;
    }

    this._couponsService
      .getLimitedCollection(0, this.getFilters())
      .subscribe(async (coupons) => {
        this.coupons = coupons;
      });
  }

  repeatedData(): void {
    this.changedValue = 'no';
    this.cancelledValue = false;
  }

  searchItems() {
    this.coupons = this.couponsCopy.filter((item) => {
      return item.coupon.toLowerCase().includes(this.searchTerm.toLowerCase());
    });
    if (this.searchTerm === '') {
      this.coupons = this.couponsCopy;
    }
  }

  async savePromo(): Promise<void> {
    console.log(this.expiredDate);
    const { year, month, day } = this.expiredDate;
    console.log(this.expiredDate);

    const expirationDate: number = new Date(year, month, day).getTime();
    try {
      this._alertsService.loading();
      const promo: Promotion = {
        name: this.promotionName,
        expirationDate,
        created: new Date().getTime(),
      };
      await this._promoService.addPromotion(promo);
      this._alertsService.toastAlert(
        'success',
        'Promo creada correctamente...'
      );
      this._modalService.dismissAll();
      this.promoView = 1;
    } catch (error) {
      this._modalService.dismissAll();
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  hasOneMonthPassed(creationDate: number): boolean {
    const dateToCompare = new Date(creationDate);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - dateToCompare.getTime();

    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference > 30;
  }
}
