import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Product } from '../../interfaces/product.interface';
import { ProductsService } from '../../providers/products.service';
import { AlertsService } from '../../providers/alerts.service';
import { BrandsService } from '../../providers/brands.service';
import { CategoriesService } from '../../providers/categories.service';
import { Brand } from '../../interfaces/brand.interface';
import { Categorie } from '../../interfaces/categorie.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MovementsService } from '../../providers/movements.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
  private productsSubscription: Subscription;
  private categoriesSubscription: Subscription;
  private brandsSubscription: Subscription;
  private colorsSubscription: Subscription;
  private movementsSubscription: Subscription;

  productContainer: boolean;
  product: Product = {
    name: '',
    description: '',
    price: 0,
    photoUrl: '',
    code: '',
    categorieUid: '',
    brandUid: '',
    colors: [],
    show: true,
    isOndiscount: false,
    outOfStock: false,
    notCountInInventory: false,
    created: new Date().getTime(),
  };
  productForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    price: new FormControl('', [
      Validators.required,
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
    ]),
    code: new FormControl('', Validators.pattern(/^-?(0|[1-9]\d*)?$/)),
    description: new FormControl(''),
  });
  loading: boolean = false;
  products: Product[] = [];
  productsCopy: Product[] = [];
  brands: Brand[] = [];
  categories: Categorie[] = [];
  categorieUidSelected: string = '';
  productSelected: Product;
  colors: any[] = [];
  colorSelected: string = '';
  showOutOfStock: boolean;
  mobile: boolean;

  constructor(
    private _productsService: ProductsService,
    private _alertsService: AlertsService,
    private _brandsService: BrandsService,
    private _categorieService: CategoriesService,
    private modalService: NgbModal,
    private _movementsService: MovementsService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth <= 800) {
      this.mobile = true;
    }
    this.getBrandsAndCategories();
  }

  ngOnDestroy(): void {
    this.brandsSubscription && this.brandsSubscription.unsubscribe();
    this.categoriesSubscription && this.categoriesSubscription.unsubscribe();
    this.productsSubscription && this.productsSubscription.unsubscribe();
    this.colorsSubscription && this.colorsSubscription.unsubscribe();
    this.movementsSubscription && this.movementsSubscription.unsubscribe();
  }

  openProductContainer() {
    this.colorSelected = '';
    this.productContainer = this.productContainer === true ? false : true;
    if (this.productContainer === true) {
      this.product = this.resetProductData();
    }
  }

  async saveProduct(edit?: boolean) {
    try {
      this.product.price = Number(this.product.price);
      this.product.previousPrice = Number(this.product.previousPrice);
      this.product.notCountInInventory = this.product.notCountInInventory;
      const product: Product = { ...this.product, ...this.productForm.value };
      this.loading = true;
      if (edit === true) {
        delete product.created;
        product.updated = new Date().getTime();
        await this._productsService.editProduct(product);
        this.product = null;
        this.productForm.reset();
        this.modalService.dismissAll();
      } else {
        await this._productsService.addProduct(product);
      }
      this._alertsService.toastAlert(
        'success',
        `Producto ${edit === true ? 'actualizado' : 'guardado'}...`
      );
      this.productContainer = false;
      if (this.categorieUidSelected !== '') {
        this.loading = true;
        setTimeout(() => {
          this.filterByCategories(this.categorieUidSelected);
          this.loading = false;
        }, 1000);
      } else {
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  getProducts() {
    this.colorsSubscription = this._productsService
      .getColors()
      .subscribe((colors) => {
        this.colors = colors;
      });
    this.getProductsByCategoryUid();
  }

  getProductsByCategoryUid(): void {
    this.productsSubscription = this._productsService
      .getProductsByCategorieUidforAdmin(this.categorieUidSelected)
      .subscribe((products) => {
        let productsData = products;
        if (!this.showOutOfStock) {
          productsData = productsData.filter((p) => !p.outOfStock);
        } else {
          productsData = productsData.filter((p) => p.outOfStock);
        }
        this.productsCopy = productsData;
        this.products = productsData.map((p) => {
          const product = {
            ...p,
            nameCopy: p.name,
            name: `<span class="badge bg-light text-dark"> ${this._categorieService.getCategorieName(
              p.categorieUid,
              this.categories
            )}</span>
          <span class="badge bg-warning text-dark">${this._brandsService.getBrandName(
            p.brandUid,
            this.brands
          )}</span> ${p.name}`,
          };
          return product;
        });
      });
  }

  getBrandsAndCategories() {
    this.brandsSubscription = this._brandsService
      .getBrands()
      .subscribe((brands) => {
        this.brands = brands;
      });
    this.categoriesSubscription = this._categorieService
      .getCategories()
      .subscribe((categories) => {
        this.categories = categories;
        this.categorieUidSelected = categories.find(
          (c) => c.name === 'Accesorios'
        ).uid;
        this.getProducts();
      });
  }

  filterByCategories(categorieUid: string) {
    this.categorieUidSelected = categorieUid;
    this.showOutOfStock = false;
    this.getProductsByCategoryUid();
  }

  openModalEdit(content, product: Product) {
    this.colorSelected = '';
    this.productContainer = false;
    this.product = product;
    this.product.previousPrice = this.product.price;
    this.product.updated = new Date().getTime();
    this.product.notCountInInventory =
      this.product && this.product.notCountInInventory
        ? this.product.notCountInInventory
        : false;
    this.productForm.setValue({
      name: product.nameCopy,
      price: product.price,
      code: product.code,
      description: product.description,
    });
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'xl',
      })
      .result.then(
        (result) => {},
        (reason) => {
          this.product = null;
          this.productForm.reset();
        }
      );
  }

  deleteProduct(productUid: string) {
    this._alertsService.loading();
    this.movementsSubscription = this._movementsService
      .getMovements()
      .subscribe(async (movements) => {
        const exist: boolean = movements.some(
          (m) => m.productUid === productUid
        );
        if (exist === true) {
          this._alertsService.toastAlert(
            'error',
            `Este producto no se puede eliminar...`
          );
        } else {
          await this._productsService.deleteProduct(productUid);
          this._alertsService.toastAlert('success', `Producto eliminado...`);
          this.loading = true;
          setTimeout(() => {
            this.filterByCategories(this.categorieUidSelected);
            this.loading = false;
          }, 1000);
        }
      });
  }

  openProductGalleryModal(content, product: Product) {
    this.productSelected = product;
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
      })
      .result.then(
        (result) => {},
        (reason) => {
          this.productSelected = null;
          setTimeout(() => {
            this.filterByCategories(this.categorieUidSelected);
          }, 1000);
        }
      );
  }

  resetProductData(): Product {
    return {
      name: '',
      description: '',
      price: 0,
      photoUrl: '',
      code: '',
      categorieUid: '',
      brandUid: '',
      show: true,
      isOndiscount: false,
      notCountInInventory: false,
      created: new Date().getTime(),
    };
  }

  getHexCode(colorUid: string): string {
    if (colorUid !== '') {
      return this.colors.find((c) => c.uid === colorUid).hexCode;
    }
  }

  getColorName(colorUid: string): string {
    if (colorUid !== '') {
      return this.colors.find((c) => c.uid === colorUid).name;
    }
  }

  getTextColor(colorUid: string): string {
    if (colorUid !== '') {
      return this.colors.find((c) => c.uid === colorUid).textColorHexCode;
    }
  }

  addColor(colorUid: string) {
    const color =
      this.product.colors && this.product.colors.find((c) => c === colorUid);
    if (color) {
      this.product.colors = this.product.colors.filter((c) => c !== colorUid);
    } else {
      if (!this.product.colors) {
        this.product.colors = [];
      }
      this.product.colors.push(colorUid);
    }
  }

  async editOnePropertyValueOfProduct(
    product: Product,
    property: string,
    value: any
  ): Promise<void> {
    try {
      const alertResponse: any = await this._alertsService.confirmation(
        '¿Estás seguro de esto?'
      );
      if (alertResponse.value) {
        const productCopy = this.productsCopy.find(
          (p) => p.uid === product.uid
        );
        let productCopyTwo = { ...productCopy };
        productCopyTwo[property] = value;
        this._alertsService.loading();
        await this._productsService.editProduct(productCopyTwo);
        // this.loadMovementsCollection(true);
        this._alertsService.close();
        this._alertsService.toastAlert('success', 'Producto actualizado...');
      } else {
        this.products = this.products.map((p) => {
          if (product.uid === p.uid) {
            const checkedValue = value === true ? false : true;
            return { outOfStock: checkedValue, ...product };
          }
          return p;
        });
      }
    } catch (error) {
      this._alertsService.close();
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  updateWithInputCheck(product: Product, event: any): void {
    this.editOnePropertyValueOfProduct(
      product,
      'outOfStock',
      event.target.checked
    );
  }
}
