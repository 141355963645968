import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../../interfaces/product.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() loading: boolean;
  imageLoaded: boolean;
  openModal: boolean;
  constructor(private _router: Router, private _modalService: NgbModal) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.product && changes.product.currentValue.photoUrl) {
      this.loadImage();
    }
  }

  selectProduct(productUid: string) {
    this._router.navigate(['/product', productUid]);
  }

  openProductModal(content: any, openModal: boolean) {
    this.openModal = openModal;
    this._modalService
      .open(content, {
        backdrop: 'static',
        size: 'xl',
      })
      .result.then(
        (result) => {
          this.openModal = false;
        },
        (reason) => {
          this.openModal = false;
        }
      );
  }

  // Cargar la imagen
  loadImage() {
    const image = new Image();
    image.src = this.product.photoUrl;

    image.onload = () => {
      this.imageLoaded = true;
    };
  }
}
