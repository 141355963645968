import { Component, Input, OnInit } from '@angular/core';
import { ProductsService } from '../../providers/products.service';
import { Product } from '../../interfaces/product.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from '../../providers/categories.service';
import { Categorie } from '../../interfaces/categorie.interface';
import { BrandsService } from '../../providers/brands.service';
import { Brand } from '../../interfaces/brand.interface';
import { ProductPhotosService } from '../../providers/product-photos.service';
import { take } from 'rxjs/operators';
import { MovementsService } from '../../providers/movements.service';
import { Movement } from '../../interfaces/movement';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent implements OnInit {
  @Input() modeModal: boolean = false;
  product: Product;
  @Input() productUid: string;
  categories: Categorie[] = [];
  brands: Brand[] = [];
  brandName: string = '';
  productPhotos: any[] = [];
  mobile: boolean;
  photoSelected: any;
  loading: boolean;
  relatedProducts: Product[];
  colors: any[] = [];
  stock: number = 0;

  constructor(
    private _productsService: ProductsService,
    private _productPhotosService: ProductPhotosService,
    private _categoriesService: CategoriesService,
    private _brandsService: BrandsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _movementsService: MovementsService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < 860) {
      this.mobile = true;
    }
    this._productsService.getColors().subscribe((colors) => {
      this.colors = colors;
    });
    if (this.modeModal === true) {
      this.loading = true;
      this.getCollections();
    } else {
      this._route.params.subscribe((param) => {
        this.productUid = this._route.snapshot.paramMap.get('uid');
        if (param.uid === this.productUid) {
          this.loading = true;
          this.getCollections();
        }
      });
    }
  }

  getProduct(uid: string) {
    this._productsService.getProductByUid(uid).subscribe((products) => {
      this.product = products[0];
      this._movementsService
        .getMovementsByProductUid(this.productUid)
        .subscribe((movs) => {
          if (movs.length !== 0) {
            const entries: number = this.getItemsNumber(
              movs.filter((m) => m.type == 1)
            );
            const removals: number = this.getItemsNumber(
              movs.filter((m) => m.type == 2)
            );
            const sales: number = this.getItemsNumber(
              movs.filter((m) => m.type == 3)
            );
            this.stock = entries - removals - sales;
          }
        });
      this.getProductGallery(this.product.uid);
      this.product.categorieName = ` ${this._categoriesService.getCategorieNamePlural(
        this.product.categorieUid,
        this.categories
      )}`;
      this.brandName = this._brandsService.getBrandName(
        this.product.brandUid,
        this.brands
      );
      this.getRelatedItems(this.product.categorieUid);
    });
  }

  getProductGallery(productUid: string) {
    this._productPhotosService
      .getProductPhotosByProductUid(productUid)
      .pipe(take(1))
      .subscribe((photos) => {
        this.productPhotos = photos.sort(
          (a, b) => a.photoNumber - b.photoNumber
        );
        if (this.productPhotos.length === 0) {
          this.productPhotos.push({
            photoNumber: 0,
            url: '../../../assets/images/imagen_no_disponible.png',
          });
        }
        this.photoSelected = this.productPhotos[0];
        this.loading = false;
      });
  }

  getCollections() {
    this._categoriesService.getCategories().subscribe((cats) => {
      this.categories = cats;
      this._brandsService.getBrands().subscribe((brands) => {
        this.brands = brands;
        this.getProduct(this.productUid);
      });
    });
  }

  selectCategorie(categorieUid: string) {
    this._router.navigate(['/categorie', categorieUid]);
  }

  getRelatedItems(categorieUid: string) {
    this._productsService
      .getProductByCategorieUid(categorieUid)
      .subscribe((products) => {
        this.relatedProducts = products.filter(
          (p) => p.uid !== this.productUid && p.show === true
        );
      });
  }

  getHexCode(colorUid: string): string {
    if (colorUid !== '') {
      return this.colors.find((c) => c.uid === colorUid).hexCode;
    }
  }

  getColorName(colorUid: string): string {
    if (colorUid !== '') {
      return this.colors.find((c) => c.uid === colorUid).name;
    }
  }

  getTextColor(colorUid: string): string {
    if (colorUid !== '') {
      return this.colors.find((c) => c.uid === colorUid).textColorHexCode;
    }
  }

  imInterested(productUid: string) {
    const url = `https://api.whatsapp.com/send?phone=+524811390039&text=Hola,%20me%20interesa%20este%20producto%0A%0Ahttps://mavostore-53c97.web.app/product/${productUid}.`;
    window.open(url, '_blank');
  }

  getItemsNumber(movementsArray: Movement[]): number {
    const items: number[] = [];

    movementsArray.map((m) => items.push(m.productsNumber));

    const value: number = items.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);

    return value;
  }
}
