<div class="container">
    <ul class="nav nav-pills">
        <li class="nav-item">
            <button class="nav-link" (click)="selectMode('day')" [ngClass]="{active: mode === 'day'}">
                Día
            </button>
        </li>
        <li class="nav-item">
            <button class="nav-link" (click)="selectMode('week')" [ngClass]="{active: mode === 'week'}">
                Semana
            </button>
        </li>
        <li class="nav-item">
            <button class="nav-link" (click)="selectMode('month')" [ngClass]="{active: mode === 'month'}">
                Mes
            </button>
        </li>
    </ul>

    <div class="content">
        <app-tickets [adminMode]="true" [dateParam]="currentDate" (changeDate)="changeDate($event)"
            (changeDateParam)="changeDateParam($event)" *ngIf="mode === 'day'"></app-tickets>

        <app-sales-charts *ngIf="mode === 'week' || mode === 'month'" [mode]="mode"></app-sales-charts>
    </div>
</div>