import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
  currentUser: { email: string; uid: string; collectionsNotAllowed: string[] };
  loading: boolean;

  constructor(public _authService: AuthService) {}

  ngOnInit(): void {
    this.loading = true;
    this._authService.auth.user.pipe(take(1)).subscribe((user) => {
      if (user) {
        this._authService.getAdmins().subscribe((data: any[]) => {
          const admin = data.find((a) => a.email === user.email);
          this.currentUser = admin;
          this.loading = false;
        });
      }
    });
  }

  itCanAccess(name: string): boolean {
    const find =
      this.currentUser && this.currentUser?.collectionsNotAllowed
        ? this.currentUser.collectionsNotAllowed.find((c) => c === name)
        : false;

    if (!find) {
      return true;
    }

    return false;
  }
}
