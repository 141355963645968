import { Component, OnInit } from '@angular/core';
import { AlertsService } from '../../../providers/alerts.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  constructor(private _alertsService: AlertsService) {}

  ngOnInit(): void {}

  showAlertMessage(number: number) {
    let title;
    let text;
    if (number === 1) {
      title = '¿Cuáles son los puntos de entrega?';
      text = `
      <p class="fs-6">
        <b>Visitanos en:</b><br>
        Calle Independencia #4, Zona Centro 79000, Cd Valles, S.L.P.<br>
        <span class="fst-italic">A un lado del estacionamiento</span>        
      </p>
      `;
      this._alertsService.showImageMessage(text, 'Cerrar');
    }
    if (number === 2) {
      title = 'Política de devoluciones';
      text = `
      <p class="fs-6">
        <b>Lea con atención:</b><br>
        No hacemos devoluciones si cambia de opinión sobre una compra, elija
        el producto con cuidado.
        <br><br>
        Por supuesto, si el producto comprado no es apto para su uso <b>(defectuoso)</b>, usted cuenta con 
        una garantía de 7 días. <br>Se le cambiara por otro producto del mismo valor o puede dar una diferencia para uno de más valor <b>PERO</b> no realizamos reembolsos.
        <br>
        <br>
        <b>Para hacer valida su garantía debe tambien traer el empaque en perfectas condiciones y con sus complementos en caso de tener.</b>
      </p>
      `;
      this._alertsService.showMessage(title, text, 'Cerrar');
    }
  }
}
