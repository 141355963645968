<!-- footer -->
<footer class="footer">
    <div class="container">
        <div class="py-2">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                        <div
                            class="col col-sm-12 justify-content-center justify-center text-center d-block d-sm-block d-md-block d-lg-none">
                            <img src="../../assets/images/logo/mavomarcaaguanegro.png" alt="mr mavo store"
                                width="180px">
                        </div>
                        <div class="col col-sm-4 d-none d-lg-block d-md-none">
                            <img src="../../assets/images/logo/mavomarcaaguanegro.png" alt="mr mavo store"
                                width="180px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <!-- Hola un example -->
                    <!-- <div class="row justify-content-center justify-center text-center d-block d-sm-block d-md-block d-lg-none mb-3">
                        <div class="col col-sm-4">
                            <img src="../../assets/images/logo/mavomarcaaguaazul.png" alt="mr mavo store" width="180px">
                        </div>
                    </div>
                    <div class="row d-none d-lg-block d-md-none">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <img src="../../assets/images/logo/mavomarcaaguaazul.png" alt="mr mavo store" width="180px">
                        </div>
                    </div> -->
                    <div class="row text-justify">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                            <p class="small contenedor">
                                Somos una empresa dedicada a la comercialización de productos y nuestro
                                principal objetivo es satisfacer las necesidades de nuestros clientes ofreciendo
                                lo mejor en <strong>calidad/precio</strong>.<br>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-4 mt-2">
                    <div class="row align-items-center">
                        <div class="col-sm-12 col-md-12 col-lg-12 pl-3">
                            <span class="small font-weight-bold text-center color-black">Preguntas frecuentes</span>
                            <div class="mt-3">
                                <li class="small cursor mb-3" (click)="showAlertMessage(1)">¿Cuáles son los puntos
                                    de
                                    entrega?
                                </li>
                                <li class="small cursor" (click)="showAlertMessage(2)">¿Existe garantía en la compra de
                                    los productos?</li>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-3 d-none d-lg-block d-md-none">
                                <span class="small font-weight-bold text-center color-black">Métodos de pago</span>
                                <p class="small contenedor mt-3">
                                    Aceptamos pagos con tarjeta
                                </p>
                                <div class="col-sm">
                                    <img class="px-3" width="66" src="../../../../assets/images/payment/mastercard.svg">
                                    <img class="px-3" width="68" src="../../../../assets/images/payment/visa.svg">
                                    <img class="px-3" width="106"
                                        src="../../../../assets/images/payment/mercado_pago.svg">
                                </div>
                            </div>
                            <div
                                class="col-sm-12 col-md-12 col-lg-12 mt-3 justify-content-center justify-center text-center d-block d-sm-block d-md-block d-lg-none">
                                <span class="small font-weight-bold text-center color-black">Métodos de pago</span>
                                <p class="small text-center mt-3">
                                    Aceptamos pagos con tarjeta
                                </p>
                                <div class="col-sm">
                                    <img class="px-3" width="66" src="../../../../assets/images/payment/mastercard.svg">
                                    <img class="px-3" width="68" src="../../../../assets/images/payment/visa.svg">
                                    <img class="px-3" width="106"
                                        src="../../../../assets/images/payment/mercado_pago.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-4 mt-2">
                    <span
                        class="small font-weight-bold d-block d-sm-block d-md-none d-lg-none mb-4 color-black">Ubicación</span>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d231.23069295988918!2d-99.01623097886315!3d21.984816014974093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d66d53dee0cc49%3A0x63f83abfb21fdc64!2sPala%20Bikes!5e0!3m2!1ses-419!2smx!4v1699068709364!5m2!1ses-419!2smx"
                        width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>

        <div class="border-top mt-3 pt-3 pb-2">
            <div class="row align-items-center">
                <!-- <span
                    class="small font-weight-bold d-block d-sm-block d-md-none d-lg-none mt-3 color-black">Contacto</span> -->
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                    <div class="row">
                        <!-- <div class="col-sm-6 col-md-4 col-lg-4">
                            <span class="small text-justify">
                                <span class="fa fa-mobile pr-1 py-auto"></span>
                                481-154-13-17
                            </span>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4">
                            <span class="small text-justify">
                                <span class="fa fa-envelope pr-1 py-auto"></span>
                                gerardo1997gof@gmail.com
                            </span>
                        </div> -->
                        <div class="col-sm-6 col-md-4 col-lg-4">
                            <span class="small text-justify">
                                <span class="fa fa-map-marker pr-1 py-auto text-danger"></span>
                                Calle Independencia #4, Zona Centro 79000, Cd Valles, S.L.P.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="border-top pt-4 pb-0">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <span class="small text-muted">Copyright {{year}} © Mr Mavo Store. All rights reserved.</span>
                </div>
                <div class="col-md-6">
                    <ul class="list-inline text-md-end mb-0 small mt-3 mt-md-0">
                        <li class="list-inline-item text-muted">Siguenos en</li>
                        <li class="list-inline-item me-1">
                            <a href="https://www.facebook.com/mrmavo" target="_blank"
                                class="icon-shape icon-sm social-links"> <svg xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16" fill="currentColor" class="bi bi-facebook"
                                    viewBox="0 0 16 16">
                                    <path
                                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                </svg>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <!-- <a routerLink="/" class="icon-shape icon-sm social-links"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-instagram" viewBox="0 0 16 16">
                                    <path text-justify
                                        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                </svg>
                            </a> -->
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</footer>