import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Brand } from '../../../interfaces/brand.interface';
import { BrandsService } from '../../../providers/brands.service';

@Component({
  selector: 'app-brand-select',
  templateUrl: './brand-select.component.html',
  styleUrls: ['./brand-select.component.scss'],
})
export class BrandSelectComponent implements OnInit {
  brands: Brand[] = [];
  @Input() brandSelected: string = '';
  @Output() sendData: EventEmitter<string> = new EventEmitter();

  constructor(private _brandsService: BrandsService) {}

  ngOnInit(): void {
    this.getBrands();
  }

  getBrands() {
    this._brandsService.getBrands().subscribe((brands) => {
      this.brands = brands;
    });
  }
}
