import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Ticket } from 'src/app/interfaces/ticket.interface';
import { TicketsService } from 'src/app/providers/tickets.service';
moment.locale('es-mx');
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { SalesDataByHour } from 'src/app/interfaces/salesDataByHour.interface';

@Component({
  selector: 'app-sales-charts',
  templateUrl: './sales-charts.component.html',
  styleUrls: ['./sales-charts.component.scss'],
})
export class SalesChartsComponent implements OnInit {
  @Input() mode: 'week' | 'month';
  startDate: Date;
  endDate: Date;
  tickets: Ticket[] = [];
  calculatedDays: {
    day: number;
    month: number;
    year: number;
    total: number;
    totalCash: number;
    totalCard: number;
    totalOthers: number;
  }[];
  dataSection: number = 1;

  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  lineChartOptions: any & { annotation: any } = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              return (
                '$' + label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              );
              // Agregamos '₱' antes de la cadena de números
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      intersect: true,
      titleFontSize: 20,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return (
            '$' + currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          );
          // Agregamos '₱' antes de la cadena de números
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  lineChartOptions2: any & { annotation: any } = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              return (
                label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'
              );
              // Agregamos '₱' antes de la cadena de números
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      intersect: true,
      titleFontSize: 20,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return (
            currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'
          );
          // Agregamos '₱' antes de la cadena de números
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  lineChartColors = [
    {
      borderColor: 'black',
      backgroundColor: '#acd8a7',
    },
  ];

  lineChartLegend = true;
  lineChartType: ChartType = 'line';
  lineChartPlugins = [];
  startDateInput: any;
  endDateInput: any;
  startDateInputData: Date;
  endDateInputData: Date;
  startDateCopy: Date;
  endDateCopy: Date;
  showByRange: boolean;
  mobile: boolean;
  hourView: boolean;
  salesDataByHourArray: SalesDataByHour[] = [];

  constructor(private _ticketsService: TicketsService) {}

  ngOnInit(): void {
    if (window.innerWidth <= 800) {
      this.mobile = true;
    }
    this.initData();
  }

  calculateCurrentWeek() {
    const today = moment().startOf('isoWeek');
    this.startDate = today.toDate();
    this.endDate = today.clone().add(6, 'days').toDate();
    this.setDateCopy();
    this.getTickets();
  }

  previousWeek() {
    const previousMonday = moment(this.startDate)
      .subtract(1, 'week')
      .startOf('isoWeek')
      .toDate();
    const previousSunday = moment(this.endDate)
      .subtract(1, 'week')
      .endOf('isoWeek')
      .toDate();
    this.startDate = previousMonday;
    this.endDate = previousSunday;
    this.getTickets();
  }

  previousMonth(): void {
    const today = this.startDate;

    this.startDate = moment(today)
      .subtract(1, 'month')
      .startOf('month')
      .toDate();
    this.endDate = moment(today).subtract(1, 'month').endOf('month').toDate();

    this.getTickets();
  }

  previous(): void {
    this.resetData();
    this.activeNormalViewInTrue();
    if (this.mode === 'week') {
      this.previousWeek();
    }
    if (this.mode === 'month') {
      this.previousMonth();
    }
  }

  next(): void {
    this.resetData();
    this.activeNormalViewInTrue();
    if (this.mode === 'week') {
      this.nextWeek();
    }
    if (this.mode === 'month') {
      this.nextMonth();
    }
  }

  nextWeek() {
    const nextMonday = moment(this.startDate)
      .add(1, 'week')
      .startOf('isoWeek')
      .toDate();
    const nextSunday = moment(this.endDate)
      .add(1, 'week')
      .endOf('isoWeek')
      .toDate();
    this.startDate = nextMonday;
    this.endDate = nextSunday;
    this.getTickets();
  }

  nextMonth(): void {
    const today = this.startDate;

    this.startDate = moment(today).add(1, 'month').startOf('month').toDate();
    this.endDate = moment(today).add(1, 'month').endOf('month').toDate();

    this.getTickets();
  }

  calculateDays(): void {
    this.lineChartLabels = [];
    this.calculatedDays = [];
    this.lineChartData = [{ data: [], label: 'Ventas' }];
    const currentDate = moment(this.startDate);
    const endDate = moment(this.endDate);

    while (currentDate <= endDate) {
      const day: number = currentDate.date();
      const month: number = currentDate.month();
      const year: number = currentDate.year();

      this.calculatedDays.push({
        day,
        month,
        year,
        total: this.getTotalByDay(day, month, year),
        totalCash: this.getTotalByDay(day, month, year, 'cash'),
        totalCard: this.getTotalByDay(day, month, year, 'card'),
        totalOthers: this.getTotalByDayOthers(day, month, year),
      });
      currentDate.add(1, 'day');
    }
    // console.log(this.calculatedDays, 'WEEK DAYS');
    this.calculatedDays.forEach((wd) => {
      this.lineChartLabels.push(String(wd.day));
      this.lineChartData[0].data.push(wd.total);
    });
  }

  getShortDate(date: Date): any {
    const newDate = moment(date).toDate();
    return moment(newDate).format('ll');
  }

  async getTickets(): Promise<void> {
    try {
      this.dataSection = 1;
      const startDate = this.getStartDateAndEndDateAsTimestamp('start');
      const endDate = this.getStartDateAndEndDateAsTimestamp('end');

      this.tickets = await this._ticketsService.getTicketsByRange(
        startDate,
        endDate
      );
      this.tickets = await this._ticketsService.getTicketsChanged(this.tickets);
      this.calculateDays();
    } catch (error) {}
  }

  getTotalByDay(
    day: number,
    month: number,
    year: number,
    which?: 'cash' | 'card'
  ): number {
    const objectDate: any = new Date(year, month, day);
    const startDate = objectDate;
    startDate.setHours(0, 0, 0, 0);
    const todayStartDate: number = startDate.getTime();

    const endDate = objectDate;
    endDate.setHours(23, 59, 59, 999);
    const todayendDate: number = endDate.getTime();

    const currentDayTickets: Ticket[] = this.tickets.filter(
      (t) => t.created >= todayStartDate && t.created <= todayendDate
    );

    return (
      this.calculateTotal(currentDayTickets, which) +
      this.calculateTotalOfTicketsChanged(currentDayTickets, which) +
      this.calculateTotalOfTicketsCancelled(currentDayTickets, which)
    );
  }

  calculateTotal(
    ticketsData: Ticket[],
    which?: 'cash' | 'card',
    propertyParam?: string
  ): number {
    let totalCost = 0;
    const property: string = propertyParam ? propertyParam : 'total';

    if (ticketsData.length > 0) {
      let tickets: Ticket[] = ticketsData.filter((t) => t.status === 1);

      if (which === 'cash')
        tickets = tickets.filter((t) => t.paymentMethod === 1);
      if (which === 'card')
        tickets = tickets.filter((t) => t.paymentMethod === 2);

      if (tickets && tickets.length > 0) {
        totalCost = tickets.reduce((accumulator, ticket) => {
          return accumulator + ticket[property];
        }, 0);
      }
    }

    return totalCost;
  }

  calculateTotalOfTicketsChanged(
    ticketsData: Ticket[],
    which?: 'cash' | 'card'
  ): number {
    let totalCost = 0;

    if (ticketsData.length > 0) {
      let tickets: Ticket[] = ticketsData.filter((t) => t.status === 2);

      if (which === 'cash')
        tickets = tickets.filter((t) => t.paymentMethod === 1);
      if (which === 'card')
        tickets = tickets.filter((t) => t.paymentMethod === 2);

      if (tickets && tickets.length > 0) {
        totalCost = tickets.reduce((accumulator, ticket) => {
          return (
            accumulator + (ticket.total - ticket.moneyInBoxFromPreviousTicket)
          );
        }, 0);
      }
    }
    return totalCost;
  }

  calculateTotalOfTicketsCancelled(
    ticketsData: Ticket[],
    which?: 'cash' | 'card'
  ): number {
    let totalCost = 0;

    if (ticketsData.length > 0) {
      let tickets: Ticket[] = ticketsData.filter(
        (t) => t.status === 3 && t.ifItsCanceledAddTotalToTheDaySale
      );

      if (which === 'cash')
        tickets = tickets.filter((t) => t.paymentMethod === 1);
      if (which === 'card')
        tickets = tickets.filter((t) => t.paymentMethod === 2);

      if (tickets && tickets.length > 0) {
        totalCost = tickets.reduce((accumulator, ticket) => {
          return accumulator + ticket.total;
        }, 0);
      }
    }
    return totalCost;
  }

  calculateTotalWeek(property?: string): number {
    const data: string = property ? property : 'total';
    const totalSum = this.calculatedDays.reduce(
      (accumulator, currentValue) => accumulator + currentValue[data],
      0
    );

    return totalSum;
  }

  calculateAverageForTheWeek(): number {
    const totalWeek: number = this.calculateTotalWeek();
    const daysWithSales: number = this.calculatedDays.filter(
      (wd) => wd.total > 0
    ).length;
    const total: number = Number(Number(totalWeek / daysWithSales).toFixed(2));
    return totalWeek === 0 ? 0 : total;
  }

  getTotalByDayOthers(day: number, month: number, year: number): number {
    const objectDate: any = new Date(year, month, day);
    const startDate = objectDate;
    startDate.setHours(0, 0, 0, 0);
    const todayStartDate: number = startDate.getTime();

    const endDate = objectDate;
    endDate.setHours(23, 59, 59, 999);
    const todayendDate: number = endDate.getTime();

    const currentDayTickets: Ticket[] = this.tickets.filter(
      (t) => t.created >= todayStartDate && t.created <= todayendDate
    );

    return this.calculateTotal(
      currentDayTickets,
      null,
      'accumulatedSalesOfUninventoryedThings'
    );
  }

  resetData(calculateDays?: boolean): void {
    this.lineChartLabels = [];
    this.lineChartData = [{ data: [], label: 'Ventas' }];
    if (!calculateDays) {
      this.calculatedDays = [];
    }
    if (calculateDays === true) {
      this.lineChartData = [
        {
          data: [],
          label: 'Horas de venta',
          backgroundColor: '#63B8FF',
          borderColor: '#0000FF',
          pointBackgroundColor: '#0000FF',
          pointBorderColor: '#FFFF',
        },
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.mode && changes.mode.currentValue) {
      this.activeNormalViewInTrue();
      this.initData();
    }
  }

  setDateCopy(): void {
    this.startDateCopy = this.startDate;
    this.endDateCopy = this.endDate;
  }

  initData(): void {
    this.resetData();
    if (this.mode === 'week') {
      this.calculateCurrentWeek();
    }
    if (this.mode === 'month') {
      this.calculateCurrentMonth();
    }
  }

  calculateCurrentMonth() {
    const today = moment(); // Obtiene la fecha actual
    this.startDate = moment(today).startOf('month').toDate();
    this.endDate = moment(today).endOf('month').toDate();
    // console.log(this.startDate, this.endDate);
    this.setDateCopy();
    this.getTickets();
  }

  getStartDateAndEndDateAsTimestamp(dateParam: 'start' | 'end'): number {
    const startDate = new Date(this.startDate);
    startDate.setHours(0, 0, 0, 0);
    const todayStartDate: number = startDate.getTime();

    const endDate = new Date(this.endDate);
    endDate.setHours(23, 59, 59, 999);
    const todayEndDate: number = endDate.getTime();

    if (dateParam === 'start') {
      return todayStartDate;
    }
    if (dateParam === 'end') {
      return todayEndDate;
    }
  }

  showDataByRange(): void {
    this.showByRange = this.showByRange === true ? false : true;
    this.activeNormalViewInTrue();
    if (this.showByRange === false) {
      this.startDate = this.startDateCopy;
      this.endDate = this.endDateCopy;
      this.resetData();
      this.getTickets();
    }
  }

  activeNormalViewInTrue() {
    if (this.hourView === true) {
      this.watchNormalView(true);
    }
  }

  changeStartDateInput(): void {
    const { year, month, day } = this.startDateInput;

    this.startDateInputData = new Date(year, month - 1, day);
    this.validateInputDates();
  }

  changeEndDateInput(): void {
    const { year, month, day } = this.endDateInput;

    this.endDateInputData = new Date(year, month - 1, day);
    this.validateInputDates();
  }

  validateInputDates(): void {
    if (this.startDateInputData && this.endDateInputData) {
      const startDate = new Date(this.startDateInputData);
      const endDate = new Date(this.endDateInputData);

      if (startDate < endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.activeNormalViewInTrue();
        this.getTickets();
      }
    }
  }

  getAverage(ticketsResult?: boolean): number {
    return this._ticketsService.calculateTheAverage(
      this.tickets,
      this.calculateTotalWeek.bind(this),
      ticketsResult
    );
  }

  calculateDaysBetween(
    startDate: string | Date,
    endDate: string | Date
  ): number {
    const start = moment(startDate);
    const end = moment(endDate);

    const diffInDays = end.diff(start, 'days');

    return diffInDays + 1;
  }

  calculateClientsAverageForRange(): number {
    const totalClients: number = this.getAverage(true);
    const daysWithSales: number = this.calculatedDays.filter(
      (wd) => wd.total > 0
    ).length;
    const total: number = Number(
      Number(totalClients / daysWithSales).toFixed(2)
    );
    return totalClients === 0 ? 0 : total;
  }

  watchByHour(): void {
    this.hourView = true;
    const ticketsData: Ticket[] = this.tickets;
    const salesData: any = this._ticketsService.groupSalesByHour(ticketsData);
    const combinedSales = {};
    Object.values(salesData).forEach((dayData) => {
      Object.entries(dayData).forEach(([hour, value]) => {
        if (combinedSales[hour]) {
          combinedSales[hour] += value;
        } else {
          combinedSales[hour] = value;
        }
      });
    });

    const salesArray: SalesDataByHour[] = Object.entries(combinedSales).map(
      ([hour, totalSales]) => {
        return { hour: parseInt(hour), totalSales: Number(totalSales) };
      }
    );
    const totalTickets = salesArray.reduce(
      (sum, item) => sum + item.totalSales,
      0
    );
    this.salesDataByHourArray = salesArray.map((sa) => {
      const percent: number = (sa.totalSales * 100) / totalTickets;
      return { ...sa, percentage: parseFloat(percent.toFixed(2)) };
    });
    this.resetData(true);
    this.salesDataByHourArray.forEach((hourSaleData) => {
      this.lineChartLabels.push(String(hourSaleData.hour));
      this.lineChartData[0].data.push(hourSaleData.percentage);
    });
    // console.log(this.tickets, 'HOLA');
    // console.log(this.calculateTotalWeek());
  }

  watchNormalView(dontGetTickets?: boolean): void {
    this.hourView = false;
    this.resetData();
    if (!dontGetTickets) {
      this.getTickets();
    }
  }
}
