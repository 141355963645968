import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../../providers/categories.service';
import { Categorie } from '../../interfaces/categorie.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandsService } from '../../providers/brands.service';
import { ProductsService } from '../../providers/products.service';
import { Product } from '../../interfaces/product.interface';
import { Brand } from '../../interfaces/brand.interface';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss'],
})
export class CategorieComponent implements OnInit {
  categories: Categorie[] = [];
  categorieUid: string;
  products: Product[] = this._productsService.getProductsFakeData();
  productsCopy: Product[] = [];
  brands: Brand[] = [];
  sortValue: string = '';
  loading: boolean;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _categoriesService: CategoriesService,
    private _brandsService: BrandsService,
    private _productsService: ProductsService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this._route.params.subscribe((param) => {
      this.categorieUid = this._route.snapshot.paramMap.get('uid');
      if (param.uid === this.categorieUid) {
        this.getData();
      }
    });
    this.getData();
  }

  getData() {
    this.categorieUid = this._route.snapshot.paramMap.get('uid');
    this.getCategories();
  }

  getCategories() {
    this._categoriesService.getCategoriesAvailables().subscribe((cats) => {
      this.categories = cats.map((c) => {
        if (!c.photoURL) {
          return {
            ...c,
            photoURL: '../../../assets/images/no-image-small.jpg',
          };
        }
        return c;
      });
      this.getCollections();
    });
  }

  selectCategorie(categorieUid: string) {
    this.categorieUid = categorieUid;
    this._router.navigate(['/categorie', categorieUid]);
  }

  getCategorieName(): string {
    const findName = this.categories.find((c) => c.uid === this.categorieUid);
    if (findName) {
      return findName.name;
    }
  }

  getCollections() {
    this._brandsService.getBrands().subscribe((brands) => {
      this.brands = brands;
    });
    this._productsService
      .getProductsByCategorieUid(this.categorieUid)
      .subscribe((products) => {
        this.products = this._productsService.mapProductsPhotoUrlData(products);
        this.productsCopy = this.products;
        this.products = this.mapProducts();
        this.sortValue = '';
        this.loading = false;
        this.sortBy();
      });
  }

  sortBy() {
    if (this.sortValue !== '') {
      let sortValueFinal = this.sortValue;
      let sort = 'asc';
      if (this.sortValue === 'priceLow') {
        sort = 'desc';
      }
      if (this.sortValue.includes('price')) {
        sortValueFinal = 'price';
      }

      this.products = this.productsCopy.sort((a, b) => {
        if (sort === 'desc') {
          return a[sortValueFinal] - b[sortValueFinal];
        } else {
          return b[sortValueFinal] - a[sortValueFinal];
        }
      });
      this.products = this.mapProducts();
    } else {
      this.products = this.productsCopy;
      this.products = this.mapProducts();
    }
  }

  mapProducts() {
    return this.products.map((p) => {
      const product = {
        ...p,
        name: `
    <span class="badge bg-dark text-white">${this._brandsService.getBrandName(
      p.brandUid,
      this.brands
    )}</span> ${p.name}`,
        categorieName: ` ${this._categoriesService.getCategorieNamePlural(
          p.categorieUid,
          this.categories
        )}`,
      };
      return product;
    });
  }
}
