<nav class="pb-5 pt-3">
    <!-- <div class="d-flex justify-content-end mb-2">
        <span>Páginas: <b>{{pagesNumber}}</b></span>
    </div> -->
    <ul class="pagination justify-content-end">
        <li class="page-item" [ngClass]="{disabled: currentPage === 1}">
            <a class="page-link" (click)="pageSelected(currentPage === 1 ? 1 : currentPage - 1)">
                <i class="fa-solid fa-arrow-left"></i>
            </a>
        </li>
        <li class="page-item"
            *ngFor="let page of pagesArray | slice: ((currentPage === 5 || currentPage >= 4) ? ((currentPage + 3 === pagesNumber || currentPage + 2 === pagesNumber || currentPage + 1 === pagesNumber || currentPage === pagesNumber) ? pagesNumber - 5 : currentPage - 2) : 0) :  ((currentPage === 5 || currentPage >= 4) ? currentPage + 3 : 5)"
            [ngClass]="{active: currentPage === page + 1}">
            <a class="page-link" (click)="pageSelected(page + 1)">{{page + 1 }}</a>
        </li>
        <li class="page-item" [ngClass]="{disabled: currentPage === pagesArray.length}">
            <a class="page-link"
                (click)="pageSelected(currentPage === pagesArray.length ? pagesArray.length : currentPage + 1)">
                <i class="fa-solid fa-arrow-right"></i>
            </a>
        </li>
    </ul>
</nav>