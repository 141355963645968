import { Component, OnInit } from '@angular/core';
import { AlertsService } from '../providers/alerts.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent implements OnInit {
  constructor(private _alertsService: AlertsService) {}

  ngOnInit(): void {
    // const viewed = localStorage.getItem('viewed');
    // if (!viewed) {
    //   setTimeout(() => {
    //     this.showAlert();
    //     localStorage.setItem('viewed', 'seen');
    //   }, 1000);
    // }
    // if (viewed === 'seen') {
    //   this.showAlert();
    //   localStorage.setItem('viewed', 'seen2');
    // }
    // if (viewed === 'seen2') {
    //   this.showAlert();
    //   localStorage.setItem('viewed', 'seen3');
    // }
  }

  showAlert() {
    const text = `
        <p class="fs-6">
        Envíanos una captura de pantalla por Facebook o WhatsApp donde muestres que ya nos sigues y se te generará un cupón de descuento válido para una compra...
        </p>
      `;
    this._alertsService.showMainMessage(
      '¿Ya nos sigues en Facebook?',
      text,
      'Ver sitio web'
    );
  }
}
