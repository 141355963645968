import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Label, ProductLabel } from '../interfaces/label.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  private labelsCollection: AngularFirestoreCollection<Label>;
  private productLabelsCollection: AngularFirestoreCollection<ProductLabel>;

  constructor(private _afs: AngularFirestore) {
    this.labelsCollection = _afs.collection<Label>('labels');
    this.productLabelsCollection =
      _afs.collection<ProductLabel>('productLabels');
  }

  getLabels(): Observable<Label[]> {
    return this.labelsCollection.valueChanges();
  }

  async addProductLabel(productLabel: any): Promise<void> {
    const id = this._afs.createId();

    const productLabelToSave: ProductLabel = {
      uid: id,
      ...productLabel,
    };
    this.productLabelsCollection.doc(id).set(productLabelToSave);
  }

  getProductsLabel(): Observable<ProductLabel[]> {
    return this.productLabelsCollection.valueChanges();
  }

  async deleteProductLabel(uid: string): Promise<void> {
    return this.productLabelsCollection.doc(uid).delete();
  }
}
