<div class="container galleryContainer p-3">
    <div class="row mt-lg-5 gallerySection">
        <div class="col-md-6 alignToCenter">
            <section class="picture1 alignToCenter" [ngClass]="{imgUrl:!urlImgOne && !photo1}">
                <h1 *ngIf="!urlImgOne && !photo1">1</h1>
                <input type="file" accept="image/*" (change)="selectFile($event,1)" *ngIf="!photo1">
                <img [src]="urlImgOne" alt="urlImgOne" class="img-fluid maxProfilePicture" *ngIf="urlImgOne && !photo1">
                <img [src]="photo1.url" alt="urlImgOne" class="img-fluid maxProfilePicture" (click)="openImage(photo1)"
                    style="cursor: zoom-in;" *ngIf="photo1 && photo1.url">
            </section>
        </div>
        <div class="col-md-6">
            <section class="col-md-12 picture2 alignToCenter" [ngClass]="{imgUrl:!urlImgTwo && !photo2}">
                <h1 *ngIf="!urlImgTwo && !photo2">2</h1>
                <input type="file" accept="image/*" (change)="selectFile($event,2)" *ngIf="!photo2">
                <img [src]="urlImgTwo" alt="urlImgTwo" class="img-fluid maxSinglePicture" *ngIf="urlImgTwo && !photo2">
                <img [src]="photo2.url" alt="urlImgTwo" class="img-fluid maxSinglePicture" (click)="openImage(photo2)"
                    style="cursor: zoom-in;" *ngIf="photo2 && photo2.url">
            </section>
            <section class="col-md-12 picture3 alignToCenter" [ngClass]="{imgUrl:!urlImgThree && !photo3}">
                <h1 *ngIf="!urlImgThree && !photo3">3</h1>
                <input type="file" accept="image/*" (change)="selectFile($event,3)" *ngIf="!photo3">
                <img [src]="urlImgThree" alt="urlImgThree" class="img-fluid maxSinglePicture"
                    *ngIf="urlImgThree && !photo3">
                <img [src]="photo3.url" alt="urlImgThree" class="img-fluid maxSinglePicture" (click)="openImage(photo3)"
                    style="cursor: zoom-in;" *ngIf="photo3 && photo3.url">
            </section>
            <section class="col-md-12 picture4 alignToCenter" [ngClass]="{imgUrl:!urlImgFour && !photo4}">
                <h1 *ngIf="!urlImgFour && !photo4">4</h1>
                <input type="file" accept="image/*" (change)="selectFile($event,4)" *ngIf="!photo4">
                <img [src]="urlImgFour" alt="urlImgFour" class="img-fluid maxSinglePicture"
                    *ngIf="urlImgFour && !photo4">
                <img [src]="photo4.url" alt="urlImgFour" class="img-fluid maxSinglePicture" (click)="openImage(photo4)"
                    style="cursor: zoom-in;" *ngIf="photo4 && photo4.url">
            </section>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-3">
                    <i class="fa-solid fa-1"></i>
                    <button type="button" class="btn btn-danger btn-sm ms-2" [disabled]="!urlImgOne && !photo1"
                        (click)="deletePicture(1)">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </div>
                <div class="col-md-3">
                    <i class="fa-solid fa-2"></i>
                    <button type="button" class="btn btn-danger btn-sm ms-2" [disabled]="!urlImgTwo && !photo2"
                        (click)="deletePicture(2)">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </div>
                <div class="col-md-3">
                    <i class="fa-solid fa-3"></i>
                    <button type="button" class="btn btn-danger btn-sm ms-2" [disabled]="!urlImgThree && !photo3"
                        (click)="deletePicture(3)">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </div>
                <div class="col-md-3">
                    <i class="fa-solid fa-4"></i>
                    <button type="button" class="btn btn-danger btn-sm ms-2" [disabled]="!urlImgFour && !photo4"
                        (click)="deletePicture(4)">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
        <button type="button" class="btn btn-primary btn-sm" (click)="uploadAll()"
            [disabled]="!urlImgOne && !urlImgTwo && !urlImgThree && !urlImgFour">
            <i class="fa-solid fa-upload"></i>
        </button>
    </div>
    <span class="badge bg-light text-dark" *ngIf="fileUploadOne">Foto 1</span>
    <div *ngIf="fileUploadOne" class="progress mt-2">
        <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ fileUploadOne.percent }}"
            aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: fileUploadOne.percent + '%' }">
            {{ fileUploadOne.percent }}%
        </div>
    </div>
    <span class="badge bg-light text-dark" *ngIf="fileUploadTwo">Foto 2</span>
    <div *ngIf="fileUploadTwo" class="progress mt-2">
        <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ fileUploadTwo.percent }}"
            aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: fileUploadTwo.percent + '%' }">
            {{ fileUploadTwo.percent }}%
        </div>
    </div>
    <span class="badge bg-light text-dark" *ngIf="fileUploadThree">Foto 3</span>
    <div *ngIf="fileUploadThree" class="progress mt-2">
        <div class="progress-bar progress-bar-info" role="progressbar"
            attr.aria-valuenow="{{ fileUploadThree.percent }}" aria-valuemin="0" aria-valuemax="100"
            [ngStyle]="{ width: fileUploadThree.percent + '%' }">
            {{ fileUploadThree.percent }}%
        </div>
    </div>
    <span class="badge bg-light text-dark" *ngIf="fileUploadFour">Foto 4</span>
    <div *ngIf="fileUploadFour" class="progress mt-2">
        <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ fileUploadFour.percent }}"
            aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: fileUploadFour.percent + '%' }">
            {{ fileUploadFour.percent }}%
        </div>
    </div>
    <div class="d-flex justify-content-end mt-5">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>