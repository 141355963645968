import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../../providers/categories.service';
import { AlertsService } from '../../providers/alerts.service';
import { Categorie } from '../../interfaces/categorie.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUpload } from 'src/app/models/fileUpload.model';
import { MainCategory } from 'src/app/interfaces/main-categorie.interface';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  showCategorieContainer: boolean;
  loading: boolean;
  categories: Categorie[] = [];
  categorieSelected: Categorie;
  categorieName: string = '';
  singularName: string = '';
  show: boolean = false;
  fileSelected: any;
  fileSelectedUrl: any;
  currentPercent: number = 0;
  loadingImage: boolean;
  fileToUpload: FileUpload;
  mainCategoryUid: string = '';
  mainCategories: MainCategory[] = [];

  constructor(
    private _categoriesService: CategoriesService,
    private _alertsService: AlertsService,
    private _modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getCategories();
  }

  activeCategorieContainer() {
    this.showCategorieContainer =
      this.showCategorieContainer === true ? false : true;
    this.show = false;
  }

  async saveCategorie(edit?: boolean) {
    try {
      const newCategorie = {
        name: this.categorieName,
        singularName: this.singularName,
        show: this.show,
      };
      this.loading = true;

      if (edit === true) {
        await this._categoriesService.editCategorie({
          uid: this.categorieSelected.uid,
          mainCategoryUid: this.mainCategoryUid,
          ...newCategorie,
        });
      } else {
        await this._categoriesService.addCategorie(newCategorie);
      }
      this._alertsService.toastAlert(
        'success',
        `Categoría ${edit === true ? 'actualizada' : 'guardada'}...`
      );
      this.getMainCategories();
      this.loading = false;
      this.showCategorieContainer = false;
      if (edit === true) {
        this._modalService.dismissAll();
      }
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  getCategories() {
    this._categoriesService.getCategories().subscribe((cats) => {
      this.categories = cats.map((c) => {
        if (!c.photoURL) {
          return {
            ...c,
            photoURL: '../../../assets/images/no-image-small.jpg',
          };
        }
        return c;
      });
    });
    this.getMainCategories();
  }

  async getMainCategories(): Promise<void> {
    this.mainCategories = await this._categoriesService.getMainCategories();
  }
  editCategorie(content: any, categorie: Categorie) {
    this.categorieSelected = categorie;
    this._modalService.open(content, { size: 'lg' });
    this.categorieName = this.categorieSelected.name;
    this.singularName =
      this.categorieSelected && this.categorieSelected.singularName
        ? this.categorieSelected.singularName
        : '';
    this.mainCategoryUid =
      this.categorieSelected && this.categorieSelected.mainCategoryUid
        ? this.categorieSelected.mainCategoryUid
        : '';
    this.show =
      this.categorieSelected && this.categorieSelected.show
        ? this.categorieSelected.show
        : false;
  }

  openCategorieImageModal(content, categorie: Categorie) {
    this.categorieSelected = categorie;
    this._modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
      })
      .result.then(
        (result) => {},
        (reason) => {
          setTimeout(() => {
            this.resetData();
          }, 500);
        }
      );
  }

  loadFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      // this.msg = 'You must select an image';
      return;
    }

    const mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      // this.msg = "Only images are supported";
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    const selectedFiles = event.target.files;
    this.fileSelected = selectedFiles.item(0);
    reader.onload = (_event) => {
      this.fileSelectedUrl = reader.result;
    };
  }

  uploadFile() {
    this.fileToUpload = new FileUpload(this.fileSelected);
    this._categoriesService
      .pushFileToStorage(
        this.fileToUpload,
        this.categorieSelected.uid,
        this.categorieSelected.name
      )
      .subscribe(
        (percentage) => {
          this.loadingImage = true;
          this.currentPercent = Math.round(percentage ? percentage : 0);
          if (Math.round(percentage ? percentage : 0) === 100) {
            this.loadingImage = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  resetData() {
    this.categorieSelected = null;
    this.fileSelected = null;
    this.fileSelectedUrl = null;
    this.currentPercent = 0;
  }

  async deleteCategoriePhoto() {
    try {
      this._alertsService.loading();
      await this._categoriesService.deleteFileStorage(
        this.categorieSelected.uid,
        this.categorieSelected.name
      );
      await this._categoriesService.deleteProductPhotos(
        this.categorieSelected.uid
      );
      this._alertsService.toastAlert('success', `Foto eliminada...`);
      this._modalService.dismissAll();
      setTimeout(() => {
        this.resetData();
      }, 1000);
    } catch (error) {
      this._alertsService.toastAlert('success', `Hubo un error...`);
    }
  }

  getMainCategoryName(mainCategoryUid: string): string {
    const mainCategory: MainCategory = this.mainCategories.find(
      (mc) => mc.uid === mainCategoryUid
    );
    if (mainCategory) {
      return mainCategory.name;
    }
    return '';
  }
}
