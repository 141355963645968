import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private customersCollection: AngularFirestoreCollection<any>;
  private adminsCollection: AngularFirestoreCollection<any>;
  customer$: Observable<any>;

  constructor(public auth: AngularFireAuth, private _afs: AngularFirestore) {
    this.customersCollection = _afs.collection<any>('customers');
    this.adminsCollection = _afs.collection<any>('admins');

    this.customer$ = this.auth.authState.pipe(
      switchMap((customer) => {
        if (customer) {
          return this._afs.doc<any>(`customers/${customer.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  googleLogin() {
    return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  addcustomer(customer: any, uid: string) {
    const customerToSave: any = {
      uid: uid,
      email: customer.email,
      photoURL: customer.photoURL,
      createdDate: new Date().getTime(),
    };
    return this.customersCollection.doc(uid).set(customerToSave);
  }

  logout() {
    this.auth.signOut();
  }

  getcustomer(id: string): Observable<any> {
    const customerDocument = this._afs.doc<any>('customers/' + id);
    return customerDocument.snapshotChanges().pipe(
      map((changes) => {
        const data = changes.payload.data();
        const uid = changes.payload.id;
        return { uid, ...data };
      })
    );
  }

  getAdmins(): Observable<any> {
    return this._afs.collection<any>('admins').valueChanges();
  }
}
