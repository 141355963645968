import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Promotion } from '../interfaces/promotions.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  private promotionsCollection: AngularFirestoreCollection<Promotion>;

  constructor(private _afs: AngularFirestore) {
    this.promotionsCollection = _afs.collection<Promotion>('promotions');
  }

  async addPromotion(promotion: any): Promise<void> {
    const id = this._afs.createId();

    const promotionToSave: Promotion = {
      uid: id,
      ...promotion,
    };
    this.promotionsCollection.doc(id).set(promotionToSave);
  }

  editPromotion(promotion: any) {
    promotion.updated = new Date().getTime();
    return this.promotionsCollection.doc(promotion.uid).update(promotion);
  }

  getPromotions(): Observable<Promotion[]> {
    let query = this._afs.collection<Promotion>('promotions', (ref: any) => {
      ref = ref.orderBy('created', 'desc');

      return ref;
    });

    return query.valueChanges();
  }
  1;
  deletePromotion(uid: string) {
    return this.promotionsCollection.doc(uid).delete();
  }
}
