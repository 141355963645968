import { Component, OnInit } from '@angular/core';
import { Brand } from '../../interfaces/brand.interface';
import { BrandsService } from '../../providers/brands.service';
import { AlertsService } from '../../providers/alerts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss'],
})
export class BrandsComponent implements OnInit {
  showBrandContainer: boolean;
  loading: boolean;
  brands: Brand[] = [];
  brandSelected: Brand;
  brandName: string = '';

  constructor(
    private _brandsService: BrandsService,
    private _alertsService: AlertsService,
    private _modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getBrands();
  }

  activeBrandContainer() {
    this.showBrandContainer = this.showBrandContainer === true ? false : true;
  }

  async saveBrand(edit?: boolean) {
    try {
      const newBrand = { name: this.brandName };
      this.loading = true;

      if (edit === true) {
        await this._brandsService.editBrand({
          uid: this.brandSelected.uid,
          ...newBrand,
        });
      } else {
        await this._brandsService.addBrand(newBrand);
      }
      this._alertsService.toastAlert(
        'success',
        `Marca ${edit === true ? 'actualizada' : 'guardada'}...`
      );
      this.loading = false;
      this.showBrandContainer = false;
      if (edit === true) {
        this._modalService.dismissAll();
      }
    } catch (error) {
      console.log(error, 'ERROR');
      this.loading = false;
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  getBrands() {
    this._brandsService.getBrands().subscribe((brands) => {
      this.brands = brands;
    });
  }

  editBrand(content: any, brand: Brand) {
    this.brandSelected = brand;
    this._modalService.open(content, { size: 'lg' });
  }
}
