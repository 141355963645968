import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-pagination',
  templateUrl: './admin-pagination.component.html',
  styleUrls: ['./admin-pagination.component.scss'],
})
export class AdminPaginationComponent implements OnInit {
  pagesNumber: number = 1;
  @Input() dataSize: number = 0;
  pagesArray: Number[] = [];
  currentPage: number = 1;
  @Output() sendData: EventEmitter<number> = new EventEmitter();
  mobile: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (window.innerWidth < 800) {
      this.mobile = true;
    }
    this.pagesNumber = Math.ceil(this.dataSize / 10);
    for (let i = 0; i < this.pagesNumber; i++) {
      this.pagesArray.push(i);
    }
  }

  pageSelected(page: number) {
    this.currentPage = page;
    this.sendData.emit(page);
  }
}
