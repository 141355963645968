<ng-template #modalPercentContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Descuento a elegir <i class="fas fa-percentage"></i>
        </h4>
        <button type="button" class="btn btn-dark btn-sm" (click)="closePercentModal()">
            <i class="fa-solid fa-circle-xmark"></i>
        </button>
    </div>
    <div class="modal-body pt-5 pb-5">
        <div class="row justify-content-center">
            <div class="col-8">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">Sin descuento</th>
                            <th scope="col" class="text-center">Con descuento</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center">
                                {{ getCurrentProductDiscount() | currency }}
                            </th>
                            <td class="text-center">
                                <b>{{ getCurrentProductDiscount(true) | currency }}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row form-group justify-content-center pb-5">
            <div class="col-8 pb-5">
                <select [(ngModel)]="percent" class="form-control text-center" (change)="applyPercent()">
                    <option *ngFor="let p of percents" [value]="p">
                        {{p}} %
                    </option>
                </select>
            </div>
        </div>
        <div class="row form-group justify-content-center pb-5">
            <div class="col-8 pb-5 d-flex justify-content-center">
                <button class="btn btn-success me-3" (click)="applyPercent(true)">Aplicar</button>
                <button class="btn btn-danger" (click)="percentCancelled()">Cancelar</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #changeSectionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Detalles del cambio
        </h4>
        <button type="button" class="btn btn-dark btn-sm" (click)="_ngbModalService.dismissAll()">
            <i class="fa-solid fa-circle-xmark"></i>
        </button>
    </div>
    <div class="modal-body pt-5 pb-5">
        <div class="row">
            <div class="col-12">
                <label for="idGlobalOfPrevTicketInput" class="form-label fw-bold">N° ticket</label>
                <input type="number" class="form-control" id="idGlobalOfPrevTicketInput"
                    placeholder="Número de ticket anterior" min="0" [(ngModel)]="idGlobalOfPrevTicket">
            </div>
            <div class="col-12 d-flex justify-content-center pt-4 pb-2">
                <button type="button" class="btn btn-info"
                    [disabled]="idGlobalOfPrevTicket <= 0 || idGlobalOfPrevTicket == null || loadingWhileSearchingForPreviousTicket"
                    (click)="findPrevTicket()">
                    <ng-container *ngIf="loadingWhileSearchingForPreviousTicket">
                        Cargando...
                    </ng-container>
                    <ng-container *ngIf="!loadingWhileSearchingForPreviousTicket">
                        Buscar
                    </ng-container>
                </button>
            </div>
            <div class="col-12 mt-3">
                <label for="moneyInBoxFromPreviousTicketInput" class="form-label fw-bold">Total ticket anterior</label>
                <input type="number" class="form-control" id="moneyInBoxFromPreviousTicketInput" [disabled]="true"
                    placeholder="Total ticket anterior" [(ngModel)]="moneyInBoxFromPreviousTicket">
            </div>
            <div class="col-12 mt-3 mb-3">
                <label class="form-label fw-bold">Fecha del ticket anterior</label>
                <p *ngIf="prevTicket">{{getDateWithMoment(prevTicket.created)}}</p>
            </div>
            <div class="row mt-3 mb-3 pb-3">
                <div class="col-6">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <button class="btn btn-primary w-100"
                                [disabled]="!idGlobalOfPrevTicket > 0 || (!prevTicket)"
                                (click)="saveDataOfTicketChange()">Guardar</button>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <button class="btn btn-secondary w-100"
                                (click)="closeChangeSectionModal()">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #paymentModalContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Pago {{ticket.paymentMethod === 1 ? 'en efectivo' : 'con tarjeta'}}
        </h4>
        <button type="button" class="btn btn-dark btn-sm" (click)="closePaymentAndOnlyCleanTicketData()"
            [disabled]="paymentCompleted || saveTicketLoading">
            <i class="fa-solid fa-circle-xmark"></i>
        </button>
    </div>
    <div class="modal-body pt-5 pb-5">
        <ng-container *ngIf="ticket.paymentMethod === 1">
            <h4 class="text-center">Cantidad recibida:</h4>
            <div class="row justify-content-center">
                <div class="col-4 p-2" *ngFor="let bill of bills">
                    <div class="card text-white date" (click)="onBillSelectedChange(bill)"
                        [ngClass]="{'bg-primary': billSelected === bill, 'bg-dark':billSelected !== bill}">
                        <div class="card-body date">
                            <h5 class="text-white">
                                {{bill | currency}}
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-4 p-2">
                    <div class="card text-white date" (click)="onBillSelectedChange(calculateTotal())"
                        [ngClass]="{'bg-primary': billSelected === calculateTotal(), 'bg-dark':billSelected !== calculateTotal()}">
                        <div class="card-body date">
                            <h5 class="text-white">
                                {{calculateTotal() | currency}}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 row date">
                <label for="otherCantity" class="col-2 col-form-label fs-6 fw-bold">Otra cantidad:</label>
                <div class="col-10">
                    <input type="number" class="form-control" id="otherCantity" [(ngModel)]="otherCantity"
                        [disabled]="paymentCompleted" (ngModelChange)="onOtherCantityChange()">
                </div>
            </div>
            <div class="mt-2">
                <ng-container *ngIf="!ticket.itsChange">
                    <h3 class="text-center" *ngIf="ticket">
                        Total: <span class="badge bg-success">{{calculateTotal() | currency}}</span>
                    </h3>
                    <h3 class="text-center">
                        Cambio: <span class="badge bg-warning text-dark"> {{change | currency}}</span>
                    </h3>
                </ng-container>
                <ng-container *ngIf="ticket.itsChange">
                    <h3 class="text-center" *ngIf="ticket">
                        Restante: <span class="badge bg-success">{{calculateTotal() -
                            ticket.moneyInBoxFromPreviousTicket | currency}}</span>
                    </h3>
                    <h3 class="text-center">
                        Cambio: <span class="badge bg-warning text-dark"> {{change | currency}}</span>
                    </h3>
                </ng-container>

            </div>
            <div class="row justify-content-center mt-5 mb-3">
                <div class="col-6">
                    <button class="btn btn-primary w-100 fs-4" (click)="completePayment()" *ngIf="!paymentCompleted"
                        [disabled]="saveTicketLoading">
                        <ng-container *ngIf="!saveTicketLoading">
                            Completar pago
                        </ng-container>
                        <ng-container *ngIf="saveTicketLoading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </ng-container>
                    </button>
                    <button class="btn btn-secondary w-100 fs-4" (click)="closePaymentAndOnlyCleanTicketData(true)"
                        *ngIf="paymentCompleted">Cerrar venta</button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="ticket.paymentMethod === 2">
            <div class="container">
                <ng-container *ngIf="ticket">
                    <ng-container *ngIf="!ticket.itsChange">
                        <h3 class="text-center">
                            Total: <span class="badge bg-success">{{calculateTotal() | currency}}</span>
                        </h3>
                    </ng-container>
                    <ng-container *ngIf="ticket.itsChange">
                        <h3 class="text-center">
                            Restante: <span class="badge bg-success">{{calculateTotal() -
                                ticket.moneyInBoxFromPreviousTicket | currency}}</span>
                        </h3>
                    </ng-container>
                </ng-container>
                <h6 class="text-center">
                    Escoge un método de pago:
                </h6>
                <div class="d-flex justify-content-center mt-5 mb-5 pb-5">
                    <div class="btn-group-vertical">
                        <button type="button" class="btn btn-info" (click)="selectCardPaymentMethod(cpm.uid)"
                            [disabled]="cardPaymentMethodSelected && cardPaymentMethodSelected.length > 0"
                            *ngFor="let cpm of cardPaymentMethods">{{cpm.name}}</button>
                    </div>
                </div>
                <h5 class="text-center mt-3 mb-3"
                    *ngIf="cardPaymentMethodSelected && cardPaymentMethodSelected.length > 0">
                    Haz seleccionado {{getcardPaymentMethodName(cardPaymentMethodSelected)}}
                </h5>
                <div class="row justify-content-center mt-5 mb-3 pb-5" *ngIf="cardPaymentMethodSelected">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-6">
                                <div class="alert alert-warning text-center" role="alert">
                                    Si ya realizaste el cobro con éxito presiona COMPLETAR PAGO
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-primary w-100 fs-4" (click)="completePayment()"
                            [disabled]="saveTicketLoading" *ngIf="!paymentCompleted">
                            <ng-container *ngIf="!saveTicketLoading">
                                Completar pago
                            </ng-container>
                            <ng-container *ngIf="saveTicketLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </ng-container>
                        </button>
                        <button class="btn btn-secondary w-100 fs-4" (click)="closePaymentAndOnlyCleanTicketData(true)"
                            *ngIf="paymentCompleted">Cerrar venta</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<div class="container pb-5">
    <div class="row bg-primary pt-2 pb-2">
        <h5 class="fw-bold text-white">{{ getDateWithMoment(today) | titlecase }}</h5>
    </div>
    <div class="right border mt-3">
        <div class="cart">
            <div class="header">
                <div class="row p-0" style="padding: 0 !important; margin: 0 !important;">
                    <div class="col-10" style="padding: 0 !important; margin: 0 !important;">
                        Cuenta actual en caja {{cashRegister}}
                    </div>
                </div>
            </div>
            <p style="padding-bottom: 0 !important; margin-bottom: 0 !important;">
                {{getMovementsForCashRegister().length}} items</p>
            <!-- <button class="btn btn-warning me-2" (click)="demostrarCapacidades()">Imprimir</button> -->


            <div class="row item" *ngFor="let mov of getMovementsForCashRegister(); let i = index;">
                <div class="col-4 align-self-center">
                    <img [src]="getProduct(mov.productUid).photoUrl" [alt]="getProduct(mov.productUid).name"
                        class="img-fluid" alt="product"
                        *ngIf="getProduct(mov.productUid).photoUrl && getProduct(mov.productUid).photoUrl !== ''">
                    <img src="../../../assets/images/imagen_no_disponible.png" [alt]="getProduct(mov.productUid).name"
                        class="img-fluid" alt="product"
                        *ngIf="!getProduct(mov.productUid).photoUrl || getProduct(mov.productUid).photoUrl === ''">
                </div>
                <div class="col-8">
                    <div class="row text-muted fw-bold fs-6 mb-1">
                        {{ getProductName(mov.productUid) }}
                    </div>
                    <div class="row text-muted">
                        <section class="col-2 d-flex justify-content-center center">
                            <button class="btn btn-dark btn-sm text-white"
                                [disabled]="mov.productsNumber === 1 || paymentOptions"
                                (click)="editProductsNumberProperty('decrement', mov.productUid)">
                                <i class="fa-solid fa-minus"></i>
                            </button>
                        </section>
                        <section class="col-6 fs-5 text-center">
                            <input type="number" name="qty" id="qty+{{mov.productUid}}"
                                class="form-control w-100 number" [(ngModel)]="mov.productsNumber" min="1"
                                [disabled]="paymentOptions">
                        </section>
                        <section class="col-2 d-flex justify-content-center center">
                            <button class="btn btn-dark btn-sm text-white" [disabled]="paymentOptions"
                                (click)="editProductsNumberProperty('increment', mov.productUid)">
                                <i class="fa-solid fa-plus"></i>
                            </button>
                        </section>
                        <section class="col-2 d-flex justify-content-center center">
                            <button class="btn btn-danger btn-sm text-white" (click)="removeMovement(mov.productUid)"
                                [disabled]="paymentOptions">
                                <i class="fa-solid fa-xmark"></i>
                            </button>
                        </section>
                        <section class="col-4 mt-1">
                            <button type="button" class="btn btn-warning btn-sm percentBtn" id="btnPercent{{i}}"
                                (click)="openPercentModal(modalPercentContent, mov.productUid)"
                                *ngIf="!productIsOffSale(mov.productUid)" [disabled]="paymentOptions">
                                {{ mov.cartDiscount }} <i class="fa-solid fa-percent"></i>
                            </button>
                        </section>
                        <section class="col-8 d-flex justify-content-end customGreenText mt-1">
                            <ng-container *ngIf="mov.cartDiscount > 0">
                                <span class="fw-bold fs-6 mt-1 text-decoration-line-through text-secondary ms-1 me-1">
                                    {{ mov.productsNumber * mov.costByUnit | currency }}
                                </span>
                            </ng-container>
                            <span class="fw-bold fs-6 mt-1 text-decoration-line-through text-secondary ms-1 me-1"
                                *ngIf="productIsOffSale(mov.productUid)">
                                {{ mov.productsNumber * getProduct(mov.productUid).previousPrice | currency }}
                            </span>
                            <span class="fw-bold fs-6 mt-1">
                                {{ mov.productsNumber * getMovementPrice(mov) | currency }}
                            </span>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="paymentTotals">
            <div class="row lower">
                <div class="col text-left">Subtotal</div>
                <div class="col text-right">
                    {{ calculateSubTotal() | currency }}
                </div>
            </div>
            <div class="row lower" *ngIf="calculateSubTotal() !== calculateTotal()">
                <div class="col text-left"><b>Descuento</b></div>
                <div class="col text-right">
                    {{ calculateSubTotal() - calculateTotal() | currency }}
                </div>
            </div>
            <div class="row lower">
                <div class="col text-left"><b>Total</b></div>
                <div class="col text-right">
                    <b>{{ calculateTotal() | currency }}</b>
                </div>
            </div>
            <div class="row mt-1" style="min-height: 2rem;" *ngIf="ticket">
                <div class="col-6">
                    <div class="form-check form-switch" style="padding-top: 0 !important; margin-top: 0 !important;">
                        <input class="form-check-input" type="checkbox" id="isChangeCheck"
                            (change)="updateStatusOfTicketChange($event)" [(ngModel)]="ticket.itsChange"
                            [checked]="ticket.itsChange" [disabled]="paymentOptions"
                            style="font-size: 0.75rem !important; padding-top: 0 !important; margin-top: 0 !important; cursor: pointer;">
                        <label class="form-check-label" for="isChangeCheck">Es cambio?</label>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-end" style="padding: 0 !important; margin: 0 !important;"
                    *ngIf="ticket.itsChange">
                    <button class="btn btn-info btn-sm me-2" (click)="openChangeSectionModal(changeSectionModal)">
                        <i class="fa-solid fa-arrow-right-arrow-left"></i>
                    </button>
                </div>
            </div>
            <button class="btn w-100 customGreenBg text-white fw-bold fs-4 mt-3"
                [disabled]="!validateIfThePaymentCanBeCompleted() || categories.length === 0 || brands.length === 0"
                (click)="showPaymentOptions()" *ngIf="!paymentOptions">
                Cobrar
            </button>
            <div class="row p-0 m-0" *ngIf="paymentOptions">
                <div class="col-6 p-1">
                    <button type="button" class="btn btn-primary w-100"
                        (click)="openPaymentModal(1, paymentModalContent)">
                        Efectivo
                    </button>
                </div>
                <div class="col-6 p-1">
                    <button type="button" class="btn btn-secondary w-100"
                        (click)="openPaymentModal(2, paymentModalContent)">
                        Tarjeta
                    </button>
                </div>
                <div class="col-12 p-1 mt-1">
                    <button type="button" class="btn btn-danger w-100" (click)="revertPaymentOptions()">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>