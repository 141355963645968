<div class="main">
    <div class="row pt-0 pb-0 mt-0 mb-2" *ngIf="mode === 'month'">
        <div class="col-md-3"></div>
        <div class="col-md-3 marginResponsive">
            <div class="row justify-content-center" *ngIf="showByRange">
                <div class="col-md-10">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="startDateInput"
                            ngbDatepicker #d="ngbDatepicker" autocomplete="off"
                            (ngModelChange)="changeStartDateInput()" />
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <i class="fas fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 marginResponsive">
            <div class="row justify-content-center" *ngIf="showByRange">
                <div class="col-md-10">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="endDateInput"
                            ngbDatepicker #d="ngbDatepicker" autocomplete="off"
                            (ngModelChange)="changeEndDateInput()" />
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <i class="fas fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="d-flex justify-content-end">
                <button class="btn btn-dark text-white btn-sm" (click)="showDataByRange()">
                    <i class="fa-solid fa-eye" *ngIf="showByRange"></i>
                    <i class="fa-solid fa-eye-slash" *ngIf="!showByRange"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="row pt-0 pb-0 mt-0 mb-0">
        <div class="col-2">
            <div class="d-flex justify-content-start" *ngIf="!showByRange">
                <button class="btn btn-secondary btn-sm" (click)="previous()">
                    <i class="fas fa-chevron-left"></i>
                </button>
            </div>
        </div>
        <div class="col-8">
            <p class="text-center fs-3 text-dark date">
                <span class="fs-3 fw-bold date2">{{ getShortDate(startDate) }}</span> - <span
                    class="fs-3 fw-bold date2">{{
                    getShortDate(endDate)
                    }}</span>
            </p>
        </div>
        <div class="col-2">
            <div class="d-flex justify-content-end" *ngIf="!showByRange">
                <button class="btn btn-secondary btn-sm" (click)="next()">
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 mt-2">
            <div class="d-flex justify-content-start" *ngIf="mode === 'month'">
                <button class="btn btn-info btn-sm" (click)="dataSection = 1" *ngIf="dataSection === 2">
                    <i class="fas fa-angle-double-left"></i>
                </button>
                <button class="btn btn-info btn-sm" (click)="dataSection = 2" *ngIf="dataSection === 3">
                    <i class="fas fa-angle-double-left"></i>
                </button>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center mt-2" *ngIf="dataSection === 1">
            <div class="d-flex flex-column">
                <span class="fs-5 fw-bold text-center info">Total:</span>
                <span class="badge bg-success fs-4 text-center cashText info2">
                    {{ (tickets.length > 0 ? calculateTotalWeek() : 0) | currency }}
                </span>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center mt-2" *ngIf="dataSection === 1">
            <div class="d-flex flex-column">
                <span class="fs-5 fw-bold text-center info">Promedio:</span>
                <span class="badge bg-primary fs-4 text-center cashText info2">
                    {{ (tickets && tickets.length > 0 ? calculateAverageForTheWeek() : 0 )| currency }}
                </span>
            </div>
        </div>
        <div class="col-8 d-flex justify-content-center mt-2" *ngIf="dataSection === 2">
            <div class="row">
                <div class="col-4 d-flex justify-content-center mt-2" [ngClass]="{'col-4': !mobile, 'col-6': mobile}">
                    <div class="d-flex flex-column">
                        <span class="fs-5 fw-bold text-center info">Efectivo:</span>
                        <span class="badge bg-secondary fs-4 text-center cashText info2">
                            {{ (tickets.length > 0 ? calculateTotalWeek('totalCash') : 0) | currency }}
                        </span>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center mt-2" [ngClass]="{'col-4': !mobile, 'col-6': mobile}">
                    <div class="d-flex flex-column">
                        <span class="fs-5 fw-bold text-center info">Tarjeta:</span>
                        <span class="badge bg-secondary fs-4 text-center cashText info2">
                            {{ (tickets.length > 0 ? calculateTotalWeek('totalCard') : 0) | currency }}
                        </span>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center mt-2" [ngClass]="{'col-4': !mobile, 'col-12': mobile}">
                    <div class="d-flex flex-column">
                        <span class="fs-5 fw-bold text-center info">Otros:</span>
                        <span class="badge bg-primary fs-4 text-center cashText info2">
                            {{ (tickets && tickets.length > 0 ? calculateTotalWeek('totalOthers') : 0 )| currency }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8 d-flex justify-content-center mt-2" *ngIf="dataSection === 3">
            <div class="row">
                <div class="d-flex justify-content-center mt-2" [ngClass]="{'col-4': !mobile, 'col-6': mobile}">
                    <div class="d-flex flex-column">
                        <span class="fs-5 fw-bold text-center info"><i class="fas fa-users"></i></span>
                        <span class="badge bg-success fs-4 text-center cashText info2">
                            {{ getAverage(true) }}
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-2" [ngClass]="{'col-4': !mobile, 'col-6': mobile}">
                    <div class="d-flex flex-column">
                        <span class="fs-5 fw-bold text-center info"><i class="fas fa-user"></i></span>
                        <span class="badge bg-success fs-4 text-center cashText info2">
                            {{calculateClientsAverageForRange()}}
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-2" [ngClass]="{'col-4': !mobile, 'col-12': mobile}">
                    <div class="d-flex flex-column">
                        <span class="fs-5 fw-bold text-center info"><i class="fas fa-money-check"></i></span>
                        <span class="badge bg-primary fs-4 text-center cashText info2">
                            {{ getAverage() | currency }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-2 mt-2">
            <div class="d-flex justify-content-end" *ngIf="mode === 'month'">
                <button class="btn btn-info btn-sm" (click)="dataSection = 2" *ngIf="dataSection === 1">
                    <i class="fas fa-angle-double-right"></i>
                </button>
                <button class="btn btn-info btn-sm" (click)="dataSection = 3" *ngIf="dataSection === 2">
                    <i class="fas fa-angle-double-right"></i>
                </button>
            </div>
        </div>

    </div>
    <div class="row justify-content-center pb-5 mb-5">
        <div class="col-12">
            <div style="display: block;" *ngIf="!hourView">
                <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                    [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
                </canvas>
            </div>
            <div style="display: block;" *ngIf="hourView">
                <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions2"
                    [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
                </canvas>
            </div>
        </div>
    </div>
    <button class="btn btn-success btn-sm floating-button" (click)="watchByHour()" *ngIf="!hourView">
        <i class="fas fa-clock"></i>
    </button>
    <button class="btn btn-secondary btn-sm floating-button" (click)="watchNormalView()" *ngIf="hourView">
        <i class="fas fa-undo-alt"></i>
    </button>
</div>