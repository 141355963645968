import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { PublicModule } from './public/public.module';

const routes: Routes = [
  // { path: '', loadChildren: () => PublicModule },
  // { path: 'admin', loadChildren: () => AdminRoutingModule },
  // { path: '**', component: PublicModule }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
