import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter } from 'src/app/interfaces/filter.interface';
import { Label, ProductLabel } from 'src/app/interfaces/label.interface';
import { MostSelledProduct } from 'src/app/interfaces/mostSelledProduct.interface';
import { Movement } from 'src/app/interfaces/movement';
import { Product } from 'src/app/interfaces/product.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { LabelsService } from 'src/app/providers/labels.service';
import { MovementsService } from 'src/app/providers/movements.service';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-sold-products-report',
  templateUrl: './sold-products-report.component.html',
  styleUrls: ['./sold-products-report.component.scss'],
})
export class SoldProductsReportComponent implements OnInit {
  @Input() sortFunction!: <T>(array: T[], field: string) => T[];
  @Input() isActiveColumnFunction!: (column: string) => boolean;
  @Input() filterDesc: { [key: string]: boolean } = {};
  @Input() filters: Filter[] = [];
  @Input() products: Product[] = [];
  @Input() mostSelledProducts: MostSelledProduct[] = [];
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() loadingOperations: boolean;
  @Input() totalSales: number;
  showStockActive: boolean;
  stockLoader: boolean;
  modeLabel: boolean;
  labels: Label[] = [];
  mostSelledProductSelected: MostSelledProduct;
  selectedLabel: string = '';
  loadingProductLabel: boolean;
  productLabels: ProductLabel[] = [];
  loadingModeLabel: boolean;

  mostSelledProductsDataToShow: MostSelledProduct[] = [];
  formData = {
    total: null,
    totalSales: null,
  };

  constructor(
    public _productsService: ProductsService,
    private _modalService: NgbModal,
    private _movementsService: MovementsService,
    private _labelsService: LabelsService,
    private _alertsService: AlertsService
  ) {}

  ngOnInit(): void {
    this.mostSelledProductsDataToShow = this.mostSelledProducts;
    this._labelsService.getLabels().subscribe((labels) => {
      this.labels = labels;
    });
  }

  sortData(data: any, propertyName: string) {
    if (this.sortFunction) {
      this.sortFunction(data, propertyName);
    }
  }

  checkColumnStatus(column: string): boolean {
    if (this.isActiveColumnFunction) {
      return this.isActiveColumnFunction(column);
    }
    return false;
  }

  openModalFilters(content: any): void {
    this._modalService.open(content, { size: 'md' });
  }

  onSubmit(form: any) {
    if (form.valid) {
      this._modalService.dismissAll();
      const { totalSales, total } = this.formData;
      this.mostSelledProductsDataToShow = this.mostSelledProducts.filter(
        (p) => p.totalSales >= totalSales || p.total >= total
      );
    }
  }

  resetData(): void {
    this.mostSelledProductsDataToShow = this.mostSelledProducts;
    this.formData.total = null;
    this.formData.totalSales = null;
  }

  formDataFilterIsActive(): boolean {
    if (this.formData.total !== null && this.formData.totalSales !== null) {
      return true;
    }
    return false;
  }

  sumProperty(array: any[], property: string): number {
    return array.reduce((acc, item) => {
      if (item[property] !== undefined && typeof item[property] === 'number') {
        return acc + item[property];
      }
      return acc;
    }, 0);
  }

  getMedian(array: any[], property: string): number {
    const values = array
      .map((item) => item[property])
      .filter((value) => typeof value === 'number')
      .sort((a, b) => a - b);

    const length = values.length;

    if (length === 0) return 0;

    const middle = Math.floor(length / 2);

    const median: number =
      length % 2 !== 0
        ? values[middle]
        : (values[middle - 1] + values[middle]) / 2;

    return parseFloat(median.toFixed(2));
  }

  getAverage(array: any[], property: string): number {
    const values = array
      .map((item) => item[property])
      .filter((value) => typeof value === 'number');

    const length = values.length;

    if (length === 0) return 0;

    const totalSum = values.reduce((sum, value) => sum + value, 0);
    const average: number = totalSum / length;
    return parseFloat(average.toFixed(2));
  }

  getTotalPercent(currentValue: number, maxValue: number): number {
    const total: number = (currentValue * 100) / maxValue;
    return parseFloat(total.toFixed(2));
  }

  showStock(): void {
    if (this.showStockActive) {
      this.showStockActive = false;
    } else {
      this.showStockActive = true;
      this.getProductsStock();
    }
  }

  async getProductsStock(): Promise<void> {
    try {
      this.stockLoader = true;
      for (let i = 0; i < this.mostSelledProductsDataToShow.length; i++) {
        const p = this.mostSelledProductsDataToShow[i];
        if (!('stock' in p)) {
          let productMovements: Movement[] =
            await this._movementsService.getMovementsByProductUidOnce(
              p.productUid
            );
          productMovements = productMovements.map((pm) => {
            return this.mapMovement(pm);
          });
          const entries: number = this._movementsService.getItemsNumber(
            productMovements.filter((m) => m.type === 1)
          );
          const removals: number = this._movementsService.getItemsNumber(
            productMovements.filter((m) => m.type === 2)
          );
          const sales: number = this._movementsService.getItemsNumber(
            productMovements.filter((m) => m.type === 3)
          );
          const stock: number = entries - removals - sales;
          // this.mostSelledProductsDataToShow[i].stock = stock;
          const index = this.mostSelledProductsDataToShow.findIndex(
            (obj) => obj.productUid === p.productUid
          );

          if (index !== -1) {
            this.mostSelledProductsDataToShow.splice(index, 1, {
              ...this.mostSelledProductsDataToShow[index],
              stock,
            });
          }
        }
      }
      this.stockLoader = false;
    } catch (error) {}
  }

  mapMovement(movement: Movement): Movement {
    return {
      ...movement,
      uid: !movement.uid ? movement.id : movement.uid,
      costByUnit: Number(movement.costByUnit),
      productsNumber: Number(movement.productsNumber),
      type: Number(movement.type),
    };
  }

  showModeLabel(): void {
    this.loadingModeLabel = true;
    if (this.modeLabel) {
      this.modeLabel = false;
      this.loadingModeLabel = false;
    } else {
      this.getProductLabelsData();
    }
  }

  getProductLabelsData(): void {
    this._labelsService.getProductsLabel().subscribe((productLabels) => {
      this.productLabels = productLabels;
      this.loadingModeLabel = false;
      this.modeLabel = true;
    });
  }

  openModalLabels(content: any, product: MostSelledProduct): void {
    if (product.stock === 0) {
      this.mostSelledProductSelected = product;
      this._modalService.open(content, { size: 'md' });
    }
  }

  async saveProductLabel(): Promise<void> {
    try {
      const newProductLabel: ProductLabel = {
        labelUid: this.selectedLabel,
        productUid: this.mostSelledProductSelected.productUid,
      };
      this.loadingProductLabel = true;

      await this._labelsService.addProductLabel(newProductLabel);

      this._alertsService.toastAlert(
        'success',
        `Se ha guardado correctamente...`
      );
      this.loadingProductLabel = false;
      this._modalService.dismissAll();
    } catch (error) {
      this.loadingProductLabel = false;
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  productHasLabelWithColor(productUid: string, keyColor: string): boolean {
    if (this.showStock && !this.stockLoader && this.modeLabel) {
      const label: Label = this.labels.find((l) => l.key === keyColor);
      const productLabel: ProductLabel = this.productLabels.find(
        (pl) => pl.productUid === productUid && pl.labelUid === label.uid
      );

      if (productLabel) {
        return true;
      }

      return false;
    }
    return false;
  }

  productHasLabel(productUid: string): boolean {
    if (this.showStock && !this.stockLoader && this.modeLabel) {
      const productLabel: ProductLabel = this.productLabels.find(
        (pl) => pl.productUid === productUid
      );

      if (productLabel) {
        return true;
      }

      return false;
    }
    return false;
  }

  getProductLabel(productUid: string): ProductLabel {
    const productLabel: ProductLabel = this.productLabels.find(
      (pl) => pl.productUid === productUid
    );
    return productLabel;
  }

  async deleteProductLabel(productLabelUid: string): Promise<void> {
    try {
      const alertResponse: any = await this._alertsService.confirmation(
        '¿Estás seguro de eliminar?'
      );
      if (alertResponse.value) {
        this.loadingProductLabel = true;
        await this._labelsService.deleteProductLabel(productLabelUid);
        this._alertsService.toastAlert(
          'success',
          `Se ha eliminado correctamente...`
        );
        this.loadingProductLabel = false;
        this._modalService.dismissAll();
        // this.getProductLabelsData();
      }
    } catch (error) {
      this.loadingProductLabel = false;
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  getTotalsByLabel(labelUid: string): number {
    if (
      this.modeLabel &&
      this.showStockActive &&
      !this.stockLoader &&
      !this.loadingModeLabel
    ) {
      let productsUid: any[] = this.productLabels.filter(
        (pl) => pl.labelUid === labelUid
      );

      productsUid = productsUid.map((pu) => {
        let totalItem = this.mostSelledProductsDataToShow.find(
          (msp) => msp.productUid === pu.productUid
        );

        let total = totalItem ? totalItem.total : 0;

        const newData: any = { productUid: pu.productUid, total: total };

        return newData;
      });

      return productsUid.reduce((accumulator, product) => {
        return accumulator + product.total;
      }, 0);
    }

    return 0;
  }
}
