import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Ticket } from '../interfaces/ticket.interface';
import { CardPaymentMethod } from '../interfaces/cardPaymentMethods.interface';
import { Observable } from 'rxjs';
import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  private ticketsCollection: AngularFirestoreCollection<Ticket>;
  private cardPaymentMethodsCollection: AngularFirestoreCollection<CardPaymentMethod>;

  constructor(
    private _afs: AngularFirestore,
    private _alertsService: AlertsService
  ) {
    this.ticketsCollection = _afs.collection<Ticket>('tickets');
    this.cardPaymentMethodsCollection =
      _afs.collection<CardPaymentMethod>('cardPaymentMethods');
  }

  getCardPaymentMethods(): Observable<CardPaymentMethod[]> {
    return this.cardPaymentMethodsCollection.valueChanges();
  }

  generateTicketUid(): string {
    return this._afs.createId();
  }

  async saveTicket(ticket: Ticket): Promise<void> {
    try {
      await this.ticketsCollection.doc(ticket.uid).set(ticket);
    } catch (error) {
      throw error;
    }
  }

  async editTicket(ticket: Ticket) {
    try {
      await this.ticketsCollection.doc(ticket.uid).update(ticket);
    } catch (error) {
      throw error;
    }
  }

  async getLastRecord(): Promise<any> {
    try {
      const collectionRef = 'tickets';
      const query = this._afs.collection(collectionRef, (ref) =>
        ref.orderBy('created', 'desc').limit(1)
      );

      const querySnapshot: any = await query
        .get({ source: 'server' })
        .toPromise();

      if (!querySnapshot) {
        throw new Error('Query snapshot is null or undefined.');
      }

      if (querySnapshot.empty) {
        // throw new Error('Query result is empty.');
        return null;
      }

      const documentData = querySnapshot.docs[0].data();
      if (!documentData) {
        throw new Error('Document data is null or undefined.');
      }

      // console.log('Document data:', documentData);
      return documentData;
    } catch (error) {
      console.error('Error during getLastRecord:', error);
      return {
        error: true,
        message:
          error.message || 'Error al obtener el último registro de tickets',
      };
    }
  }

  async getTodayLastRecord(): Promise<any> {
    try {
      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      const todayStartDate: number = startDate.getTime();

      const endDate = new Date();
      endDate.setHours(23, 0, 0, 0);
      const todayendDate: number = endDate.getTime();
      const collectionRef = 'tickets';
      const querySnapshot: any = await this._afs
        .collection(collectionRef, (ref) =>
          ref
            .where('created', '>=', todayStartDate)
            .where('created', '<=', todayendDate)
            .orderBy('created', 'desc')
            .limit(1)
        )
        .get()
        .toPromise();
      if (!querySnapshot.empty) {
        const documentData = querySnapshot.docs[0].data();
        return documentData;
      } else {
        return null;
      }
    } catch (error) {
      return {
        error: true,
        message: 'Error al obtener el último registro de tickets de hoy',
      };
    }
  }

  async getTodayTickets(dateParam?: Date): Promise<Ticket[]> {
    try {
      const startDate = dateParam ? dateParam : new Date();
      startDate.setHours(0, 0, 0, 0);
      const todayStartDate: number = startDate.getTime();

      const endDate = dateParam ? dateParam : new Date();
      endDate.setHours(23, 0, 0, 0);
      const todayendDate: number = endDate.getTime();

      const collectionRef = 'tickets';
      const querySnapshot: any = await this._afs
        .collection(collectionRef, (ref) =>
          ref
            .where('created', '>=', todayStartDate)
            .where('created', '<=', todayendDate)
            .orderBy('created', 'desc')
        )
        .get()
        .toPromise();

      if (!querySnapshot.empty) {
        const ticketArray = querySnapshot.docs.map(
          (doc) => doc.data() as Ticket
        );
        return ticketArray;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  }

  async getTicketByField(
    field: string,
    value: any,
    expression: any
  ): Promise<Ticket[] | null> {
    try {
      const collectionRef = 'tickets';
      const querySnapshot: any = await this._afs
        .collection(collectionRef, (ref) =>
          ref.where(field, expression, value).orderBy('created', 'desc')
        )
        .get()
        .toPromise();
      if (!querySnapshot.empty) {
        // const documentData = querySnapshot.docs[0].data();
        // return documentData;
        const ticketArray: Ticket[] = querySnapshot.docs.map(
          (doc) => doc.data() as Ticket[]
        );
        return ticketArray;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async getTicketsByRange(
    startDateParam: number,
    endDateParam: number
  ): Promise<Ticket[]> {
    try {
      const collectionRef = 'tickets';
      const querySnapshot: any = await this._afs
        .collection(collectionRef, (ref) =>
          ref
            .where('created', '>=', startDateParam)
            .where('created', '<=', endDateParam)
            .orderBy('created', 'desc')
        )
        .get()
        .toPromise();

      if (!querySnapshot.empty) {
        const ticketArray = querySnapshot.docs.map(
          (doc) => doc.data() as Ticket
        );
        return ticketArray;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  }

  async getTicketsChanged(tickets: Ticket[]): Promise<Ticket[]> {
    const ticketsCancelled: Ticket[] = tickets.filter((t) => t.status === 3);

    for (const ticketCancelled of ticketsCancelled) {
      const ticketChanged: Ticket[] = await this.getTicketByField(
        'idGlobalOfPrevTicket',
        ticketCancelled.globalTicketNumber,
        '=='
      );

      let ticketsChangedArray: Ticket[];
      if (ticketChanged) {
        ticketsChangedArray = ticketChanged.filter((tc) => tc.status === 2);
      }

      if (
        ticketsChangedArray &&
        ticketsChangedArray.length === 1 &&
        ticketsChangedArray[0].status === 2
      ) {
        const index = tickets.findIndex(
          (t) => t.globalTicketNumber === ticketCancelled.globalTicketNumber
        );
        if (index !== -1) {
          tickets[index].ifItsCanceledAddTotalToTheDaySale = true;
        }
      }
    }

    return tickets;
  }

  groupSalesByHour(tickets: any[]) {
    const sales = tickets.map((s) => {
      return { ...s, created: new Date(s.created) };
    });

    const salesByHour = {};

    sales.forEach((sale) => {
      const date = new Date(sale.created);
      const day = date.toLocaleDateString();
      const hour = date.getHours();

      if (!salesByHour[day]) {
        salesByHour[day] = {};
      }

      if (!salesByHour[day][hour]) {
        salesByHour[day][hour] = 0;
      }

      salesByHour[day][hour]++;
    });

    return salesByHour;
  }

  calculateTheAverage(
    tickets: any[],
    calculateTotal: () => number,
    totalTicketsResult?: boolean
  ): number {
    const totalTickets: number = tickets.filter(
      (t) => t.status === 1 || t.status === 2
    ).length;

    if (totalTickets === 0) {
      return 0;
    }

    const total: number = calculateTotal() / totalTickets;

    if (!totalTicketsResult) {
      return parseFloat(total.toFixed(2));
    }
    return totalTickets;
  }
}
