import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Brand } from '../interfaces/brand.interface';
import { Product } from '../interfaces/product.interface';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  private brandsCollection: AngularFirestoreCollection<Brand>;

  constructor(private _afs: AngularFirestore) {
    this.brandsCollection = _afs.collection<Brand>('brands');
  }

  async addBrand(brand: any): Promise<void> {
    const id = this._afs.createId();

    const brandsToSave: Brand = {
      uid: id,
      ...brand,
    };
    this.brandsCollection.doc(id).set(brandsToSave);
  }

  getBrands(): Observable<Brand[]> {
    return this.brandsCollection.valueChanges();
  }

  editBrand(brand: Brand) {
    return this.brandsCollection.doc(brand.uid).update(brand);
  }

  getBrandName(brandUid: string, brands: Brand[]): string {
    if (brandUid !== '') {
      return brands.find((b) => b.uid === brandUid).name;
    }
    return '';
  }

  async getBrandsPromise(): Promise<Brand[]> {
    try {
      const collectionRef = 'brands';
      const querySnapshot: any = await this._afs
        .collection(collectionRef)
        .get()
        .toPromise();
      if (!querySnapshot.empty) {
        const brandsArray = querySnapshot.docs.map(
          (doc) => doc.data() as Brand
        );
        return brandsArray;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  }
}
