import { Component, OnInit } from '@angular/core';
import { AuthService } from '../providers/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  hasAccess: boolean;
  loading: boolean;

  constructor(public _authService: AuthService) {}

  ngOnInit() {
    this.loading = true;
    this._authService.auth.user.subscribe((user) => {
      if (user) {
        this._authService.getAdmins().subscribe((data: any[]) => {
          const admin = data.find((a) => a.email === user.email);
          if (admin && admin.superUser) {
            this.hasAccess = true;
          } else {
            this.hasAccess = false;
          }
          this.loading = false;
        });
      } else {
        this.hasAccess = false;
        this.loading = false;
      }
    });
  }

  async login() {
    const customerResponse = await this._authService.googleLogin();
    console.log(customerResponse, 'user');
    this._authService
      .getcustomer(customerResponse.user.uid)
      .subscribe((user) => {
        if (!user.email) {
          this._authService.addcustomer(
            customerResponse.user,
            customerResponse.user.uid
          );
        }
      });
  }

  logout() {
    this._authService.logout();
  }
}
