import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Brand } from 'src/app/interfaces/brand.interface';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { Movement } from 'src/app/interfaces/movement';
import { Product } from 'src/app/interfaces/product.interface';
import { Ticket } from 'src/app/interfaces/ticket.interface';
import { BrandsService } from 'src/app/providers/brands.service';
import { CategoriesService } from 'src/app/providers/categories.service';
import { ProductsService } from 'src/app/providers/products.service';
import { TicketsService } from 'src/app/providers/tickets.service';

@Component({
  selector: 'app-main-checkout',
  templateUrl: './main-checkout.component.html',
  styleUrls: ['./main-checkout.component.scss'],
})
export class MainCheckoutComponent implements OnInit {
  movements: Movement[] = [];
  movementsOriginalCopy: Movement[] = [];
  tabs = [1];
  counter = 2;
  active = 1;
  products: Product[] = [];
  productsCopy: Product[] = [];
  categories: Categorie[] = [];
  brands: Brand[] = [];
  loading: boolean;
  tickets: Ticket[] = [];
  ticket: Ticket;
  mobile: boolean;

  constructor(
    private _productsService: ProductsService,
    private _brandsService: BrandsService,
    private _categoriesService: CategoriesService,
    private _ticketsService: TicketsService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth <= 800) {
      this.mobile = true;
    }
    if (!this.mobile) {
      this.loading = true;
      this.getData();
    }
  }

  getData(): void {
    this._brandsService
      .getBrands()
      .pipe(take(1))
      .subscribe((brands) => {
        this.brands = brands;
      });
    this._categoriesService
      .getCategories()
      .pipe(take(1))
      .subscribe((categories) => {
        this.categories = categories;
        this.getProducts();
      });
  }

  getProducts(): void {
    this._productsService
      .getProducts()
      .pipe(take(1))
      .subscribe((products) => {
        // this.products = this.products.
        let productsData = products.filter((p) => !p.outOfStock);
        productsData = productsData.filter((p) => !p.outOfStock);

        this.productsCopy = productsData;
        this.products = productsData.map((p) => {
          const product = {
            ...p,
            nameCopy: p.name,
            name: `${this._categoriesService.getCategorieName(
              p.categorieUid,
              this.categories
            )} ${this._brandsService.getBrandName(p.brandUid, this.brands)} ${
              p.name
            }`,
          };
          return product;
        });
        this.loading = false;
        this.getTicketInitData();
      });
  }

  close(event: MouseEvent, toRemove: number) {
    // Verificar que no estemos cerrando la primera tab
    if (this.tabs.length > 1 && toRemove !== 1) {
      this.tabs = this.tabs.filter((id) => id !== toRemove);
      event.preventDefault();
      event.stopImmediatePropagation();

      // Asegurarnos de que la tab activa siga siendo válida después de cerrar
      if (toRemove === this.active) {
        this.active = this.tabs[0];
        this.getTicketInitData();
      }

      // Resetear el contador a 1 cuando se borra la segunda tab
      if (toRemove === 2) {
        this.counter = 2;
        this.clearMovementsForCashRegister(2);
        this.tickets = this.tickets.filter((t) => t.cashRegister !== 2);
      }
    }
  }

  add(event: MouseEvent) {
    if (this.tabs.length < 2) {
      this.tabs.push(this.counter++);
      this.active = this.tabs[this.tabs.length - 1];
      this.getTicketInitData();
      event.preventDefault();
    }
  }

  addMovement(mov: Movement): void {
    const find: Movement = this.movements.find(
      (m) => m.productUid === mov.productUid && m.cashRegister === this.active
    );

    if (!find) {
      const newMov: Movement = {
        cashRegister: Number(this.active),
        cartDiscount: 0,
        uid: this._ticketsService.generateTicketUid(),
        ...mov,
      };
      this.movements.push(newMov);
      this.movementsOriginalCopy.push(newMov);
    }
  }

  removeMovementForCart(productUid: string): void {
    this.movements = this.movements.filter(
      (m) => !(m.productUid === productUid && m.cashRegister === this.active)
    );
    this.movementsOriginalCopy = this.movementsOriginalCopy.filter(
      (m) => !(m.productUid === productUid && m.cashRegister === this.active)
    );
  }

  incrementOrDecrementProductsNumberProperty(data: {
    value: 'increment' | 'decrement';
    productUid: string;
  }): void {
    const index: number = this.movements.findIndex(
      (m) => m.productUid === data.productUid && m.cashRegister === this.active
    );
    let items = this.movements[index].productsNumber;
    this.movements[index] = {
      ...this.movements[index],
      productsNumber: data.value === 'increment' ? items + 1 : items - 1,
    };
  }

  changeStateOfMovementDataByUid(movement): void {
    const index: number = this.movements.findIndex(
      (m) =>
        m.productUid === movement.productUid && m.cashRegister === this.active
    );

    this.movements[index] = {
      ...movement,
    };
  }

  clearMovementsForCashRegister(cashRegister: number): void {
    this.movements = this.movements.filter(
      (m) => m.cashRegister !== cashRegister
    );
    this.movementsOriginalCopy = this.movementsOriginalCopy.filter(
      (m) => m.cashRegister !== cashRegister
    );
  }

  getTicket(): Ticket {
    return this.tickets.find((t) => t.cashRegister === this.active);
  }

  getTicketInitData(): void {
    const ticketExist: Ticket = this.getTicket();

    if (!ticketExist) {
      this.tickets.push({
        accumulatedSalesOfUninventoryedThings: 0,
        cashRegister: this.active,
        created: new Date().getTime(),
        dayTicketNumber: 0,
        globalTicketNumber: 0,
        itsChange: false,
        paymentMethod: 1,
        status: 1,
        subtotal: 0,
        total: 0,
        uid: this._ticketsService.generateTicketUid(),
      });
    }
    this.ticket = this.getTicket();
  }

  selectCurrentTicket(): void {
    this.getTicketInitData();
  }

  changeStateOfTicketDataByUid(ticket: Ticket): void {
    const index: number = this.tickets.findIndex(
      (t) => t.uid === ticket.uid && t.cashRegister === this.active
    );

    this.tickets[index] = {
      ...ticket,
    };

    this.ticket = this.getTicket();
  }

  cleanCash(cashNumber: number) {
    this.clearMovementsForCashRegister(cashNumber);
    this.tickets = this.tickets.filter((t) => t.cashRegister !== cashNumber);
    this.getTicketInitData();
  }

  cleanCashOnlyTicket(cashNumber: number) {
    this.tickets = this.tickets.filter((t) => t.cashRegister !== cashNumber);
    this.getTicketInitData();
  }
}
