<!-- Popular Products Start-->
<section class="my-lg-14 my-8">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-6">

                <h3 class="mb-0">Productos destacados</h3>

            </div>
        </div>

        <div class="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-3">
            <div class="col" *ngFor="let product of products|slice:0:10">
                <app-product-card [product]="product" [loading]="loading"></app-product-card>
            </div>
        </div>
    </div>
</section>
<!-- Popular Products End-->