<ng-container *ngIf="!mobile">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs mt-1">
        <li *ngFor="let id of tabs" [ngbNavItem]="id">
            <a ngbNavLink>
                <b>Caja {{ id }}</b>
                <span class="close position-relative pl-2 font-weight-light ms-2" (click)="close($event, id)"
                    *ngIf="id !== 1">
                    <i class="fas fa-times text-secondary" style="font-size: 1rem;"></i>
                </span>
            </a>
            <ng-template ngbNavContent>
                <div class="d-flex justify-content-center pt-5 mt-5" *ngIf="loading">
                    <div class="loader mt-5"></div>
                </div>
                <div class="row" *ngIf="!loading" style="min-height: 75vh;">
                    <div class="col-8">
                        <app-first-checkout-section [products]="products"
                            (sendMovement)="addMovement($event)"></app-first-checkout-section>
                    </div>
                    <div class="col-4">
                        <app-second-checkout-section [movements]="movements"
                            [movementsOriginalCopy]="movementsOriginalCopy" [products]="products"
                            [cashRegister]="active" [tickets]="tickets" [ticket]="ticket"
                            (productUidForRemoveMovement)="removeMovementForCart($event)"
                            (incrementOrDecrementProductsNumberProperty)="incrementOrDecrementProductsNumberProperty($event)"
                            (changeStateOfMovementDataByUid)="changeStateOfMovementDataByUid($event)"
                            (selectCurrentTicket)="selectCurrentTicket($event)"
                            (changeStateOfTicketDataByUid)="changeStateOfTicketDataByUid($event)"
                            (cleanCash)="cleanCash($event)"
                            (cleanCashOnlyTicket)="cleanCashOnlyTicket($event)"></app-second-checkout-section>
                    </div>
                </div>
            </ng-template>
        </li>
        <li class="nav-item" style="cursor: pointer;">
            <a class="nav-link" (click)="add($event)">
                <i class="fas fa-plus"></i>
            </a>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

</ng-container>
<div class="d-flex justify-content-center mt-5 pb-5" *ngIf="mobile">
    <button type="button" class="btn btn-info" routerLink="/checkout/tickets">Ir a tickets</button>
</div>
<!-- <pre>Active: {{ active }}</pre> -->