<ng-template #modalTicketView let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Ticket #{{ticketSelected.globalTicketNumber}}
        </h4>
        <button type="button" class="btn btn-dark btn-sm" (click)="closeModalTicketView()">
            <i class="fa-solid fa-circle-xmark"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <app-ticket-view [ticket]="ticketSelected"></app-ticket-view>
        </div>
    </div>
</ng-template>
<div class="container">
    <div class="row height d-flex justify-content-center align-items-center" *ngIf="searchMode">
        <div class="col-2"></div>
        <div class="col-8 mt-1">
            <div class="form">
                <input type="number" class="form-control form-input" placeholder="Buscar ticket por n° global..."
                    autocomplete="off" [(ngModel)]="globalTicketNumber"
                    (keydown.enter)="searchTicketByglobalTicketNumber()">
                <span class="left-pan">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
        <div class="col-2 d-flex justify-content-end">
            <button class="btn btn-dark btn-sm" (click)="activeSearchMode()">
                <i class="fas fa-window-close"></i>
            </button>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-4" *ngIf="!searchMode && !adminMode">
        <button class="btn btn-warning btn-sm" (click)="activeSearchMode()">
            <i class="fas fa-search"></i>
        </button>
    </div>
    <div class="row pt-0 pb-0 mt-0 mb-0" *ngIf="!searchMode && adminMode">
        <div class="col-md-4">
            <div class="d-flex justify-content-start">
                <button class="btn btn-info btn-sm" (click)="printTotalSale()" [disabled]="loadingPrint">
                    <i class="fa-solid fa-print"></i>
                </button>
            </div>
        </div>
        <div class="col-md-4 marginResponsive">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="newDate"
                            ngbDatepicker #d="ngbDatepicker" autocomplete="off"
                            (ngModelChange)="changeDateFromParent()" />
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <i class="fas fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="d-flex justify-content-end"
                style="display: flex; align-items: center; justify-content: center; justify-items: center;"
                *ngIf="!searchMode && adminMode">
                <button class="btn btn-warning btn-sm" (click)="activeSearchMode()">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="row justify-content-end">
        <div class="col-4 mt-2 mb-2">
            <div class="d-flex justify-content-start" *ngIf="showSaleInfo">
                <button class="btn btn-dark btn-sm" (click)="reloadTicketsData()" [disabled]="reloadTicketsDataLoading">
                    <i class="fas fa-undo"></i>
                </button>
            </div>
        </div>
        <div class="col-4 mt-2 mb-2">
            <div class="d-flex justify-content-center" *ngIf="!searchMode && !adminMode">
                <button class="btn" [ngClass]="{'btn-secondary': showSaleInfo, 'btn-primary': !showSaleInfo}"
                    (click)="activeShowSaleInfo()">
                    {{showSaleInfo ? 'Cerrar' : 'Ver más'}}
                </button>
            </div>
        </div>
        <div class="col-4 mt-2 mb-2">
            <div class="d-flex justify-content-end">
                <button class="btn btn-info" (click)="printTotalSale()" [disabled]="loadingPrint"
                    *ngIf="showSaleInfo">Imprimir
                    resumen</button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="showSaleInfo || adminMode">
        <div class="row justify-content-center mt-2 mb-2" *ngIf="!searchMode">
            <h3 class="text-center" *ngIf="!adminMode">Resumen venta de hoy:</h3>
            <div class="row pt-0 pb-0 mt-0 mb-0" *ngIf="adminMode">
                <div class="col-2">
                    <div class="d-flex justify-content-start">
                        <button class="btn btn-secondary btn-sm" (click)="changeCurrentDateData(-1)">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </div>
                </div>
                <div class="col-8" *ngIf="!loadingTwo">
                    <h3 class="text-center">Venta {{isToday(dateParam) ? 'hoy' : getMomentShortDate()}}</h3>
                </div>
                <div class="col-2">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-secondary btn-sm" (click)="changeCurrentDateData(1)"
                            [disabled]="isToday(dateParam)">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex justify-content-center mt-2">
                <div class="d-flex flex-column" *ngIf="!mobile">
                    <span class="fs-5 fw-bold text-center">
                        {{totalIsActive ? 'Promedio:' : 'Total:'}}
                    </span>
                    <span class="badge bg-success fs-4 text-center" style="cursor: pointer;" *ngIf="!totalIsActive"
                        (click)="mouseOnTotal(true)">
                        {{calculateTotal() + calculateTotalOfTicketsChanged() + calculateTotalOfTicketsCancelled() |
                        currency }}
                    </span>
                    <span class="badge bg-success fs-4 text-center" style="cursor: pointer;" *ngIf="totalIsActive"
                        (click)="mouseOnTotal(false)">
                        {{getAverage() | currency }}
                    </span>
                </div>
                <div class="d-flex flex-row" *ngIf="mobile" style="align-items: center !important;">
                    <span class="fw-bold fs-5 text-center me-2">
                        <i class="fa-solid fa-cash-register"></i>
                    </span>
                    <span class="badge bg-success fs-4 text-center" (click)="mouseOnTotal(true)" *ngIf="!totalIsActive">
                        {{calculateTotal() + calculateTotalOfTicketsChanged() + calculateTotalOfTicketsCancelled() |
                        currency }}
                    </span>
                    <span class="badge bg-success fs-4 text-center" (click)="mouseOnTotal(false)" *ngIf="totalIsActive">
                        {{getAverage() | currency }}
                    </span>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center mt-2 marginResponsive2">
                <div class="d-flex flex-column" *ngIf="!mobile">
                    <span class="fs-5 fw-bold text-center">Efectivo:</span>
                    <span class="badge bg-primary fs-4 text-center cashText">
                        {{calculateTotal('cash') + calculateTotalOfTicketsChanged('cash') +
                        calculateTotalOfTicketsCancelled('cash') | currency }}
                    </span>
                </div>
                <div class="d-flex flex-row" *ngIf="mobile" style="align-items: center !important;">
                    <span class="fw-bold fs-5 text-center me-2">
                        <i class="fa-solid fa-money-bill"></i> </span>
                    <span class="badge bg-primary fs-4 text-center cashText">
                        {{calculateTotal('cash') + calculateTotalOfTicketsChanged('cash') +
                        calculateTotalOfTicketsCancelled('cash') | currency }}
                    </span>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center mt-2">
                <div class="d-flex flex-column" *ngIf="!mobile">
                    <span class="fs-5 fw-bold text-center">Tarjeta:</span>
                    <span class="badge bg-primary fs-4 text-center cashText">
                        {{calculateTotal('card') + calculateTotalOfTicketsChanged('card') +
                        calculateTotalOfTicketsCancelled('card') | currency }}
                    </span>
                </div>
                <div class="d-flex flex-row" *ngIf="mobile" style="align-items: center !important;">
                    <span class="fw-bold fs-5 text-center ms-2 me-2">
                        <i class="fa-solid fa-credit-card"></i>
                    </span>
                    <span class="badge bg-primary fs-4 text-center cardText">
                        {{calculateTotal('card') + calculateTotalOfTicketsChanged('card') +
                        calculateTotalOfTicketsCancelled('card') | currency }}
                    </span>
                </div>
            </div>
        </div>
        <!-- <div class="d-flex justify-content-end pt-1 pb-1" *ngIf="!searchMode">
            <button class="btn btn-info" (click)="printTotalSale()" [disabled]="loadingPrint">Imprimir resumen</button>
        </div> -->
    </ng-container>
    <div class="table-wrapper-scroll-y my-custom-scrollbar mt-5" *ngIf="!loading">
        <table class="table table-hover mt-5 mb-5 pb-5">
            <thead>
                <tr>
                    <th colspan="7" *ngIf="mobile">
                        {{tickets.length}} tickets
                    </th>
                    <ng-container *ngIf="!mobile">
                        <th scope="col">N° día</th>
                        <th scope="col">N° global</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Total</th>
                        <th>Tipo de cobro</th>
                        <th>Creado</th>
                        <th scope="col">Acciones</th>
                    </ng-container>
                </tr>
            </thead>
            <tbody *ngIf="!loading">
                <tr *ngFor="let ticket of tickets; let i = index;">
                    <th scope="row" *ngIf="!mobile">
                        <!-- {{ tickets.length - i }} -->
                        #{{ticket.dayTicketNumber}}
                    </th>
                    <td class="textResp">
                        <b>#{{ticket.globalTicketNumber}}</b>
                    </td>
                    <td>
                        <span class="badge bg-success"
                            *ngIf="ticket.status === 1 && !ticket.ifItsCanceledAddTotalToTheDaySale">
                            <ng-container *ngIf="!mobile">
                                Venta
                            </ng-container>
                            <ng-container *ngIf="mobile">
                                <i class="fa-solid fa-arrow-right text-center"></i>
                            </ng-container>
                        </span>
                        <span class="badge bg-info"
                            *ngIf="ticket.status === 2 && !ticket.ifItsCanceledAddTotalToTheDaySale">
                            <ng-container *ngIf="!mobile">
                                Cambio
                            </ng-container>
                            <ng-container *ngIf="mobile">
                                <i class="fa-solid fa-arrow-right-arrow-left"></i>
                            </ng-container>
                        </span>
                        <span class="badge bg-danger"
                            *ngIf="ticket.status === 3 && !ticket.ifItsCanceledAddTotalToTheDaySale">
                            <ng-container *ngIf="!mobile">
                                Cancelado
                            </ng-container>
                            <ng-container *ngIf="mobile">
                                <i class="fas fa-window-close text-center"></i>
                            </ng-container>
                        </span>
                        <span class="badge bg-warning text-dark"
                            *ngIf="ticket.status === 3 && ticket.ifItsCanceledAddTotalToTheDaySale">
                            <ng-container *ngIf="!mobile">
                                Cancelado por cambio
                            </ng-container>
                            <ng-container *ngIf="mobile">
                                <i class="fa-solid fa-arrow-rotate-left"></i>
                            </ng-container>
                        </span>
                    </td>
                    <td class="textResp">
                        {{ticket.total | currency}}
                    </td>
                    <td>
                        <b *ngIf="ticket.paymentMethod == 1 && !mobile">Efectivo</b>
                        <b *ngIf="ticket.paymentMethod == 2  && !mobile">Tarjeta</b>
                        <b *ngIf="ticket.paymentMethod == 1 && mobile">
                            <i class="fa-solid fa-money-bill"></i>
                        </b>
                        <b *ngIf="ticket.paymentMethod == 2  && mobile">
                            <i class="fa-solid fa-credit-card"></i>
                        </b>
                    </td>
                    <td class="textResp">
                        <ng-container *ngIf="isToday(ticket.created)">
                            {{getCreatedDate(ticket.created)}}
                        </ng-container>
                        <ng-container *ngIf="!isToday(ticket.created)">
                            {{getMomentTimeDate(ticket.created)}}
                        </ng-container>
                    </td>
                    <td class="buttons-responsive">
                        <button class="btn btn-info btn-sm me-3" [disabled]="loadingPrint" (click)="printTicket(ticket)"
                            *ngIf="!adminMode">
                            <i class="fas fa-print"></i>
                        </button>
                        <button type="button" class="btn btn-primary btn-sm mb-1 mb-lg-0 textResp"
                            (click)="openTicketViewModal(modalTicketView, ticket)">
                            <i class="fas fa-info-circle"></i>
                        </button>
                        <button type="button" class="btn btn-danger btn-sm ms-lg-3 textResp"
                            *ngIf="ticket.status !== 3 && adminMode || !adminMode" [disabled]="ticket.status === 3"
                            (click)="cancelTicket(ticket)">
                            <i class="fas fa-window-close"></i>
                        </button>
                        <button type="button" class="btn btn-secondary btn-sm ms-lg-3 textResp"
                            *ngIf="ticket.status === 3 && adminMode" (click)="revertTicket(ticket)">
                            <i class="fas fa-undo"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="alert alert-secondary" role="alert" *ngIf="tickets && tickets.length && tickets.length === 0">
        No hay tickets para mostrar...
    </div>
    <ng-container *ngIf="loading">
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </ng-container>
</div>