<section *ngIf="loading !== true">
    <div class="mt-4" *ngIf="modeModal !== true">
        <div class="container">
            <!-- row -->
            <div class="row ">
                <!-- col -->
                <div class="col-12">
                    <!-- breadcrumb -->
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><a class="hover-blue" routerLink="/">Home</a></li>
                            <li class="breadcrumb-item">
                                <a class="link hover-blue" (click)="selectCategorie(product.categorieUid)"
                                    *ngIf="product">{{product.categorieName}}</a>
                            </li>

                            <li class="breadcrumb-item active" *ngIf="product">
                                {{brandName.length > 0 ? ((brandName + ' -' + ' ')) : ''}} {{product.name}}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <section class="mt-8">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <!-- img slide -->
                    <div class="product" id="product">
                        <div *ngIf="product && photoSelected">
                            <div class="row justify-content-center">
                                <img [src]="photoSelected.url" alt="product.name" class="w-75 bigPicture">
                            </div>
                        </div>
                    </div>
                    <!-- product tools -->
                    <div class="product-tools">
                        <div class="thumbnails row g-3" id="productThumbnails">
                            <!-- tns-nav-active -->
                            <div class="col-3" *ngFor="let photo of productPhotos"
                                [ngClass]="{'tns-nav-active': photoSelected === photo}">
                                <div class="thumbnails-img smallPicture" (click)="photoSelected = photo"
                                    style="cursor: pointer;">
                                    <img class="smallPicture2" [src]="photo.url" alt="photo.name">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="ps-lg-10 mt-6 mt-md-0">
                        <!-- content -->
                        <a class="mb-1 d-block" (click)="selectCategorie(product.categorieUid)"
                            *ngIf="product && !mobile">{{product.categorieName}}</a>
                        <!-- heading -->
                        <!-- <span class="badge bg-light text-dark" *ngIf="!mobile">{{brandName}}</span><br> -->
                        <h1 class="mb-1" *ngIf="product">{{brandName.length > 0 ? ((brandName + ' -' + ' ')) : ''}}
                            {{product.name}}</h1>
                        <span class="badge bg-success" *ngIf="stock > 0">Disponible</span>
                        <span class="badge bg-secondary" *ngIf="stock === 0">Agotado</span>
                        <br>
                        <!-- <span class="badge bg-secondary">Agotado</span> -->

                        <div class="fs-4">
                            <!-- price -->
                            <span class="fw-bold text-dark" *ngIf="product">{{product.price | currency}}</span>
                            <!-- <span class="text-decoration-line-through text-muted">$35</span> -->
                            <!-- <span><small class="fs-6 ms-2 text-danger">26% Off</small></span> -->
                        </div>
                        <!-- hr -->
                        <!-- <hr class="my-6"> -->
                        <!-- <div class="mb-5">
                            <button type="button" class="btn btn-outline-secondary me-2">250g</button>
                            <button type="button" class="btn btn-outline-secondary me-2">500g</button>
                            <button type="button" class="btn btn-outline-secondary">1kg</button>
                        </div> -->
                        <section *ngIf="product && product.colors" class="mt-1">
                            <b>Colores:</b><br>
                            <ng-container *ngFor="let color of product.colors">
                                <span class="badge rounded-pill me-2"
                                    [ngStyle]="{'background-color': getHexCode(color), 'color': getTextColor(color) }">
                                    {{getColorName(color)}}
                                </span>
                            </ng-container>
                        </section>
                        <div class="mt-2 row justify-content-start g-2 align-items-center">

                            <div class="col-xxl-4 col-lg-4 col-md-5 col-5 d-grid">
                                <button type="button" class="btn btn-primary" (click)="imInterested(product.uid)">
                                    <i class="fa-brands fa-whatsapp me-2"></i>Me interesa
                                </button>
                            </div>
                        </div>
                        <hr class="my-2" *ngIf="product && product.colors && product.colors.length > 0">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="mt-lg-4 mt-1">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-lb-tab" id="myTab" role="tablist">
                        <!-- nav item -->
                        <li class="nav-item" role="presentation">
                            <!-- btn --> <button class="nav-link active" id="product-tab" data-bs-toggle="tab"
                                data-bs-target="#product-tab-pane" type="button" role="tab"
                                aria-controls="product-tab-pane" aria-selected="true">Descripción del producto</button>
                        </li>

                    </ul>
                    <!-- tab content -->
                    <div class="tab-content" id="myTabContent">
                        <!-- tab pane -->
                        <div class="tab-pane fade show active" id="product-tab-pane" role="tabpanel"
                            aria-labelledby="product-tab" tabindex="0">
                            <div class="my-8" [innerHTML]="product.description">
                                {{product.description}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>



    </section>

    <!-- section -->
    <section class="my-lg-14 my-14" *ngIf="modeModal !== true">
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-12">
                    <!-- heading -->
                    <h3>Artículos relacionados</h3>
                </div>
            </div>
            <!-- row -->
            <div class="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-2 mt-2">
                <div class="col" *ngFor="let product of relatedProducts|slice:0:10">
                    <app-product-card [product]="product"></app-product-card>
                </div>
            </div>
        </div>
    </section>
</section>
<app-whatsapp-button></app-whatsapp-button>
<div class="d-flex justify-content-center pt-5 pb-10 mt-5 mb-10" *ngIf="loading">
    <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>