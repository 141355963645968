import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-summary',
  templateUrl: './stock-summary.component.html',
  styleUrls: ['./stock-summary.component.scss']
})
export class StockSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
