import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Product } from '../../interfaces/product.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Movement } from 'src/app/interfaces/movement';

@Component({
  selector: 'app-first-checkout-section',
  templateUrl: './first-checkout-section.component.html',
  styleUrls: ['./first-checkout-section.component.scss'],
})
export class FirstCheckoutSectionComponent implements OnInit, AfterViewInit {
  @Input() products: Product[] = [];
  @ViewChild('yourInput') yourInput: ElementRef;
  @Output() sendMovement: EventEmitter<Movement> = new EventEmitter();
  inputText: string = '';
  currentProductsData: Product[] = [];
  productSelected: Product;
  editPrice: boolean;

  constructor(
    private _alertsService: AlertsService,
    private _ngbModalService: NgbModal
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.focusSearchInput();
  }

  focusSearchInput(): void {
    this.yourInput.nativeElement.focus();
  }

  readText() {
    this.currentProductsData = this._filter(this.inputText);
  }

  private _filter(value: string): Product[] {
    const filterValues = value
      .toLowerCase()
      .split(' ')
      .filter((val) => val.trim() !== '');

    if (filterValues.length === 0) {
      return [];
    }

    const filteredOptions = this.products.filter((option) => {
      const optionNameLower = option.name.toLowerCase();
      return filterValues.every((filter) => optionNameLower.includes(filter));
    });

    return filteredOptions
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 12);
  }

  openImage(urlImage: string, name: string): void {
    this._alertsService.openImageFromCheckout(urlImage, name);
  }

  openModal(modalContent: any, product: Product): void {
    const newProduct = { ...product };
    this.productSelected = newProduct;
    this._ngbModalService.open(modalContent, {
      backdrop: 'static',
      size: 'sm',
    });
  }

  closeModal() {
    this._ngbModalService.dismissAll();
    this.editPrice = false;
    this.focusSearchInput();
  }

  addProductsToCart(): void {
    const movement: Movement = {
      productUid: this.productSelected.uid,
      type: 3,
      productsNumber: 1,
      costByUnit: Number(this.productSelected.price),
      categoryUid: this.productSelected.categorieUid,
      created: new Date().getTime(),
    };
    this.sendMovement.emit(movement);
    this.closeModal();
    this.resetSearchData();
  }

  resetSearchData(): void {
    this.inputText = '';
    this.currentProductsData = [];
  }
}
