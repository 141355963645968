<ng-template #modalContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Validación <i class="far fa-check-circle text-success"></i>
        </h4>
        <button type="button" class="btn btn-dark btn-sm" (click)="closeModal()">
            <i class="fa-solid fa-circle-xmark"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row justify-content-center pt-5 pb-5">
            <div class="col-md-6">
                <img [src]="productSelected.photoUrl" [alt]="productSelected.name" class="card-img-top" alt="product"
                    *ngIf="productSelected.photoUrl && productSelected.photoUrl !== ''"
                    (click)="openImage(productSelected.photoUrl, productSelected.name)">
                <img src="../../../assets/images/imagen_no_disponible.png" [alt]="productSelected.name"
                    class="card-img-top" alt="product"
                    *ngIf="!productSelected.photoUrl || productSelected.photoUrl === ''">
            </div>
        </div>
        <p class="fs-6 fw-bold mt-2 mb-5 text-center w-100">
            {{productSelected.name}}
        </p>
        <div class="row" style="margin-top: 1.5rem;">
            <section class="col-9">
                <input name="productPrice" class="form-control text-center" [(ngModel)]="productSelected.price"
                    [disabled]="!editPrice" [value]="productSelected.price " autocomplete="off">
            </section>
            <section class="col-3 center">
                <button class="btn btn-success btn-sm" (click)="editPrice = editPrice === true ? false : true"
                    [disabled]="productSelected.offSale">
                    <i class="fas fa-edit"></i>
                </button>
            </section>
        </div>
        <p class="fw-light text-center text-danger" *ngIf="productSelected.offSale">
            Productos con descuento no se permite editar su precio.
        </p>
        <button class="btn btn-primary w-100" style="margin-top: 1rem;" (click)="addProductsToCart()">
            Continuar
        </button>
    </div>
</ng-template>

<div class="container">
    <div class="row height d-flex justify-content-center align-items-center">
        <div class="col-md-10 col-xl-8 mt-1">
            <div class="form">
                <input type="text" class="form-control form-input" placeholder="Buscar por nombre o clave..."
                    autocomplete="off" [(ngModel)]="inputText" (ngModelChange)="readText()" #yourInput>
                <span class="left-pan">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="row mt-2 p-2">
        <ng-container *ngIf="currentProductsData.length > 0">
            <div class="col-3" *ngFor="let product of currentProductsData">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-12 col-xl-10">
                        <div class="card w-100 rounded">
                            <img [src]="product.photoUrl" [alt]="product.name" class="card-img-top" alt="product"
                                *ngIf="product.photoUrl && product.photoUrl !== ''"
                                (click)="openImage(product.photoUrl, product.name)">
                            <img src="../../../assets/images/imagen_no_disponible.png" [alt]="product.name"
                                class="card-img-top" alt="product" *ngIf="!product.photoUrl || product.photoUrl === ''">
                            <div class="card-body">
                                <h6 class="card-title">{{product.name}}</h6>
                                <h5>
                                    <span class="fw-bold text-decoration-line-through text-secondary me-2"
                                        *ngIf="product.offSale">
                                        {{ product.previousPrice | currency }}
                                    </span>
                                    {{product.price | currency}}
                                </h5>
                                <button class="btn btn-primary w-100" (click)="openModal(modalContent, product)">
                                    <i class="fas fa-plus-circle" style="font-size: 1.2rem;"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="currentProductsData.length === 0 && inputText.length === 0">
            <div class="p-5 mt-5">
                <div class="row justify-content-center p-5 bg-dark" style="height: 60vh;">
                    <div class="col-6 d-flex justify-content-center mt-5 mb-5"
                        style="display: flex; align-items: center;">
                        <div class="custom">
                            <img src="../../../assets/original.png" alt="logo" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="currentProductsData.length === 0 && inputText.length !== 0">
            <div class="p-5 mt-5">
                <div class="alert alert-secondary" role="alert">
                    No se encontraron resultados...
                </div>
            </div>
        </ng-container>
    </div>
</div>