import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { MainCheckoutComponent } from './main-checkout/main-checkout.component';
import {
  NgbNavModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FirstCheckoutSectionComponent } from './first-checkout-section/first-checkout-section.component';
import { FormsModule } from '@angular/forms';
import { SecondCheckoutSectionComponent } from './second-checkout-section/second-checkout-section.component';
import { SharedModule } from '../modules/shared/shared.module';

@NgModule({
  declarations: [
    CheckoutComponent,
    MainCheckoutComponent,
    FirstCheckoutSectionComponent,
    SecondCheckoutSectionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CheckoutRoutingModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbModalModule,
    FormsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
})
export class CheckoutModule {}
