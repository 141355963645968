<div class="row justify-content-center pb-2">
    <div class="col-md-4">
        <input type="text" class="form-control w-100" placeholder="{{placeholder}}" [(ngModel)]="name" (keyup)="
            sendData()" />
    </div>
</div>
<div class="row justify-content-center pb-2">
    <div class="col-md-4">
        <div class="d-flex justify-content-center w-100" *ngIf="showButton === true">
            <button class="btn btn-primary" [ngClass]="{'btn-warning': edit}" (click)="sendData()"
                [disabled]="name.length < 3 || loading">{{edit === true
                ? 'Actualizar' : 'Guardar'}}</button>
        </div>
    </div>
</div>