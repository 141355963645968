import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutComponent } from './checkout.component';
import { MainCheckoutComponent } from './main-checkout/main-checkout.component';
import { TicketsComponent } from '../modules/shared/tickets/tickets.component';

const routes: Routes = [
  {
    path: 'checkout',
    component: CheckoutComponent,
    children: [
      { path: '', component: MainCheckoutComponent },
      { path: 'tickets', component: TicketsComponent },
      //    { path: '**', component: HomeComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CheckoutRoutingModule {}
