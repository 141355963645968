<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{view === 1 ? 'Generar cupón' : 'Cupón'}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <div class="container" *ngIf="view === 1">
            <div class="form-group">
                <input type="text" class="form-control text-center fs-4" [(ngModel)]="coupon" [disabled]="true">
            </div>
            <div class="form-group mt-2">
                <select class="form-control" type="text" [(ngModel)]="percentSelected">
                    <option value="0" *ngIf="percentSelected == 0">Descuento</option>
                    <option *ngFor="let percent of percents" [value]="percent">{{percent}}%</option>
                </select>
            </div>
            <div class="form-group mt-2">
                <select class="form-control" type="text" [(ngModel)]="promotionSelected">
                    <option value="" *ngIf="promotionSelected == ''">Promoción</option>
                    <option *ngFor="let promotion of promotions" [value]="promotion.uid">{{promotion.name}}</option>
                </select>
            </div>
            <div class="form-group mt-2">
                <input type="text" class="form-control fs-6" [(ngModel)]="clientPhoneNumber" placeholder="Móvil">
            </div>
            <div class="d-flex justify-content-center mt-2">
                <ng-container *ngIf="!editCoupon">
                    <button class="btn btn-primary"
                        [disabled]="percentSelected === 0 && promotionSelected == '' && (clientPhoneNumber && clientPhoneNumber.length === 10)"
                        (click)="checkIfCouponExists()">
                        Generar cupón
                    </button>
                </ng-container>
                <ng-container *ngIf="editCoupon">
                    <button class="btn btn-primary"
                        [disabled]="percentSelected === 0 && promotionSelected == '' && (clientPhoneNumber && clientPhoneNumber.length === 10)"
                        (click)="editCouponData(couponSelected)">
                        Editar cupón
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="container pt-3 pb-3" *ngIf="view === 2 && couponSelected" id="couponElement">
            <div class="couponContainer">
                <div class="coupon-card">
                    <img src="../../../assets/halloween/arana.png" class="arana" alt="arana">
                    <img src="../../../assets/halloween/ghost.webp" class="ghost" alt="arana">
                    <img src="../../../assets/original.png" class="logo" alt="logo">
                    <h2 class="text-white mainText spooky visibleMd">
                        Tienes un <span class="fs-3 fw-bold">{{couponSelected.percentValue}}%</span> de
                        descuento para tu próxima compra
                    </h2>
                    <h6 class="text-white mainText spooky visibleSm">
                        Tienes un <span class="fs-3">{{couponSelected.percentValue}}%</span> de descuento para
                        tu próxima compra
                    </h6>
                    <di class="coupon-row">
                        <span id="cpnCode" class="fs-3 visibleMd">{{couponSelected.coupon}}</span>
                        <span id="cpnCode" class="fs-4 visibleSm">{{couponSelected.coupon}}</span>
                        <!-- <span id="cpnBtn">Copy Code</span> -->
                    </di>
                    <p class="valid">Válido hasta el día 3 de noviembre</p>
                    <div class="circle1"></div>
                    <div class="circle2"></div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center pt-2 pb-2" *ngIf="view === 2 && couponSelected">
            <button class="btn btn-primary btn-sm" (click)="downloadCoupon()">
                Descargar <i class="fa-solid fa-download"></i>
            </button>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
</ng-template>
<ng-template #promotionsContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Promociónes</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <ul class="nav nav-tabs mb-2">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{active: promoView === 1}" (click)="promoView = 1">Todo</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{active: promoView === 2}" (click)="promoView = 2">Crear</a>
            </li>
        </ul>
        <div class="container">
            <ng-container *ngIf="promoView === 1">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Válidez</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of promotions; let i = index">
                            <td class="centered-cell">
                                {{p.name}}
                            </td>
                            <td class="centered-cell">{{ p.expirationDate | date: 'mediumDate'}}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <ng-container *ngIf="promoView === 2">
                <div class="form-group">
                    <input type="text" class="form-control text-center fs-4" placeholder="Título de la promoción..."
                        [(ngModel)]="promotionName">
                </div>
                <div class="form-group mt-2">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="expiredDate"
                            ngbDatepicker #d="ngbDatepicker" autocomplete="off" />
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <i class="fas fa-calendar"></i>
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <button class="btn btn-primary" [disabled]="promotionName.length <= 3 && !expiredDate"
                        (click)="savePromo()">
                        Crear promoción
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
</ng-template>
<app-back-button></app-back-button>
<div class="container pt-2">
    <div class="d-flex justify-content-center">
    </div>
    <div class="row mt-2">
        <div class="col-md-4"></div>
        <div class="col-md-4 d-flex justiy-content-center">
            <button class="btn btn-primary customBtns" (click)="openCouponModal(content)">Generar cupón</button>
        </div>
        <div class="col-md-4 d-flex justiy-content-end">
            <button class="btn btn-info customBtns" (click)="openPromotionModal(promotionsContent)">
                <i class="fas fa-percent"></i> Promociónes
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 mt-3 mb-3">
            <input class="form-control" type="text" [(ngModel)]="searchTerm" (input)="searchItems()"
                placeholder="Buscar por cupón">
        </div>
        <div class="col-md-4 mt-3 mb-3">
            <select [(ngModel)]="couponSelect" class="form-control" (change)="changeData()">
                <option value="">Seleccionar</option>
                <option value="1">Generados</option>
                <option value="2">Entregados</option>
                <option value="3">Canjeados</option>
                <option value="4">Cancelados</option>
                <!-- <option value="5">Todos</option> -->
            </select>
        </div>
    </div>
    <div class="table-responsive mt-2">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" class="visibleMd">#</th>
                    <th scope="col">Cupón</th>
                    <th scope="col" colspan="2">Estado</th>
                    <th scope="col" class="visibleMd">Creado</th>
                    <th scope="col">Actualizado</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of coupons; let i = index">
                    <th scope="row" class="centered-cell visibleMd">
                        {{coupons.length - i}}
                    </th>
                    <td class="centered-cell">
                        {{c.coupon}} <span class="badge bg-warning text-dark">-{{c.percentValue}}%</span>
                    </td>
                    <td colspan="2" class="centered-cell">
                        <!-- <button class="btn btn-success btn-sm mb-1 btnToggle" *ngIf="c.status === 'active'"
                            (click)="updateCoupon(c, 'coupon')" [disabled]="c.changed === 'yes'">
                            <i class="fa-solid fa-toggle-on"></i>
                        </button>
                        <button class="btn btn-secondary btn-sm mb-1 btnToggle" *ngIf="c.status === 'inactive'"
                            (click)="updateCoupon(c, 'coupon')" [disabled]="c.changed === 'yes'">
                            <i class="fa-solid fa-toggle-off"></i>
                        </button>
                        <button class="btn btn-sm mb-1 ms-1"
                            [ngClass]="{'btn-success':c.changed === 'yes', 'btn-danger':!c.changed || c.changed === 'no'}"
                            (click)="updateCoupon(c, 'changed')" *ngIf="c.status === 'inactive'">
                            <i class="fa-solid fa-arrow-right-arrow-left" *ngIf="c.changed && c.changed === 'yes'"></i>
                            <i class="fa-solid fa-arrow-right-long" *ngIf="!c.changed || c.changed === 'no'"></i>
                        </button> -->
                        <ng-container *ngIf="c.cancelled !== true">
                            <span class="badge bg-info status" *ngIf="c.status === 'active'"
                                (click)="updateCoupon(c, 'coupon', 'no')">Generado</span>
                            <span class="badge bg-secondary status"
                                *ngIf="c.status === 'inactive' && ((c.changed && c.changed === 'no'))"
                                (click)="updateCoupon(c, 'changed')">Entregado</span>
                            <span class="badge bg-success"
                                *ngIf="c.status === 'inactive' && ((c.changed && c.changed === 'yes'))">Canjeado</span>
                        </ng-container>

                        <span class="badge bg-danger" *ngIf="c.cancelled === true"
                            (click)="updateCoupon(c, 'coupon', 'no')">Cancelado</span>
                    </td>
                    <td class="centered-cell visibleMd">
                        {{c.created | date: 'mediumDate'}}
                    </td>
                    <td *ngIf="c.updated" class="centered-cell">
                        {{c.updated | date: 'mediumDate'}}
                    </td>
                    <td *ngIf="!c.updated" class="centered-cell">
                        -
                    </td>
                    <td class="centered-cell">
                        <button class="btn btn-primary btn-sm me-1"
                            *ngIf="c.status === 'inactive' && ((c.changed && c.changed === 'no')) && !c.cancelled || ((c.status === 'active' && !c.cancelled))"
                            (click)="openCouponModal(content, c)">
                            <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-dark btn-sm" (click)="openViewCouponModal(content, c)">
                            <i class="fa-solid fa-ticket"></i>
                        </button>
                        <button class="btn btn-danger btn-sm ms-1"
                            (click)="updateCouponStandard({cancelled:true, uid:c.uid})"
                            *ngIf="(c.status === 'active' && (!c.cancelled || c.cancelled !== true)) || ((c.status === 'inactive' && ((c.changed && c.changed === 'no')) && hasOneMonthPassed(c.created) && (!c.cancelled || c.cancelled === false)))">
                            <i class="fas fa-times-square"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>