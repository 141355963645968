import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  currentDate: Date = new Date();

  // NEW CODE
  mode: 'day' | 'week' | 'month' = 'day';

  constructor() {}

  ngOnInit() {}

  changeDate(dayChange: number): void {
    this.currentDate.setDate(this.currentDate.getDate() + dayChange);
  }

  changeDateParam(newDate: Date): void {
    this.currentDate = newDate;
  }

  selectMode(mode: 'day' | 'month' | 'week'): void {
    this.mode = mode;
  }
}
