import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './public.component';
import { HomeComponent } from './home/home.component';
import { NavBarComponent } from './shared/nav-bar/nav-bar.component';
import { CategoriesSliderComponent } from './categories-slider/categories-slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PopularProductsComponent } from './popular-products/popular-products.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CategorieComponent } from './categorie/categorie.component';
import { AdminModule } from '../admin/admin.module';
import { ProductCardComponent } from './shared/product-card/product-card.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { WhatsappButtonComponent } from './shared/whatsapp-button/whatsapp-button.component';

@NgModule({
  declarations: [
    PublicComponent,
    HomeComponent,
    NavBarComponent,
    CategoriesSliderComponent,
    PopularProductsComponent,
    FooterComponent,
    CategorieComponent,
    ProductCardComponent,
    ProductViewComponent,
    WhatsappButtonComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SlickCarouselModule,
    AdminModule,
    NgbModule,
    FormsModule,
  ],
})
export class PublicModule {}
