import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Product } from '../interfaces/product.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private productsCollection: AngularFirestoreCollection<Product>;
  private colorsCollection: AngularFirestoreCollection<Product>;

  constructor(private _afs: AngularFirestore) {
    this.productsCollection = _afs.collection<Product>('products');
    this.colorsCollection = _afs.collection<Product>('colors');
  }

  async addProduct(product: any): Promise<void> {
    const id = this._afs.createId();

    const productsToSave: Product = {
      uid: id,
      ...product,
    };
    this.productsCollection.doc(id).set(productsToSave);
  }

  getProducts(): Observable<Product[]> {
    return this.productsCollection.valueChanges();
  }

  getColors(): Observable<Product[]> {
    return this.colorsCollection.valueChanges();
  }

  getProductsByCategorieUid(categorieUid: string): Observable<Product[]> {
    return this._afs
      .collection<Product>('products', (ref) =>
        ref.where('categorieUid', '==', categorieUid).where('show', '==', true)
      )
      .valueChanges();
  }

  getProductsByCategorieUidforAdmin(
    categorieUid: string
  ): Observable<Product[]> {
    return this._afs
      .collection<Product>('products', (ref) =>
        ref.where('categorieUid', '==', categorieUid)
      )
      .valueChanges();
  }

  getProductsAvailables(): Observable<Product[]> {
    return this._afs
      .collection<Product>('products', (ref) => ref.where('show', '==', true))
      .valueChanges();
  }

  editProduct(product: Product) {
    return this.productsCollection.doc(product.uid).update(product);
  }

  deleteProduct(uid: string) {
    return this.productsCollection.doc(uid).delete();
  }

  getProductByUid(uid: string) {
    return this._afs
      .collection<Product>('products', (ref) => ref.where('uid', '==', uid))
      .valueChanges();
  }

  getProductByCategorieUid(categorieUid: string) {
    return this._afs
      .collection<Product>('products', (ref) =>
        ref.where('categorieUid', '==', categorieUid)
      )
      .valueChanges();
  }

  mapProductsPhotoUrlData(products: Product[]): Product[] {
    return products.filter((p) => (p && p.photoUrl !== '') || p.photoUrl);
  }

  getProductsFakeData(): Product[] {
    const productsData: Product[] = [];

    for (let i = 1; i <= 10; i++) {
      const element = {
        uid: '000' + i,
        categorieUid: '000' + i,
        brandUid: '000' + i,
        name: '000' + i,
        description: '000' + i,
        price: 100,
        previousPrice: 0,
        isOndiscount: false,
        show: true,
        photoUrl: '',
        code: '',
        created: 0,
        updated: 0,
        nameCopy: '000' + i,
        numberOfSales: 0,
        categorieName: '000' + i,
      };
      productsData.push(element);
    }

    return productsData;
  }

  getProductName(productUid: string, products: Product[]): string {
    const product = products.find((p) => p.uid === productUid);
    return product && product.name ? product.name : '-';
  }

  getProduct(productUid: string, products: Product[]): Product {
    const product = products.find((p) => p.uid === productUid);
    return product;
  }

  async getProductByUidPromise(uid: string): Promise<Product> {
    try {
      const collectionRef = 'products';
      const querySnapshot: any = await this._afs
        .collection(collectionRef, (ref) =>
          ref
            .where('uid', '>=', uid)

            .limit(1)
        )
        .get()
        .toPromise();
      if (!querySnapshot.empty) {
        const documentData = querySnapshot.docs[0].data();
        return documentData;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
}
