import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByLetter',
})
export class SortByLetterPipe implements PipeTransform {
  transform(value: any[]): any[] {
    value.sort((a, b) => {
      let textA = a.name.toUpperCase();
      let textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return value;
  }
}
