import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AuthService } from '../providers/auth.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  hasAccess: boolean;
  loading: boolean;
  elem: any;
  fullScreen: boolean;
  isOnline: boolean = navigator.onLine;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public _authService: AuthService
  ) {
    this.elem = document.documentElement;
  }

  @HostListener('window:online', ['$event'])
  onOnline(event: Event) {
    this.isOnline = true;
  }

  @HostListener('window:offline', ['$event'])
  onOffline(event: Event) {
    this.isOnline = false;
  }

  ngOnInit() {
    this.loading = true;
    this._authService.auth.user.subscribe((user) => {
      if (user) {
        this._authService.getAdmins().subscribe((data: any[]) => {
          const cashier = data.find((a) => a.email === user.email);
          if (cashier && cashier.isCashier) {
            this.hasAccess = true;
          } else {
            this.notHasAccess();
          }
          this.loading = false;
        });
      } else {
        this.notHasAccess();
        this.loading = false;
      }
    });
  }

  async login() {
    const customerResponse = await this._authService.googleLogin();
    this._authService
      .getcustomer(customerResponse.user.uid)
      .subscribe((user) => {
        if (!user.email) {
          this._authService.addcustomer(
            customerResponse.user,
            customerResponse.user.uid
          );
        }
      });
  }

  logout() {
    this._authService.logout();
  }

  notHasAccess(): void {
    this.hasAccess = false;
    this._authService.logout();
  }

  openFullscreen() {
    this.fullScreen = true;
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    this.fullScreen = false;
    if (document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
}
