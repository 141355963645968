<div class="row row-cols-2 row-cols-md-6 justify-content-center" *ngIf="!loading">
    <div class="col p-1">
        <div class="w-100 bg-light text-dark pt-3 pb-3 border rounded" routerLink="/admin/sales">
            <div class="w-100 pt-3 pb-3">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <section class="d-flex justify-content-center">
                            <img src="../../../assets/admin/salesLogo.png" alt="sales" class="img-fluid">
                        </section>
                    </div>
                </div>
                <h5 class="w-100 text-center text-dark mt-2">Ventas</h5>
            </div>
        </div>
    </div>
    <div class="col p-1" *ngIf="itCanAccess('movements') === true">
        <div class="w-100 bg-light text-dark pt-3 pb-3 border rounded" routerLink="/admin/movements">
            <div class="w-100 pt-3 pb-3">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <section class="d-flex justify-content-center">
                            <img src="../../../assets/admin/movementsLogo.png" alt="movements" class="img-fluid">
                        </section>
                    </div>
                </div>
                <h5 class="w-100 text-center text-dark mt-2">Movimientos</h5>
            </div>
        </div>
    </div>
    <div class="col p-1">
        <div class="w-100 bg-light text-dark pt-3 pb-3 border rounded" routerLink="/admin/products">
            <div class="w-100 pt-3 pb-3">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <section class="d-flex justify-content-center">
                            <img src="../../../assets/admin/productsLogo.png" alt="products" class="img-fluid">
                        </section>
                    </div>
                </div>
                <h5 class="w-100 text-center text-dark mt-2">Productos</h5>
            </div>
        </div>
    </div>
    <div class="col p-1">
        <div class="w-100 bg-light text-dark pt-3 pb-3 border rounded" routerLink="/admin/categories">
            <div class="w-100 pt-3 pb-3">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <section class="d-flex justify-content-center">
                            <img src="../../../assets/admin/categoriesLogo.png" alt="categories" class="img-fluid">
                        </section>
                    </div>
                </div>
                <h5 class="w-100 text-center text-dark mt-2">Categorias</h5>
            </div>
        </div>
    </div>
    <div class="col p-1">
        <div class="w-100 bg-light text-dark pt-3 pb-3 border rounded" routerLink="/admin/brands">
            <div class="w-100 pt-3 pb-3">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <section class="d-flex justify-content-center">
                            <img src="../../../assets/admin/brandsLogo.png" alt="brands" class="img-fluid">
                        </section>
                    </div>
                </div>
                <h5 class="w-100 text-center text-dark mt-2">Marcas</h5>
            </div>
        </div>
    </div>
    <div class="col p-1">
        <div class="w-100 bg-light text-dark pt-3 pb-3 border rounded" routerLink="/admin/coupons">
            <div class="w-100 pt-3 pb-3">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <section class="d-flex justify-content-center">
                            <img src="../../../assets/admin/couponsLogo.png" alt="coupons" class="img-fluid">
                        </section>
                    </div>
                </div>
                <h5 class="w-100 text-center text-dark mt-2">Cupones</h5>
            </div>
        </div>
    </div>
</div>