import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../../../providers/categories.service';
import { Categorie } from '../../../interfaces/categorie.interface';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  categories: Categorie[] = [];

  constructor(private _categoriesService: CategoriesService) {}

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this._categoriesService.getCategories().subscribe((cats) => {
      this.categories = cats.map((c) => {
        if (!c.photoURL || c.photoURL === '') {
          return {
            ...c,
            photoURL: '../../../assets/images/no-image-small.jpg',
          };
        }
        return c;
      });
      this.categories = this.categories.filter((c) => c.show === true);
    });
  }
}
