export class FileUpload {
  uid!: string;
  name!: string;
  url!: string;
  file: File;
  percent?: number;
  productUid: string;
  photoNumber: number;

  constructor(file: File) {
    this.file = file;
    this.percent = 0;
  }
}
