import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { FileUpload } from '../models/fileUpload.model';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor() {}

  toastAlert(type: 'success' | 'error', title: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2500,
    });

    Toast.fire({
      icon: type,
      title: title,
    });
  }

  async movementConfirmation(
    title: string,
    productName: string,
    cantity: number,
    costByUnit: number,
    type: string
  ): Promise<any> {
    const response = await Swal.fire({
      title: `<strong>${title}</strong>`,
      html:
        `<span class='text-center's>${productName}</span><br>` +
        `<span class='text-center'>${cantity} <b>x</b> $${costByUnit}</span>` +
        `<br><span class='text-center'><b>${type}</b></span>`,
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    });
    return response;
  }

  loading() {
    Swal.showLoading();
  }

  async confirmation(text?: string): Promise<any> {
    const response = await Swal.fire({
      title: '¿Estás seguro de realizar está acción?',
      text: text ? text : '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar!',
    });
    return response;
  }

  openImage(image: any) {
    Swal.fire({
      imageUrl: image.url,
      imageWidth: '100%',
      imageAlt: image.name,
      backdrop: 'rgba(0,0,0,0.7)',
      showConfirmButton: false,
      background: 'transparent',
    });
  }

  openImageFromCheckout(imageUrl: any, name: string) {
    Swal.fire({
      imageUrl: imageUrl,
      imageWidth: '100%',
      imageAlt: name,
      backdrop: 'rgba(0,0,0,0.7)',
      showConfirmButton: false,
      background: 'transparent',
    });
  }

  showMessage(title: string, text: string, buttonText: string) {
    Swal.fire({
      title: title,
      html: text,
      confirmButtonText: buttonText,
      confirmButtonColor: '#016bf8',
    });
  }

  showImageMessage(text: string, buttonText: string) {
    Swal.fire({
      html: text,
      confirmButtonText: buttonText,
      confirmButtonColor: '#016bf8',
      imageUrl:
        'https://scontent.ftsl1-1.fna.fbcdn.net/v/t39.30808-6/383170949_796622055803233_3995484577762546043_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH6uwr3MHon9Gn9BiXsXj4Zx2OqTUIRNqzHY6pNQhE2rEWyptEz-pyffHNphm8Dcbl8jL9Wad0rjnhjJVMcMYSo&_nc_ohc=idtimnwNLEoAX_O5CKb&_nc_ht=scontent.ftsl1-1.fna&oh=00_AfCQcWcE5bAK0yfj1NHoHl9a4aa5Yo9KtjtyCbSab8xboA&oe=654B33BD',
      imageHeight: 250,
    });
  }

  showMainMessage(title: string, text: string, buttonText: string) {
    Swal.fire({
      title: title,
      html: text,
      confirmButtonText: buttonText,
      confirmButtonColor: '#016bf8',
      allowOutsideClick: false,
    });
  }

  close() {
    Swal.close();
  }
}
