<header>
    <div class="bg-light py-1">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-12 text-center text-md-start">
                    <span>
                        En compras mayores a $100 pesos envíamos a domicilio por $25 pesos...
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar navbar-light py-lg-4 pt-3 px-0 pb-0">
        <div class="container">
            <div class="row w-100 align-items-center g-lg-2 g-0">
                <div class="col-xxl-2 col-lg-3">
                    <a class="navbar-brand d-none d-lg-block" routerLink="/">
                        <img src="../../assets/mavostoredarkblue.png" alt="mr mavo store" width="220px">
                        <!-- Mavo Store -->
                    </a>
                    <div class="d-flex justify-content-between w-100 d-lg-none">
                        <a class="navbar-brand" routerLink="/">
                            <img src="../../assets/mavostoredarkblue.png" alt="mr mavo store" width="180px">
                            <!-- Mavo Store -->
                        </a>

                        <div class="d-flex align-items-center lh-1">

                            <div class="list-inline me-2">
                                <div class="list-inline-item">

                                    <!-- <a href="#!" class="text-muted" data-bs-toggle="modal" data-bs-target="#userModal">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </a> -->
                                </div>
                                <div class="list-inline-item">

                                    <!-- <a class="text-muted position-relative " data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight" href="#offcanvasExample" role="button"
                                        aria-controls="offcanvasRight">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-shopping-bag">
                                            <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                            <line x1="3" y1="6" x2="21" y2="6"></line>
                                            <path d="M16 10a4 4 0 0 1-8 0"></path>
                                        </svg>
                                        <span
                                            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                            1
                                            <span class="visually-hidden">unread messages</span>
                                        </span>
                                    </a> -->
                                </div>

                            </div>
                            <!-- Button -->
                            <button class="navbar-toggler collapsed btnHamburger" type="button"
                                data-bs-toggle="offcanvas" data-bs-target="#navbar-default"
                                aria-controls="navbar-default" aria-expanded="false">
                                <span class="icon-bar top-bar mt-0 hamburger"></span>
                                <span class="icon-bar middle-bar hamburger"></span>
                                <span class="icon-bar bottom-bar hamburger"></span>
                            </button>

                        </div>
                    </div>

                </div>
                <div class="col-xxl-6 col-lg-5 d-none d-lg-block">

                </div>
                <div class="col-md-2 col-xxl-3 d-none d-lg-block">

                </div>
                <div class="col-md-2 col-xxl-1 text-end d-none d-lg-block">

                    <div class="list-inline">
                        <div class="list-inline-item">

                            <!-- <a href="./pages/shop-wishlist.html" class="text-muted position-relative">

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-heart">
                                    <path
                                        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                                    </path>
                                </svg>
                                <span
                                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    5
                                    <span class="visually-hidden">unread messages</span>
                                </span>
                            </a> -->
                        </div>
                        <div class="list-inline-item">

                            <!-- <a href="#!" class="text-muted" data-bs-toggle="modal" data-bs-target="#userModal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                            </a> -->
                        </div>
                        <div class="list-inline-item">

                            <!-- <a class="text-muted position-relative " data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight" href="#offcanvasExample" role="button"
                                aria-controls="offcanvasRight">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-shopping-bag">
                                    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                    <line x1="3" y1="6" x2="21" y2="6"></line>
                                    <path d="M16 10a4 4 0 0 1-8 0"></path>
                                </svg>
                                <span
                                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    1
                                    <span class="visually-hidden">unread messages</span>
                                </span>
                            </a> -->

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="border-bottom pb-lg-4 pb-3">
    <nav class="navbar navbar-expand-lg navbar-light navbar-default pt-0 pb-0">
        <div class="container px-0 px-md-3">

            <div class="dropdown me-3 d-none d-lg-block">
                <button class="btn btn-primary px-6 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-grid">
                            <rect x="3" y="3" width="7" height="7"></rect>
                            <rect x="14" y="3" width="7" height="7"></rect>
                            <rect x="14" y="14" width="7" height="7"></rect>
                            <rect x="3" y="14" width="7" height="7"></rect>
                        </svg></span> Categorías
                </button>
                <ul class="dropdown-menu">
                    <li *ngFor="let categorie of categories | sortByLetter">
                        <a class="dropdown-item" [routerLink]="['categorie', categorie.uid,]">{{categorie.name}}</a>
                    </li>
                </ul>
            </div>



            <div class="offcanvas offcanvas-start p-4 p-lg-0" id="navbar-default">

                <div class="d-flex justify-content-between align-items-center mb-2 d-block d-lg-none">
                    <a class="aLogo" routerLink="/"> <img src="../../assets/mavostoredarkblue.png" alt="mr mavo store"
                            width="180px">
                        <!-- Mavo Store -->
                    </a>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <div class="d-block d-lg-none mb-2 pt-2">
                    <a class="btn btn-primary w-100 d-flex justify-content-center align-items-center"
                        data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false">
                        <span class="me-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid">
                                <rect x="3" y="3" width="7" height="7"></rect>
                                <rect x="14" y="3" width="7" height="7"></rect>
                                <rect x="14" y="14" width="7" height="7"></rect>
                                <rect x="3" y="14" width="7" height="7"></rect>
                            </svg></span> Categorías
                    </a>
                    <div class="collapse mt-2" id="collapseExample">
                        <div class="card card-body">
                            <ul class="mb-0 list-unstyled">
                                <li *ngFor="let categorie of categories | sortByLetter">
                                    <a class="dropdown-item" data-bs-dismiss="offcanvas"
                                        [routerLink]="['categorie', categorie.uid,]">{{categorie.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="d-none d-lg-block">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" role="button" routerLink="/">
                                Inicio
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="d-block d-lg-none">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link" role="button" routerLink="/">
                                Inicio
                            </a>
                        </li>

                    </ul>
                </div>


            </div>
        </div>

    </nav>
</div>