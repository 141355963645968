import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Movement } from '../../interfaces/movement';
import { MovementsService } from '../../providers/movements.service';
import { AlertsService } from '../../providers/alerts.service';
import { ProductsService } from '../../providers/products.service';
import { Product } from '../../interfaces/product.interface';
import { Categorie } from '../../interfaces/categorie.interface';
import { Brand } from '../../interfaces/brand.interface';
import { CategoriesService } from '../../providers/categories.service';
import { BrandsService } from '../../providers/brands.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith, take } from 'rxjs/operators';
import { Filter } from 'src/app/interfaces/filter.interface';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MovementsTotals } from 'src/app/interfaces/movements-totals.interface';
import { MatDialog } from '@angular/material/dialog';
import { MostSelledProduct } from 'src/app/interfaces/mostSelledProduct.interface';
import { MostSelledByCategory } from '../../interfaces/mostSelledProduct.interface';
import { MainCategory } from 'src/app/interfaces/main-categorie.interface';

@Component({
  selector: 'app-movements',
  templateUrl: './movements.component.html',
  styleUrls: ['./movements.component.scss'],
})
export class MovementsComponent implements OnInit {
  movementsData: Movement[] = [];
  movementsArrayDataForTotals: Movement[] = [];
  movementSelected: Movement;
  categories: Categorie[] = [];
  brands: Brand[] = [];
  products: Product[] = [];
  productsCopy: Product[] = [];
  pageSize: number = 50;
  pageSizeItems: number[] = [10, 25, 50, 100];
  startAfter: any = null;
  endBefore: any = null;
  mobile: boolean;
  currentPage: number = 1;
  firstItemsPerPage: { page: number; itemCreated: number }[] = [];
  loading: boolean = false;
  showOperations: boolean;
  selectedCategory: string = '';
  productUid: string = '';
  filters: Filter[] = [];
  productsString: string[] = [];
  filterByProductName: boolean;
  productsFinderLoader: boolean;
  startDate: any;
  endDate: any;
  startDateParam: number;
  endDateParam: number;
  movementsTotals: MovementsTotals;
  total: number = 0;
  totalSales: number = 0;
  loadingOperations: boolean;
  loadingTable: boolean;
  edit: boolean;
  productSelectedUid: string;
  currentIndex: number;
  mostSelledProducts: MostSelledProduct[] = [];
  mostSelledByCategory: MostSelledByCategory[] = [];
  mostSelledByMainCategory: MostSelledByCategory[] = [];
  tabActive: number = 1;
  mainCategories: MainCategory[] = [];
  filterDesc: { [key: string]: boolean } = {};
  activeColumn: string;
  productsWithInvestment: Product[] = [];

  constructor(
    public _categoriesService: CategoriesService,
    public _productsService: ProductsService,
    public _brandsService: BrandsService,
    private _movementsService: MovementsService,
    private _alertsService: AlertsService,
    private modalService: NgbModal,
    public _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < 800) {
      this.mobile = true;
    }
    this.getCollections();
  }

  getCollections(): void {
    this._categoriesService
      .getCategories()
      .pipe(take(1))
      .subscribe((cats) => {
        this.categories = cats;
        this._brandsService
          .getBrands()
          .pipe(take(1))
          .subscribe((brands) => {
            this.brands = brands;
            this._productsService
              .getProducts()
              .pipe(take(1))
              .subscribe((products) => {
                this.productsString = products.map(
                  (p) =>
                    `${this._categoriesService.getCategorieName(
                      p.categorieUid,
                      this.categories
                    )} ${this._brandsService.getBrandName(
                      p.brandUid,
                      this.brands
                    )} ${p.name}`
                );
                this.productsCopy = products.map((p) => {
                  if (p) {
                    p.nameCopy = p.name;
                    p.name = `${this._categoriesService.getCategorieName(
                      p.categorieUid,
                      this.categories
                    )} ${this._brandsService.getBrandName(
                      p.brandUid,
                      this.brands
                    )} ${p.name}`;
                    return p;
                  }
                });
                this.products = products.map((p) => {
                  const product = {
                    ...p,
                    name: `<span class="badge bg-light text-dark"> ${this._categoriesService.getCategorieName(
                      p.categorieUid,
                      this.categories
                    )}</span>
                <span class="badge bg-secondary text-white">${this._brandsService.getBrandName(
                  p.brandUid,
                  this.brands
                )}</span> ${p.nameCopy}`,
                  };
                  return product;
                });
                this.loadMovementsCollection();
              });
          });
      });
  }

  loadMovementsCollection(notLoading?: boolean): void {
    if (!notLoading) {
      this.loading = true;
    }
    this._movementsService
      .getMovementsWithPagination(this.pageSize, this.filters, null, null)
      .subscribe((data: any) => {
        this.movementsData = data;
        this.tabActive = 1;
        this.showOperations = false;
        if (data && data.length && data.length > 0) {
          this.fillFirstItemsPerPage(data);
        } else {
          if (!notLoading) {
            this.loading = false;
          }
        }
      });
    this.getMainCategories();
  }

  async getMainCategories(): Promise<void> {
    this.mainCategories = await this._categoriesService.getMainCategories();
  }

  getMainCategoryName(mainCategoryUid: string): string {
    return this.mainCategories.find((mc) => mc.uid === mainCategoryUid).name;
  }

  nextPage(): void {
    this.loading = true;
    this.currentPage = this.currentPage + 1;
    this._movementsService
      .getMovementsWithPagination(
        this.pageSize,
        this.filters,
        null,
        this.movementsData[this.movementsData.length - 1].created
      )
      .subscribe((data: any) => {
        this.movementsData = data;
        this.tabActive = 1;
        if (data && data.length && data.length > 0) {
          this.fillFirstItemsPerPage(data);
        } else {
          this.loading = false;
        }
      });
  }

  prevPage(): void {
    this.loading = true;

    const startAt = this.firstItemsPerPage.find(
      (p) => p.page === this.currentPage - 1
    ).itemCreated;
    this.currentPage = this.currentPage - 1;
    this._movementsService
      .getMovementsWithPagination(this.pageSize, this.filters, startAt, null)
      .subscribe((data: any) => {
        this.movementsData = data;
        this.tabActive = 1;
        this.loading = false;
      });
  }

  fillFirstItemsPerPage(data: Movement[], notLoading?: boolean): void {
    const exists = this.firstItemsPerPage.find(
      (p) => p.page === this.currentPage
    );
    if (!exists) {
      this.firstItemsPerPage.push({
        page: this.currentPage,
        itemCreated: data[0].created,
      });
    }
    if (!notLoading) {
      this.loading = false;
    }
  }

  changePageSize(): void {
    this.currentPage = 1;
    this.loadMovementsCollection();
  }

  filterBySelectedCategory(selectedCategory: string): void {
    this.filters = this.filters.filter((f) => f.field === 'created');
    this.selectedCategory = selectedCategory;
  }

  getObjectFilter(field: string, operator: string, value: any): Filter {
    return { field: field, operator: operator, value: value };
  }

  filterMovementsByProduct(data: string): void {
    this.productUid = this.productsCopy.find((p) => p.name == data).uid;
    this.resetHeadersForFilterData();
    this.filters.push(
      this.getObjectFilter('productUid', '==', this.productUid)
    );
    this.loadMovementsCollection();
  }

  filterMovementsByProductWithSelect(productUid: string): void {
    this.resetHeadersForFilterData();
    this.productUid = productUid;
    this.filters.push(
      this.getObjectFilter('productUid', '==', this.productUid)
    );
    this.loadMovementsCollection();
  }

  resetHeadersForFilterData(everything?: boolean): void {
    if (everything) {
      this.filters = [];
    }
    if (!everything) {
      this.filters = this.filters.filter((f) => f.field === 'created');
    }
    this.currentPage = 1;
    this.pageSize = 50;
  }

  resetFilters(): void {
    this.selectedCategory = '';
    this.productUid = '';
    this.startDate = undefined;
    this.endDate = undefined;
    this.resetHeadersForFilterData(true);
    if (this.filterByProductName) {
      this.productsFinderLoader = true;
      setTimeout(() => {
        this.productsFinderLoader = false;
      }, 100);
    }
    this.loadMovementsCollection();
  }

  activeFilterByProductName(): void {
    this.filterByProductName = this.filterByProductName === true ? false : true;
  }

  convertToTimestamp(dateObj: any, isFirstHourOfDay: boolean): number {
    const { year, month, day } = dateObj;
    const hours = isFirstHourOfDay ? 0 : 23;
    const minutes = isFirstHourOfDay ? 0 : 59;
    const seconds = isFirstHourOfDay ? 0 : 59;
    const milliseconds = 0;

    const jsMonth = month - 1;
    const timestamp = new Date(
      year,
      jsMonth,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    ).getTime();

    return timestamp;
  }

  filterDataByDate(): void {
    this.currentPage = 1;
    if (this.startDate) {
      this.filters = this.filters.filter(
        (f) => f.operator !== '>=' && f.value !== 'created'
      );
      this.startDateParam = this.convertToTimestamp(this.startDate, true);
      this.filters.push(
        this.getObjectFilter('created', '>=', this.startDateParam)
      );
    }

    if (this.endDate) {
      this.filters = this.filters.filter(
        (f) => f.operator !== '<=' && f.value !== 'created'
      );
      this.endDateParam = this.convertToTimestamp(this.endDate, false);
      this.filters.push(
        this.getObjectFilter('created', '<=', this.endDateParam)
      );
    }
    this.loadMovementsCollection();
  }

  async showTotalsMovements(): Promise<void> {
    try {
      this.tabActive = 1;
      if (this.showOperations === true || this.productUid !== '') {
        this.showTotalsMovementsCompleteFunction();
      } else {
        const dateParam = this.filters.find((f) => f.field === 'created');
        if (!dateParam) {
          const alertResponse = await this._alertsService.confirmation(
            'Como no hay filtros seleccionados será una consulta pesada'
          );
          if (alertResponse.value) {
            this.showTotalsMovementsCompleteFunction();
          }
        }
        if (dateParam) {
          this.showTotalsMovementsCompleteFunction();
        }
      }
    } catch (error) {
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  showTotalsMovementsCompleteFunction(): void {
    this.showOperations = this.showOperations =
      this.showOperations === true ? false : true;
    if (this.showOperations) {
      this.loadingOperations = true;
      this._movementsService
        .getMovementsNoPagination(this.filters, null, null)
        .pipe(take(1))
        .subscribe((movs) => {
          this.movementsArrayDataForTotals = movs.map((m, i) => {
            return this._movementsService.mapMovements(m, i, this.products);
          });
          this.movementsTotals = this._movementsService.getCurrentInvestesment(
            this.products,
            this.movementsArrayDataForTotals
          );

          this.productsWithInvestment =
            this._movementsService.getProductsArrayWithInvestment(
              this.products,
              this.movementsArrayDataForTotals
            );

          this.mostSelledProducts = this.calculateMostSelledProducts(
            this.movementsArrayDataForTotals.filter((m) => m.type == 3)
          );

          const productsNotSelled: Product[] = this.products.filter((p) => {
            const findIfExistInMostSelledProducts: MostSelledProduct =
              this.mostSelledProducts.find((msp) => msp.productUid === p.uid);
            if (!findIfExistInMostSelledProducts) {
              return p;
            }
          });

          // console.log(productsNotSelled, 'PRODUCTS NOT SELLED');

          const newArrayWithProductsSelledAndNotSelled: MostSelledProduct[] =
            this.mostSelledProducts.concat(
              this.createMostSelledProductsArray(productsNotSelled)
            );

          this.mostSelledProducts = this.mostSelledProducts.map(
            (m: MostSelledProduct) => {
              const product: Product = this._productsService.getProduct(
                m.productUid,
                this.products
              );
              const category: Categorie = this._categoriesService.getCategory(
                product.categorieUid,
                this.categories
              );
              m.categoryUid = category.uid;
              m.mainCategoryUid = category.mainCategoryUid;
              return m;
            }
          );

          this.mostSelledByCategory = this.calculateTotalSalesByCategory(
            newArrayWithProductsSelledAndNotSelled
          );

          this.mostSelledByMainCategory = this.getMostSelledByMainCategory();

          this.total = this.getTotalInvestesment(
            this.movementsArrayDataForTotals
          );
          this.getTotalSales(this.movementsArrayDataForTotals);
          this.loadingOperations = false;
        });
    }
  }

  getTotalInvestesment(movs: Movement[]): number {
    const num: number[] = [];
    const movementsTypeOne = movs.filter((m) => m.type === 1);
    movementsTypeOne.map((m) => {
      num.push(m.costByUnit * m.productsNumber);
    });

    return num.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);
  }

  getTotalSales(movs: Movement[]) {
    const num: number[] = [];
    const movementsTypeOne = movs.filter((m) => m.type == 3);
    movementsTypeOne.map((m) => num.push(m.costByUnit * m.productsNumber));

    this.totalSales = num.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);
  }

  getMovementsLengthByType(type: number): number {
    return this.movementsArrayDataForTotals.filter((m) => m.type === type)
      .length;
  }

  async editOnePropertyValueOfMovement(
    movement: Movement,
    property: string,
    value: any
  ): Promise<void> {
    try {
      let movementCopy = { ...movement };

      movementCopy[property] = value;

      const alertResponse: any = await this.alertConfirmationWithData(
        movementCopy,
        '¿Estás seguro de cancelar este movimiento?'
      );
      if (alertResponse.value) {
        this._alertsService.loading();
        await this._movementsService.editMovement(movementCopy);
        this.loadMovementsCollection(true);
        this._alertsService.close();
        this._alertsService.toastAlert('success', 'Movimiento cancelado...');
      }
    } catch (error) {
      this._alertsService.close();
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  async alertConfirmationWithData(data: Movement, title: string): Promise<any> {
    const { costByUnit, productsNumber, type } = data;
    const productName = this.products.find(
      (p) => p.uid === data.productUid
    ).name;
    const alertResponse: any = await this._alertsService.movementConfirmation(
      title,
      productName,
      productsNumber,
      costByUnit,
      type === 1
        ? 'Entrada'
        : type === 2
        ? 'Salida'
        : type === 3
        ? 'Venta'
        : 'Cancelación'
    );

    return alertResponse;
  }

  openModal(modalContent: any, size?: string): void {
    this.modalService.open(modalContent, {
      backdrop: 'static',
      size: size ? size : 'sm',
      windowClass: 'custom',
    });
  }

  clean() {
    this.modalService.dismissAll();
    if (this.edit) {
      this.resetDatForEditMovement();
    }
    this.loadMovementsCollection(true);
  }

  async deleteMovement(movement: Movement) {
    try {
      const alertResponse: any = await this.alertConfirmationWithData(
        movement,
        '¿Estás seguro de eliminar?'
      );
      if (alertResponse.value) {
        this.loadingTable = true;
        await this._movementsService.deleteMovement(movement.uid);
        this.loadingTable = false;
        this._alertsService.toastAlert('success', 'Movimiento eliminado...');
        this.loadMovementsCollection(true);
      }
    } catch (error) {
      this.loadingTable = false;
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  openModalEdit(modalContent, movement: Movement) {
    this.movementSelected = movement;
    this.edit = true;
    this.openModal(modalContent);
  }

  resetDatForEditMovement() {
    this.movementSelected = null;
    this.productSelectedUid = '';
    this.edit = false;
    this.loadMovementsCollection(true);
  }

  onMouseEnter(index: number) {
    this.currentIndex = index;
  }

  onMouseLeave() {
    this.currentIndex = null;
  }

  calculateMostSelledProducts(
    movementsArrayDataForTotals: Movement[]
  ): MostSelledProduct[] {
    // Calculate total sales per product
    const mostSelledProducts: MostSelledProduct[] =
      movementsArrayDataForTotals.reduce(
        (acc: MostSelledProduct[], movement: Movement) => {
          const existingProductIndex = acc.findIndex(
            (item) => item.productUid === movement.productUid
          );
          if (existingProductIndex !== -1) {
            acc[existingProductIndex].totalSales += movement.productsNumber;
            acc[existingProductIndex].total +=
              movement.productsNumber * movement.costByUnit;
          } else {
            const productWithInvestment: Product =
              this.productsWithInvestment.find(
                (p) => p && p.uid && p.uid === movement.productUid
              );
            acc.push({
              fullNameClean: this.getProductFullName(
                movement.productUid
              ).toUpperCase(),
              productUid: movement.productUid,
              total: movement.productsNumber * movement.costByUnit,
              totalSales: movement.productsNumber,
              currentInvestment:
                productWithInvestment && productWithInvestment.currentInvestment
                  ? productWithInvestment.currentInvestment
                  : 0,
            });
          }
          return acc;
        },
        []
      );
    return mostSelledProducts.sort((a, b) => b.totalSales - a.totalSales);
  }

  createMostSelledProductsArray(products: Product[]): MostSelledProduct[] {
    return products.map((p) => {
      const productWithInvestment: Product = this.productsWithInvestment.find(
        (p2) => p2 && p2.uid && p2.uid === p.uid
      );
      const category: Categorie = this._categoriesService.getCategory(
        p.categorieUid,
        this.categories
      );

      return {
        categoryUid: category.uid,
        mainCategoryUid: category.mainCategoryUid,
        fullNameClean: this.getProductFullName(p.uid).toUpperCase(),
        productUid: p.uid,
        total: 0,
        totalSales: 0,
        currentInvestment:
          productWithInvestment && productWithInvestment.currentInvestment
            ? productWithInvestment.currentInvestment
            : 0,
      };
    });
  }

  calculateTotalSalesByCategory(
    products: MostSelledProduct[]
  ): MostSelledByCategory[] {
    const totalSalesByCategory: MostSelledByCategory[] = products.reduce(
      (acc: MostSelledByCategory[], product: MostSelledProduct) => {
        const existingCategoryIndex = acc.findIndex(
          (item) => item.categoryUid === product.categoryUid
        );
        if (existingCategoryIndex !== -1) {
          // Si la categoría ya existe en el array, suma las ventas totales
          acc[existingCategoryIndex].total += product.total;
          acc[existingCategoryIndex].utility =
            acc[existingCategoryIndex].total -
            acc[existingCategoryIndex].investment;
        } else {
          // Si la categoría no existe en el array, agrega un nuevo objeto al array
          const category: Categorie = this._categoriesService.getCategory(
            product.categoryUid,
            this.categories
          );
          let investment: number = this.getTotalInvestesment(
            this.movementsArrayDataForTotals.filter(
              (m) => m.categorieUid === product.categoryUid
            )
          );
          acc.push({
            total: product.total,
            investment: investment,
            categoryUid: product.categoryUid,
            mainCategoryUid: product.mainCategoryUid,
            categoryName:
              category && category.name
                ? category.name
                : 'Nombre no disponible',
            utility: product.total - investment,
            currentInvestment: this.getCurrentInvestmentByCategory(
              product.categoryUid
            ),
          });
        }
        return acc;
      },
      []
    );

    return totalSalesByCategory.sort((a, b) => b.total - a.total);
  }

  getCurrentInvestmentByCategory(categoryUid: string): number {
    const products: Product[] = this.productsWithInvestment.filter(
      (p) => p && p.categorieUid == categoryUid
    );

    const totals: number[] = products.map((p) =>
      p && p.currentInvestment ? p.currentInvestment : 0
    );

    return totals.reduce((acc, curr) => acc + curr, 0);
  }

  getMostSelledByMainCategory(): MostSelledByCategory[] {
    const groupedByMainCategory: { [mainCategoryUid: string]: number } = {};

    // Iterar sobre el array original para sumar los totales por mainCategoryUid
    for (const item of this.mostSelledByCategory) {
      if (groupedByMainCategory[item.mainCategoryUid]) {
        groupedByMainCategory[item.mainCategoryUid] += item.total;
      } else {
        groupedByMainCategory[item.mainCategoryUid] = item.total;
      }
    }

    // Convertir el objeto de grupos a un array de objetos
    const result: any[] = Object.keys(groupedByMainCategory).map(
      (mainCategoryUid) => ({
        mainCategoryUid,
        total: groupedByMainCategory[mainCategoryUid],
        investment: this.getInvestmentByCategory(mainCategoryUid),
        utility:
          groupedByMainCategory[mainCategoryUid] -
          this.getInvestmentByCategory(mainCategoryUid),
        categoryName: this.getMainCategoryName(mainCategoryUid),
        currentInvestment:
          this.getCurrentInvestmentByMainCategory(mainCategoryUid),
      })
    );
    return result;
  }

  getInvestmentByCategory(mainCategoryUid: string): number {
    const num: number[] = [];
    const data: MostSelledByCategory[] = this.mostSelledByCategory.filter(
      (msbc) => msbc.mainCategoryUid === mainCategoryUid
    );
    data.map((m) => {
      num.push(m.investment);
    });
    return num.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);
  }

  getCurrentInvestmentByMainCategory(mainCategoryUid: string): number {
    const num: number[] = [];
    const data: MostSelledByCategory[] = this.mostSelledByCategory.filter(
      (msbc) => msbc.mainCategoryUid === mainCategoryUid
    );
    data.map((m) => {
      num.push(m && m.currentInvestment ? m.currentInvestment : 0);
    });
    return num.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);
  }

  sortByField<T>(array: T[], field: string): T[] {
    this.filterDesc[field] = !this.filterDesc[field];
    this.activeColumn = field;
    return array.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (aValue === bValue) {
        return 0;
      }

      return this.filterDesc[field]
        ? aValue > bValue
          ? -1
          : 1
        : aValue < bValue
        ? -1
        : 1;
    });
  }

  isColumnActive(column: string): boolean {
    return this.activeColumn === column;
  }

  getProductFullName(productUid: string): string {
    const product: Product = this.productsCopy.find(
      (p) => p.uid === productUid
    );
    return product && product.name ? product.name : '';
  }

  doubleTheInvestmentWasSold(a: number, b: number): boolean {
    if (a >= 2 * b) {
      return true;
    }
    return false;
  }
}
