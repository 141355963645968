<app-back-button></app-back-button>
<div class="d-flex justify-content-end pt-2 pb-2">
    <button class="btn btn-primary" [ngClass]="{'btn-secondary': showBrandContainer}"
        (click)="activeBrandContainer()">{{showBrandContainer ? 'Ocultar' : 'Nueva marca'}}</button>
</div>
<div class="container" *ngIf="showBrandContainer">
    <app-name-input (sendDataEvent)="brandName = $event" [loading]="loading" [showButton]="false"></app-name-input>
    <div class="d-flex justify-content-center mb-3">
        <button class="btn btn-primary" (click)="saveBrand()" [disabled]="brandName === ''">Guardar</button>
    </div>
</div>
<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col" class="text-center">Nombre</th>
                <th scope="col" class="text-center">Editar</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let brand of brands | sortByLetter; let i = index;">
                <th scope="row">{{i + 1}}</th>
                <td class="text-center">
                    {{brand.name}}
                </td>
                <td class="text-center">
                    <button class="btn btn-primary btn-sm" (click)="editBrand(content, brand)">
                        <i class="fa-solid fa-pen-to-square" style="cursor: pointer;"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Editar marca</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <app-name-input [name]="brandSelected.name" [edit]="true" [loading]="loading" [showButton]="false"
            (sendDataEvent)="brandName = $event">
        </app-name-input>
        <div class="d-flex justify-content-center mb-3">
            <button class="btn btn-warning" (click)="saveBrand(true)" [disabled]="brandName === ''">Actualizar</button>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
</ng-template>