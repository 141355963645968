import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Coupon } from '../interfaces/coupon.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  private couponsCollection: AngularFirestoreCollection<Coupon>;

  constructor(private _afs: AngularFirestore) {
    this.couponsCollection = _afs.collection<Coupon>('coupons');
  }

  async addCoupon(coupon: any): Promise<void> {
    const id = this._afs.createId();

    const couponToSave: Coupon = {
      uid: id,
      ...coupon,
    };
    this.couponsCollection.doc(id).set(couponToSave);
  }

  getLimitedCollection(
    startAfter?: any,
    filters?: any[]
  ): Observable<Coupon[]> {
    const limit = 10;

    let query = this._afs.collection<Coupon>('coupons', (ref: any) => {
      ref = ref.orderBy('created', 'desc');

      if (startAfter) {
        ref = ref.startAfter(startAfter);
      }

      for (const filter of filters) {
        ref = ref.where(filter.field, filter.operator, filter.value);
      }

      // ref = ref.limit(limit);

      return ref;
    });

    return query.valueChanges();
  }

  editCoupon(coupon: any) {
    coupon.updated = new Date().getTime();
    return this.couponsCollection.doc(coupon.uid).update(coupon);
  }

  async getCollectionSize(): Promise<number> {
    const snapshot = await this.couponsCollection.ref.get();
    return snapshot.size;
  }

  getCouponsByField(fieldName: string, value: string): Observable<Coupon[]> {
    return this._afs
      .collection<Coupon>('coupons', (ref) => ref.where(fieldName, '==', value))
      .valueChanges();
  }

  getInfoByPhoneNumber(
    promotionUid: string,
    clientPhoneNumber: number
  ): Observable<Coupon[]> {
    let query = this._afs.collection<Coupon>('coupons', (ref: any) => {
      ref = ref.orderBy('created', 'desc');

      ref = ref.where('promotionUid', '==', promotionUid);
      ref = ref.where('clientPhoneNumber', '==', clientPhoneNumber);

      return ref;
    });

    return query.valueChanges();
  }
}
