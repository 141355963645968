<div class="container">
    <div class="row" *ngIf="filters.length > 0">
        <div class="col-md-2">
            <div class="d-flex justify-content-start pt-1 pb-1">
                <button class="btn btn-sm" [disabled]="stockLoader"
                    [ngClass]="{'btn-success': showStockActive, 'btn-secondary': !showStockActive}"
                    (click)="showStock()">
                    <i class="fas fa-clipboard-list"></i>
                </button>
                <button class="btn btn-sm ms-2" [disabled]="stockLoader || loadingModeLabel" *ngIf="showStockActive"
                    [ngClass]="{'btn-primary': modeLabel, 'btn-secondary': !modeLabel}" (click)="showModeLabel()">
                    <i class="fas fa-tag"></i>
                </button>
            </div>
        </div>
        <div class="col-md-8">
            <div class="row pt-5 pb-5 mt-5 mb-5">
                <div class="col-12 d-flex justify-content-center" *ngIf="stockLoader">
                    <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <ng-container *ngIf="!modeLabel">
                    <div class="col-md-6 mb-1" *ngIf="!stockLoader">
                        <table class="table table-bordered w-100">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center">Mediana</th>
                                </tr>
                                <tr>
                                    <th>Ventas</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>{{ getMedian(mostSelledProductsDataToShow, 'totalSales') }}</b>
                                    </td>
                                    <td>
                                        <b>{{ getMedian(mostSelledProductsDataToShow, 'total') | currency }}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6 mb-1" *ngIf="!stockLoader">
                        <table class="table table-bordered w-100">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center">Promedio</th>
                                </tr>
                                <tr>
                                    <th>Ventas</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>{{ getAverage(mostSelledProductsDataToShow, 'totalSales') }}</b>
                                    </td>
                                    <td>
                                        <b>{{ getAverage(mostSelledProductsDataToShow, 'total') | currency }} </b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                <div class="col-12 mb-1 d-flex justify-content-center" [ngClass]="{'mt-3': stockLoader}"
                    *ngIf="modeLabel">
                    <span class="badge fs-6 me-3 mb-3" *ngFor="let label of labels"
                        [ngStyle]="{'background-color': label.colorHexCode, 'color': label.textColorHexCode}">
                        {{label.name}}: <br><b>{{ getTotalsByLabel(label.uid) | currency }}</b>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="d-flex justify-content-end pt-1 pb-1">
                <button class="btn btn-primary btn-sm" (click)="openModalFilters(filtersContent)"
                    [disabled]="stockLoader">
                    <i class="fas fa-filter"></i>
                </button>
                <button class="btn btn-success btn-sm ms-2" (click)="resetData()"
                    *ngIf="formDataFilterIsActive() && !showStockActive" [disabled]="stockLoader">
                    <i class="fas fa-undo"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="table-responsive mb-3 pb-3">
        <div class="table-wrapper-scroll-y my-custom-scrollbar" [ngClass]="{tableLoading: stockLoader}">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col" *ngIf="modeLabel && showStockActive && !stockLoader"></th>
                        <th scope="col" class="pointer"
                            (click)="sortData(mostSelledProductsDataToShow, 'fullNameClean')"
                            [class.sorted]="filterDesc['fullNameClean']">
                            Nombre <span class="sort-arrow" *ngIf="checkColumnStatus('fullNameClean')">{{
                                (filterDesc['fullNameClean'] ?
                                '↓' : '↑')
                                }}</span>
                        </th>
                        <th *ngIf="showStockActive && !stockLoader" scope="col" class="pointer"
                            (click)="sortData(mostSelledProductsDataToShow, 'stock')"
                            [class.sorted]="filterDesc['stock']">
                            Stock <span class="sort-arrow" *ngIf="checkColumnStatus('stock')">{{
                                filterDesc['stock'] ? '↓' : '↑' }}</span>
                        </th>
                        <th scope="col" class="pointer" (click)="sortData(mostSelledProductsDataToShow, 'totalSales')"
                            [class.sorted]="filterDesc['totalSales']">
                            N° ventas <span class="sort-arrow" *ngIf="checkColumnStatus('totalSales')">{{
                                (filterDesc['totalSales'] ?
                                '↓' : '↑')
                                }}</span>
                        </th>
                        <th scope="col" class="pointer" (click)="sortData(mostSelledProductsDataToShow, 'total')"
                            [class.sorted]="filterDesc['total']">
                            Total <span class="sort-arrow" *ngIf="checkColumnStatus('total')">{{
                                filterDesc['total'] ? '↓' : '↑' }}</span>
                        </th>
                        <th scope="col" class="pointer"
                            *ngIf="filters.length === 0 && !startDate && !endDate && !loadingOperations"
                            (click)="sortData(mostSelledProductsDataToShow, 'currentInvestment')"
                            [class.sorted]="filterDesc['currentInvestment']">
                            Inv. Actual <span class="sort-arrow" *ngIf="checkColumnStatus('currentInvestment')">{{
                                filterDesc['currentInvestment'] ? '↓' : '↑' }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let productSelled of mostSelledProductsDataToShow; let i = index;"
                        [ngClass]="{'urgent': productHasLabelWithColor(productSelled.productUid, 'red'), 'moderate': productHasLabelWithColor(productSelled.productUid, 'yellow'), 'noUrgent': productHasLabelWithColor(productSelled.productUid, 'green') }">
                        <th scope="row">{{i + 1}}</th>
                        <th class="text-center"
                            *ngIf="modeLabel && showStockActive && !stockLoader && !productHasLabel(productSelled.productUid)"
                            (click)="openModalLabels(labelsContent, productSelled)">
                            <i class="fas fa-circle pointer" *ngIf="productSelled.stock === 0"></i>
                            <p *ngIf="productSelled.stock > 0"> - </p>
                        </th>
                        <th class="text-center"
                            *ngIf="modeLabel && showStockActive && !stockLoader && productHasLabel(productSelled.productUid)">
                            <i class="fa-solid fa-circle-xmark pointer"
                                (click)="deleteProductLabel(getProductLabel(productSelled.productUid).uid)"></i>
                        </th>
                        <td [innerHtml]="_productsService.getProductName(productSelled.productUid, products)">
                            {{_productsService.getProductName(productSelled.productUid, products)}}
                        </td>
                        <td *ngIf="showStockActive && !stockLoader">
                            {{productSelled?.stock}}
                        </td>
                        <td>{{productSelled.totalSales}}</td>
                        <td>{{productSelled.total | currency}}</td>
                        <td *ngIf="filters.length === 0 && !startDate && !endDate && !loadingOperations">
                            {{productSelled.currentInvestment | currency}}</td>
                    </tr>
                    <tr *ngIf="filters.length > 0">
                        <td colspan="4" class="text-center fs-5" *ngIf="showStockActive && modeLabel && !stockLoader">
                            <b>{{ getTotalPercent(sumProperty(mostSelledProductsDataToShow, 'total'), totalSales)
                                }}%</b>
                        </td>
                        <td colspan="3" class="text-center fs-5" *ngIf="!modeLabel && showStockActive">
                            <b>{{ getTotalPercent(sumProperty(mostSelledProductsDataToShow, 'total'), totalSales)
                                }}%</b>
                        </td>
                        <td colspan="2" class="text-center fs-5"
                            *ngIf="modeLabel && !showStockActive || (!modeLabel && !showStockActive) ">
                            <b>{{ getTotalPercent(sumProperty(mostSelledProductsDataToShow, 'total'), totalSales)
                                }}%</b>
                        </td>
                        <td>
                            <b>{{sumProperty(mostSelledProductsDataToShow, 'totalSales')}}</b>
                        </td>
                        <td>
                            <b>{{sumProperty(mostSelledProductsDataToShow, 'total') | currency}}</b>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<ng-template #filtersContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Filtrar información</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <div class="container mt-4">
            <form #salesForm="ngForm" (ngSubmit)="onSubmit(salesForm)" class="needs-validation" novalidate>
                <div class="mb-3">
                    <label for="totalSales" class="form-label">N° de ventas</label>
                    <input type="number" id="totalSales" name="totalSales" class="form-control"
                        [(ngModel)]="formData.totalSales" required />
                    <div class="invalid-feedback">El campo Total Sales es obligatorio.</div>
                </div>

                <div class="mb-3">
                    <label for="total" class="form-label">Total</label>
                    <input type="number" id="total" name="total" class="form-control" [(ngModel)]="formData.total"
                        required />
                    <div class="invalid-feedback">El campo Total es obligatorio.</div>
                </div>

                <button type="submit" class="btn btn-primary">Aplicar</button>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
</ng-template>

<ng-template #labelsContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title"
            [innerHtml]="_productsService.getProductName(mostSelledProductSelected.productUid, products)">
            {{_productsService.getProductName(mostSelledProductSelected.productUid, products)}}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form>
                        <div class="form-group">
                            <label for="labelsSelect">Selecciona una etiqueta:</label>
                            <select id="labelsSelect" class="form-control" [(ngModel)]="selectedLabel" name="label">
                                <option *ngFor="let label of labels" [value]="label.uid"
                                    [ngStyle]="{'background-color': label.colorHexCode, 'color': label.textColorHexCode}">
                                    {{ label.name }}
                                </option>
                            </select>
                        </div>
                    </form>
                    <div class="d-flex justify-content-center mt-3">
                        <button class="btn btn-success" (click)="saveProductLabel()">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
</ng-template>