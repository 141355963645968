<app-back-button></app-back-button>
<div class="d-flex justify-content-end pt-2 pb-2">
    <button class="btn btn-primary" [ngClass]="{'btn-secondary': showCategorieContainer}"
        (click)="activeCategorieContainer()">{{showCategorieContainer ? 'Ocultar' : 'Nueva categoría'}}</button>
</div>
<ng-template #galleryContent let-modal>
    <div class="container p-5">
        <div class="row justify-content-center">
            <div class="col-md-4 customHeight" *ngIf="categorieSelected">
                <img [src]="fileSelectedUrl" class="img-fluid w-100 customHeight" alt="categorieImage"
                    *ngIf="fileSelected">
                <img [src]="categorieSelected.photoURL" class="img-fluid w-100 customHeight" alt="categorieImage"
                    *ngIf="!fileSelected">
            </div>
        </div>
        <p *ngIf="currentPercent > 0">
            <ngb-progressbar type="warning" textType="dark" [value]="currentPercent" [showValue]="true">
            </ngb-progressbar>
        </p>
        <div class="d-flex justify-content-center mt-3" *ngIf="categorieSelected.photoURL.includes('assets')">
            <input type="file" id="categorieImage" name="categorieImage" (change)="loadFile($event)">
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-2">
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-success btn-sm" [disabled]="!fileSelected || loadingImage"
                        (click)="uploadFile()">
                        <i class="fa-solid fa-cloud-arrow-up"></i>
                    </button>
                </div>
            </div>
            <div class="col-2" *ngIf="!categorieSelected.photoURL.includes('assets')">
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-danger btn-sm" (click)="deleteCategoriePhoto()">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-5">
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cerrar</button>
        </div>
    </div>
</ng-template>
<div class="container" *ngIf="showCategorieContainer">
    <app-name-input (sendDataEvent)="categorieName = $event" [loading]="loading" [showButton]="false"></app-name-input>
    <app-name-input (sendDataEvent)="singularName = $event" [loading]="loading" [showButton]="false"
        [placeholder]="'Nombre singular'"></app-name-input>
    <div class="d-flex justify-content-center mb-2">
        <span style="cursor: pointer;">
            <i class="fa-solid fa-toggle-on fa-3x text-success" (click)="show = false" *ngIf="show"></i>
            <i class="fa-solid fa-toggle-off fa-3x text-secondary" (click)="show = true" *ngIf="!show"></i>
        </span>
    </div>
    <div class="d-flex justify-content-center mb-3">
        <button class="btn btn-primary" (click)="saveCategorie()"
            [disabled]="categorieName === '' || singularName === ''">Guardar</button>
    </div>
</div>
<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col" class="text-start">Nombre</th>
                <th scope="col" class="text-start">Singular</th>
                <th scope="col" class="text-start">Principal</th>
                <th scope="col" class="text-center">Editar</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cat of categories | sortByLetter; let i = index;">
                <th scope="row">{{i + 1}}</th>
                <td class="text-start">
                    <i class="fa-solid fa-circle ms-2"
                        [ngClass]="{'text-success': cat && cat.show, 'text-secondary':cat && !cat.show}"></i>
                    {{cat.name}}
                </td>
                <td class="text-start">
                    {{cat.singularName}}
                </td>
                <td class="text-start">
                    {{cat && cat.mainCategoryUid ? getMainCategoryName(cat.mainCategoryUid) : ''}}
                </td>
                <td class="text-center">
                    <button class="btn btn-primary btn-sm" (click)="editCategorie(content, cat)">
                        <i class="fa-solid fa-pen-to-square" style="cursor: pointer;"></i>
                    </button>
                    <button class="btn btn-dark btn-sm ms-2" (click)="openCategorieImageModal(galleryContent, cat)">
                        <i class="fa-regular fa-image"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Editar categoría</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <app-name-input [name]="categorieSelected.name" [edit]="true" [loading]="loading" [showButton]="false"
            (sendDataEvent)="categorieName = $event">
        </app-name-input>
        <app-name-input [name]="categorieSelected.singularName" [edit]="true" [loading]="loading" [showButton]="false"
            [placeholder]="'Nombre singular'" (sendDataEvent)="singularName = $event">
        </app-name-input>
        <div class="row justify-content-center">
            <div class="col-4 mb-2">
                <select [(ngModel)]="mainCategoryUid" class="form-control">
                    <option value="">Seleccionar</option>
                    <option *ngFor="let mc of mainCategories | sortByLetter" [value]="mc.uid">
                        {{mc.name}}
                    </option>
                </select>
            </div>
        </div>
        <div class="d-flex justify-content-center mb-2">
            <span style="cursor: pointer;">
                <i class="fa-solid fa-toggle-on fa-3x text-success" (click)="show = false" *ngIf="show"></i>
                <i class="fa-solid fa-toggle-off fa-3x text-secondary" (click)="show = true" *ngIf="!show"></i>
            </span>
        </div>
        <div class="d-flex justify-content-center mb-3">
            <button class="btn btn-warning" (click)="saveCategorie(true)"
                [disabled]="categorieName === '' || singularName === ''">Actualizar</button>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
</ng-template>