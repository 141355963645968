import { Component, Input, OnInit } from '@angular/core';
import { Movement } from 'src/app/interfaces/movement';
import { Ticket } from 'src/app/interfaces/ticket.interface';
import { MovementsService } from 'src/app/providers/movements.service';
import * as moment from 'moment';
import { Product } from 'src/app/interfaces/product.interface';
import { ProductsService } from 'src/app/providers/products.service';
import { CategoriesService } from 'src/app/providers/categories.service';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { Brand } from 'src/app/interfaces/brand.interface';
import { BrandsService } from 'src/app/providers/brands.service';
moment.locale('es-mx');

@Component({
  selector: 'app-ticket-view',
  templateUrl: './ticket-view.component.html',
  styleUrls: ['./ticket-view.component.scss'],
})
export class TicketViewComponent implements OnInit {
  @Input() products: Product[] = [];
  @Input() ticket: Ticket;
  @Input() movements: Movement[] = [];
  @Input() categories: Categorie[] = [];
  @Input() brands: Brand[] = [];
  loading: boolean;

  constructor(
    public _productsService: ProductsService,
    private _movementsService: MovementsService,
    private _categoriesService: CategoriesService,
    private _brandsService: BrandsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.movements = await this._movementsService.getMovementsByTicketUid(
      this.ticket.uid
    );
    this.categories = await this._categoriesService.getCategoriesPromise();
    this.brands = await this._brandsService.getBrandsPromise();

    for (const mov of this.movements) {
      const product: Product =
        await this._productsService.getProductByUidPromise(mov.productUid);
      this.products.push(product);
    }
    this.products = this.products.map((p) => {
      const product = {
        ...p,
        name: `<span class="badge bg-light text-dark"> ${this._categoriesService.getCategorieName(
          p.categorieUid,
          this.categories
        )}</span>
    <span class="badge bg-secondary text-white">${this._brandsService.getBrandName(
      p.brandUid,
      this.brands
    )}</span> ${p.name}`,
      };
      return product;
    });
    this.loading = false;
  }

  getDate(date: number): any {
    return moment(date).calendar();
  }
}
