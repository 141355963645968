import { NgModule } from '@angular/core';
import { ProductFinderComponent } from './product-finder/product-finder.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDatepickerModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TicketViewComponent } from './ticket-view/ticket-view.component';
import { TicketsComponent } from './tickets/tickets.component';
import { SalesChartsComponent } from './sales-charts/sales-charts.component';

@NgModule({
  declarations: [
    ProductFinderComponent,
    TicketViewComponent,
    TicketsComponent,
    SalesChartsComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    ChartsModule,
  ],
  exports: [
    ProductFinderComponent,
    TicketViewComponent,
    TicketsComponent,
    SalesChartsComponent,
  ],
})
export class SharedModule {}
