<div class="container">
    <div class="table-responsive" *ngIf="ticket && ticket.uid">
        <table class="table table-bordered">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">N° global</th>
                    <th scope="col">N° día</th>
                    <th scope="col">Creado</th>
                    <th scope="col">Actualizado</th>
                    <th scope="col">Estado</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>#{{ticket.globalTicketNumber}}</th>
                    <th>#{{ticket.dayTicketNumber}}</th>
                    <td>{{getDate(ticket.created)}}</td>
                    <td *ngIf="ticket.updated">{{getDate(ticket.updated)}}</td>
                    <td *ngIf="!ticket.updated">No actualizado</td>
                    <td>
                        <span class="badge bg-success" *ngIf="ticket.status === 1">Venta</span>
                        <span class="badge bg-info" *ngIf="ticket.status === 2">Cambio</span>
                        <span class="badge bg-danger" *ngIf="ticket.status === 3">Cancelado</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">Subtotal</th>
                    <th scope="col">Total</th>
                    <th scope="col">Descuento</th>
                    <th scope="col">Total en NO productos</th>
                    <th scope="col">Metodó de pago</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ticket.subtotal | currency}}</td>
                    <td>{{ticket.total | currency}}</td>
                    <td>{{ticket.subtotal - ticket.total | currency}}</td>
                    <td>{{ticket.accumulatedSalesOfUninventoryedThings | currency}}</td>
                    <td>
                        <ng-container *ngIf="ticket.paymentMethod === 1">Efectivo</ng-container>
                        <ng-container *ngIf="ticket.paymentMethod === 2">Tarjeta</ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered" *ngIf="ticket.status === 2 && ticket.itsChange">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">N° ticket anterior</th>
                    <th scope="col">Total ticket anterior</th>
                    <th scope="col">Nuevo total</th>
                    <th scope="col">Diferencia</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>#{{ticket.idGlobalOfPrevTicket}}</th>
                    <td>{{ticket.moneyInBoxFromPreviousTicket | currency}}</td>
                    <td>{{ticket.total | currency}}</td>
                    <td>{{ticket.total - ticket.moneyInBoxFromPreviousTicket | currency}}</td>
                </tr>
            </tbody>
        </table>
        <h5 *ngIf="movements.length > 0" class="text-center">Productos</h5>
        <table class="table table-striped table-hover mt-3" *ngIf="!loading">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Total</th>
                    <th scope="col">Fecha</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let movement of movements; let i = index">
                    <th scope="row">{{i + 1 }}</th>
                    <td>
                        <span class="badge bg-success" *ngIf="movement.type == 3">Venta</span>
                        <span class="badge bg-danger" *ngIf="movement.type == 4">Cancelado</span>
                        <span class="badge bg-secondary" *ngIf="movement.type != 3 && movement.type !== 4">Otro</span>
                    </td>
                    <td [innerHtml]="_productsService.getProductName(movement.productUid, products)">
                        {{_productsService.getProductName(movement.productUid, products)}}
                    </td>
                    <td style="cursor: pointer;">
                        {{movement.productsNumber}} <b>x</b> {{movement.costByUnit | currency}}
                    </td>
                    <td>{{getDate(movement.created)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>