import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { CategoriesService } from '../../providers/categories.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categories-slider',
  templateUrl: './categories-slider.component.html',
  styleUrls: ['./categories-slider.component.scss'],
})
export class CategoriesSliderComponent implements OnInit {
  slideConfig = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    arrows: true,
    prevArrow:
      '<button type="button" class="slick-prev slick-prev-icon"><i class="fa-solid fa-chevron-left"></i></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fa-solid fa-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  categories: any | Categorie[] = [1, 2, 3, 4, 5, 6, 7, 8];
  loading: boolean;

  constructor(
    private _categoriesService: CategoriesService,
    private _router: Router
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this._categoriesService
      .getCategoriesAvailables()
      .pipe(take(1))
      .subscribe((cats) => {
        this.categories = cats;
        this.loading = false;
        this.categories = cats.map((c) => {
          if (!c.photoURL || c.photoURL === '') {
            return {
              ...c,
              photoURL: '../../../assets/images/no-image-small.jpg',
            };
          }
          return c;
        });
      });
  }

  goToCategory(categorie: any | Categorie): void {
    if (categorie && categorie.uid) {
      this._router.navigate(['/categorie', categorie.uid]);
    }
  }
}
