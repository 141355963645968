<div style="background-color: white !important;">
    <ng-container *ngIf="isOnline">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" routerLink="/checkout">
                    <img src="../../assets/mavomarcaaguanegro.png" alt="mr mavo store" width="150px">
                </a>
                <div class="container-fluid">
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/checkout" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">Inicio</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/checkout/tickets" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">Tickets</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-dark btn-sm" (click)="logout()"
                        *ngIf="_authService.auth.user | async as user; else showLoginOptions">
                        <i class="fa-solid fa-right-from-bracket"></i>
                    </button>
                    <ng-template #showLoginOptions>
                        <button type="button" class="btn btn-danger btn-sm" (click)="login()">
                            <i class="fa-brands fa-google"></i>
                        </button>
                    </ng-template>

                </div>
            </div>
        </nav>
        <ng-container *ngIf="!loading">
            <div *ngIf="_authService.auth.user | async as user; else showLogin">
                <ng-container *ngIf="hasAccess === true && loading !== true;">
                    <router-outlet></router-outlet>
                </ng-container>
                <ng-container *ngIf="hasAccess !== true && loading !== true;">
                    <div class="container mt-2 pt-2">
                        <div class="alert alert-warning" role="alert">
                            Solo puede ingresar un administrador...
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-template #showLogin>
                <div class="container mt-2 pt-2">
                    <div class="alert alert-secondary" role="alert" *ngIf="loading !== true">
                        Inicie sesión...
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <div class="loading" *ngIf="loading">Loading&#8230;</div>
        <button class="btn btn-dark btn-sm floating-button" (click)="openFullscreen()" *ngIf="!fullScreen">
            <i class="fas fa-expand"></i>
        </button>
    </ng-container>
    <ng-container *ngIf="!isOnline">
        <div class="container pt-5">
            <div class="row justify-content-center">
                <div class="col-6 bg-dark p-3">
                    <img src="../../assets/original.png" class="img-fluid" alt="mavo logo">
                </div>
            </div>
            <h3 class="text-center mt-5">Sin una conexión a internet</h3>
            <h6 class="text-center mt-5">Revisa tu conexión</h6>
        </div>
    </ng-container>
    <button class="btn btn-secondary btn-sm floating-button" (click)="closeFullscreen()" *ngIf="fullScreen">
        <i class="fas fa-compress-arrows-alt"></i>
    </button>
</div>