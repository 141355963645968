import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Movement } from 'src/app/interfaces/movement';
import { Ticket } from 'src/app/interfaces/ticket.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { MovementsService } from 'src/app/providers/movements.service';
import { TicketsService } from 'src/app/providers/tickets.service';
import * as moment from 'moment';
import { TicketsServiceService } from '../../../providers/tickets-service.service';
import { CategoriesService } from 'src/app/providers/categories.service';
import { BrandsService } from 'src/app/providers/brands.service';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { Brand } from 'src/app/interfaces/brand.interface';
import { Subscription, interval } from 'rxjs';
moment.locale('es-mx');

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit, OnDestroy {
  @Input() dateParam: Date;
  @Input() adminMode: boolean;
  @Output() changeDate: EventEmitter<number> = new EventEmitter();
  @Output() changeDateParam: EventEmitter<Date> = new EventEmitter();

  private showSaleInfoSubscription: Subscription;
  searchMode: boolean;
  tickets: Ticket[] = [];
  globalTicketNumber: number | null;
  loading: boolean;
  loadingPrint: boolean;
  ticketSelected: Ticket;
  categories: Categorie[] = [];
  brands: Brand[] = [];
  showSaleInfo: boolean;
  reloadTicketsDataLoading: boolean;
  newDate: NgbDateStruct;
  mobile: boolean;
  totalIsActive: boolean;

  constructor(
    private _alertsService: AlertsService,
    private _ngbModalService: NgbModal,
    private _ticketsService: TicketsService,
    private _movementsService: MovementsService,
    private _printTicketsService: TicketsServiceService,
    private _categoriesService: CategoriesService,
    private _brandsService: BrandsService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth <= 800) {
      this.mobile = true;
    }
    this.getTodayTickets();
  }

  ngOnDestroy(): void {
    this.showSaleInfoSubscription &&
      this.showSaleInfoSubscription.unsubscribe();
  }

  async getInitData(): Promise<void> {
    this.categories = await this._categoriesService.getCategoriesPromise();
    this.brands = await this._brandsService.getBrandsPromise();
  }

  activeShowSaleInfo(): void {
    this.showSaleInfo = this.showSaleInfo === true ? false : true;
    this.showSaleInfoSubscription = interval(60000).subscribe(() => {
      this.showSaleInfo = false;
      this.showSaleInfoSubscription.unsubscribe();
    });
  }

  async getTodayTickets(): Promise<void> {
    try {
      this.loading = true;
      this.totalIsActive = false;
      await this.getInitData();
      this.tickets = await this._ticketsService.getTodayTickets(
        this.dateParam ? this.dateParam : null
      );
      // console.log(this.tickets, 'TICKETS');
      this.tickets = await this._ticketsService.getTicketsChanged(this.tickets);
      this.loading = false;
    } catch (error) {}
  }

  async reloadTicketsData(): Promise<void> {
    this.reloadTicketsDataLoading = true;
    this.tickets = [];
    this.tickets = await this._ticketsService.getTodayTickets();
    this.tickets = await this._ticketsService.getTicketsChanged(this.tickets);
    this.reloadTicketsDataLoading = false;
  }

  activeSearchMode(): void {
    this.searchMode = this.searchMode === true ? false : true;
    this.globalTicketNumber = null;
    if (this.searchMode === true) {
      this.tickets = [];
    }
    if (this.searchMode === false) {
      this.getTodayTickets();
    }
  }

  calculateTotal(which?: 'cash' | 'card'): number {
    let totalCost = 0;

    if (this.tickets.length > 0) {
      let tickets: Ticket[] = this.tickets.filter((t) => t.status === 1);

      if (which === 'cash')
        tickets = tickets.filter((t) => t.paymentMethod === 1);
      if (which === 'card')
        tickets = tickets.filter((t) => t.paymentMethod === 2);

      if (tickets && tickets.length > 0) {
        totalCost = tickets.reduce((accumulator, ticket) => {
          return accumulator + ticket.total;
        }, 0);
      }
    }

    return totalCost;
  }

  calculateTotalOfTicketsChanged(which?: 'cash' | 'card'): number {
    let totalCost = 0;

    if (this.tickets.length > 0) {
      let tickets: Ticket[] = this.tickets.filter((t) => t.status === 2);

      if (which === 'cash')
        tickets = tickets.filter((t) => t.paymentMethod === 1);
      if (which === 'card')
        tickets = tickets.filter((t) => t.paymentMethod === 2);

      if (tickets && tickets.length > 0) {
        totalCost = tickets.reduce((accumulator, ticket) => {
          return (
            accumulator + (ticket.total - ticket.moneyInBoxFromPreviousTicket)
          );
        }, 0);
      }
    }
    return totalCost;
  }

  calculateTotalOfTicketsCancelled(which?: 'cash' | 'card'): number {
    let totalCost = 0;

    if (this.tickets.length > 0) {
      let tickets: Ticket[] = this.tickets.filter(
        (t) => t.status === 3 && t.ifItsCanceledAddTotalToTheDaySale
      );

      if (which === 'cash')
        tickets = tickets.filter((t) => t.paymentMethod === 1);
      if (which === 'card')
        tickets = tickets.filter((t) => t.paymentMethod === 2);

      if (tickets && tickets.length > 0) {
        totalCost = tickets.reduce((accumulator, ticket) => {
          return accumulator + ticket.total;
        }, 0);
      }
    }
    return totalCost;
  }

  async searchTicketByglobalTicketNumber(): Promise<void> {
    if (this.globalTicketNumber !== null && this.globalTicketNumber > 0) {
      this.loading = true;
      const tickets: Ticket[] = await this._ticketsService.getTicketByField(
        'globalTicketNumber',
        this.globalTicketNumber,
        '=='
      );
      this.tickets = tickets;
      this.loading = false;
    }
  }

  async cancelTicket(ticket: Ticket): Promise<void> {
    try {
      const alertResponse = await this._alertsService.confirmation();
      if (alertResponse.isConfirmed) {
        const updatedDate: number = new Date().getTime();
        const movements: Movement[] =
          await this._movementsService.getMovementsByTicketUid(ticket.uid);
        for (const mov of movements) {
          const newMov: Movement = { ...mov, type: 4, updated: updatedDate };
          await this._movementsService.editMovementPromise(newMov);
        }
        await this._ticketsService.editTicket({
          ...ticket,
          status: 3,
          updated: updatedDate,
        });
        this._alertsService.toastAlert('success', 'Ticket cancelado');
        if (!this.searchMode) this.getTodayTickets();
        if (this.searchMode) this.searchTicketByglobalTicketNumber();
      }
    } catch (error) {
      this._alertsService.toastAlert('error', 'No se pudo cancelar');
    }
  }

  async revertTicket(ticket: Ticket): Promise<void> {
    try {
      const alertResponse = await this._alertsService.confirmation();
      if (alertResponse.isConfirmed) {
        const updatedDate: number = new Date().getTime();
        const movements: Movement[] =
          await this._movementsService.getMovementsByTicketUid(ticket.uid);
        for (const mov of movements) {
          const newMov: Movement = { ...mov, type: 3, updated: updatedDate };
          await this._movementsService.editMovementPromise(newMov);
        }
        await this._ticketsService.editTicket({
          ...ticket,
          status: 1,
          updated: updatedDate,
        });
        this._alertsService.toastAlert('success', 'Ticket restaurado');
        if (!this.searchMode) this.getTodayTickets();
        if (this.searchMode) this.searchTicketByglobalTicketNumber();
      }
    } catch (error) {
      this._alertsService.toastAlert('error', 'No se pudo cancelar');
    }
  }

  openTicketViewModal(modalContent: any, ticketSelected: Ticket): void {
    this.ticketSelected = ticketSelected;

    this._ngbModalService.open(modalContent, {
      backdrop: 'static',
      size: 'lg',
    });
  }

  closeModalTicketView(): void {
    this._ngbModalService.dismissAll();
  }

  getCreatedDate(created: number): any {
    return moment(created).calendar();
  }

  async printTicket(ticket: Ticket): Promise<void> {
    try {
      const alertResponse = await this._alertsService.confirmation(
        '¿Estás seguro de querer imprimir?'
      );
      if (alertResponse.isConfirmed) {
        this.loadingPrint = true;
        await this._printTicketsService.printSale(
          ticket,
          this.categories,
          this.brands,
          [],
          true
        );
        this.loadingPrint = false;
      }
    } catch (error) {
      this.loadingPrint = false;
      this._alertsService.toastAlert('error', 'No se pudo imprimir');
    }
  }

  async printTotalSale(): Promise<void> {
    try {
      const alertResponse = await this._alertsService.confirmation(
        '¿Estás seguro de querer imprimir?'
      );
      if (alertResponse.isConfirmed) {
        const sales: any = {
          all:
            this.calculateTotal() +
            this.calculateTotalOfTicketsChanged() +
            this.calculateTotalOfTicketsCancelled(),
          cash:
            this.calculateTotal('cash') +
            this.calculateTotalOfTicketsChanged('cash') +
            this.calculateTotalOfTicketsCancelled('cash'),
          card:
            this.calculateTotal('card') +
            this.calculateTotalOfTicketsChanged('card') +
            this.calculateTotalOfTicketsCancelled('card'),
        };
        this.loadingPrint = true;
        await this._printTicketsService.printTotalSales(
          sales,
          this.tickets.filter((t) => t.status === 1),
          this.categories,
          this.getTotalSalesTicketDate()
        );
        this.loadingPrint = false;
      }
    } catch (error) {
      this.loadingPrint = false;

      this._alertsService.toastAlert('error', 'No se pudo imprimir');
    }
  }

  getTotalSalesTicketDate(): Date {
    if (this.adminMode) {
      return new Date(this.dateParam);
    }
    if (!this.adminMode) {
      return new Date();
    }
  }

  isToday(dateParam?: Date | number): boolean {
    let date = dateParam ? new Date(dateParam) : new Date();
    const currentDate = new Date();
    return (
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate()
    );
  }

  changeCurrentDateData(data: number): void {
    this.newDate = null;
    this.changeDate.emit(data);
    this.getTodayTickets();
  }

  getMomentShortDate(dateParam: number | Date): any {
    let date = dateParam ? new Date(dateParam) : this.dateParam;
    return moment(date).format('ll');
  }

  getMomentTimeDate(dateParam: number | Date): any {
    let date = dateParam ? new Date(dateParam) : this.dateParam;
    return moment(date).format('LT');
  }

  changeDateFromParent(): void {
    const { year, month, day } = this.newDate;

    const newDate: Date = new Date(year, month - 1, day);
    this.changeDateParam.emit(newDate);
    this.getTodayTickets();
  }

  mouseOnTotal(isActive: boolean): void {
    if (this.adminMode) {
      this.totalIsActive = isActive;
    }
  }

  getAverage(): number {
    return this._ticketsService.calculateTheAverage(
      this.tickets,
      this.calculateTotal.bind(this)
    );
  }
}
