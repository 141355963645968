import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { FileUpload } from '../models/fileUpload.model';
import { Product } from '../interfaces/product.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductPhotosService {
  private basePath = '/productPhotos';
  private productPhotosCollection: AngularFirestoreCollection<FileUpload>;
  private productsCollection: AngularFirestoreCollection<Product>;

  constructor(
    private _afs: AngularFirestore,
    private _storage: AngularFireStorage
  ) {
    this.productPhotosCollection = _afs.collection<FileUpload>('productPhotos');
    this.productsCollection = _afs.collection<Product>('products');
  }

  pushFileToStorage(
    fileUpload: FileUpload,
    productUid: string,
    photoNumber: number
  ): Observable<number | undefined> {
    const filePath = `${this.basePath}/${productUid}/${photoNumber}/${fileUpload.file.name}`;
    const storageRef = this._storage.ref(filePath);
    const uploadTask = this._storage.upload(filePath, fileUpload.file);

    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((downloadURL) => {
            fileUpload.url = downloadURL;
            fileUpload.name = fileUpload.file.name;
            fileUpload.photoNumber = photoNumber;
            this.saveFileData(fileUpload, productUid);
          });
        })
      )
      .subscribe();

    return uploadTask.percentageChanges();
  }

  private saveFileData(fileUpload: FileUpload, productUid: string): void {
    const id = this._afs.createId();
    const fileCopy = fileUpload;
    delete fileCopy.file;

    const fileToSave: FileUpload = {
      uid: id,
      productUid: productUid,
      ...fileUpload,
    };
    this.productPhotosCollection.doc(id).set(fileToSave);
    if (fileUpload.photoNumber === 1) {
      this.productsCollection
        .doc(productUid)
        .update({ photoUrl: fileUpload.url });
    }
  }

  getProductPhotosByProductUid(productUid: string): Observable<FileUpload[]> {
    return this._afs
      .collection<FileUpload>('productPhotos', (ref) =>
        ref.where('productUid', '==', productUid)
      )
      .valueChanges();
  }

  deleteFileStorage(file: FileUpload): void {
    const filePath = `${this.basePath}/${file.productUid}/${file.photoNumber}`;
    console.log(filePath, 'file path');
    const storageRef = this._storage.ref(filePath);
    storageRef.child(file.name).delete();
  }

  deleteProductPhotos(uid: string, product?: Product, position?: number): void {
    this.productPhotosCollection.doc(uid).delete();
    if (position === 1) {
      this.productsCollection.doc(product.uid).update({ photoUrl: '' });
    }
  }
}
