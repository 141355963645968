import { Injectable } from '@angular/core';
import { Categorie } from '../interfaces/categorie.interface';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileUpload } from '../models/fileUpload.model';
import { Product } from '../interfaces/product.interface';
import { MainCategory } from '../interfaces/main-categorie.interface';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private categoriesCollection: AngularFirestoreCollection<Categorie>;
  private mainCategoriesCollection: AngularFirestoreCollection<MainCategory>;
  private basePath = '/categoriePhotos';

  constructor(
    private _afs: AngularFirestore,
    private _storage: AngularFireStorage
  ) {
    this.categoriesCollection = _afs.collection<Categorie>('categories');
    this.mainCategoriesCollection =
      _afs.collection<Categorie>('mainCategories');
  }

  async addCategorie(categorie: any): Promise<void> {
    const id = this._afs.createId();

    const categorieToSave: Categorie = {
      uid: id,
      ...categorie,
    };
    this.categoriesCollection.doc(id).set(categorieToSave);
  }

  getCategories(): Observable<Categorie[]> {
    return this.categoriesCollection.valueChanges();
  }

  async getMainCategories(): Promise<MainCategory[]> {
    const querySnapshot: any = await this.mainCategoriesCollection
      .get()
      .toPromise();
    if (!querySnapshot.empty) {
      const mainCategoriesArray = querySnapshot.docs.map(
        (doc) => doc.data() as MainCategory
      );
      return mainCategoriesArray;
    } else {
      return [];
    }
  }

  getCategoriesAvailables(): Observable<Categorie[]> {
    return this._afs
      .collection<Categorie>('categories', (ref) =>
        ref.where('show', '==', true)
      )
      .valueChanges();
  }

  editCategorie(categorie: Categorie) {
    return this.categoriesCollection.doc(categorie.uid).update(categorie);
  }

  getCategory(categoryUid: string, categories: Categorie[]): Categorie {
    return categories.find((c) => c.uid === categoryUid);
  }

  getCategorieName(categorieUid: string, categories: Categorie[]): string {
    return categories.find((c) => c.uid === categorieUid).singularName;
  }

  getCategorieNamePlural(
    categorieUid: string,
    categories: Categorie[]
  ): string {
    return categories.find((c) => c.uid === categorieUid).name;
  }

  pushFileToStorage(
    fileUpload: FileUpload,
    categorieUid: string,
    categorieName: string
  ): Observable<number | undefined> {
    const filePath = `${this.basePath}/${categorieUid}/${categorieName}`;
    const storageRef = this._storage.ref(filePath);
    const uploadTask = this._storage.upload(filePath, fileUpload.file);

    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((downloadURL) => {
            fileUpload.url = downloadURL;
            this.saveFileData(fileUpload, categorieUid);
          });
        })
      )
      .subscribe();

    return uploadTask.percentageChanges();
  }

  private saveFileData(fileUpload: FileUpload, categorieUid: string): void {
    const fileCopy = fileUpload;
    delete fileCopy.file;

    this.categoriesCollection
      .doc(categorieUid)
      .update({ photoURL: fileUpload.url });
  }

  deleteFileStorage(categorieUid: string, categorieName: string): void {
    const filePath = `${this.basePath}/${categorieUid}`;
    console.log(filePath, 'file path');
    const storageRef = this._storage.ref(filePath);
    storageRef.child(categorieName).delete();
  }

  deleteProductPhotos(categorieUid?: string): void {
    this.categoriesCollection.doc(categorieUid).update({ photoURL: '' });
  }

  getCategorieUid(productUid: string, products: Product[]): string {
    const product = products.find((p) => {
      return p.uid === productUid;
    });
    if (product && product.categorieUid) {
      return product.categorieUid;
    }
    return '';
  }

  async getCategoriesPromise(): Promise<Categorie[]> {
    try {
      const collectionRef = 'categories';
      const querySnapshot: any = await this._afs
        .collection(collectionRef)
        .get()
        .toPromise();
      if (!querySnapshot.empty) {
        // const documentData = querySnapshot.docs[0].data();
        // return documentData;
        const categoriesArray = querySnapshot.docs.map(
          (doc) => doc.data() as Categorie
        );
        return categoriesArray;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  }
}
