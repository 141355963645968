import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CategoriesService } from '../../../providers/categories.service';
import { Categorie } from '../../../interfaces/categorie.interface';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
})
export class CategorySelectComponent implements OnInit {
  categories: Categorie[] = [];
  @Input() categorieSelected: string = '';
  @Output() sendData: EventEmitter<string> = new EventEmitter();

  constructor(private _categoriesService: CategoriesService) {}

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this._categoriesService.getCategories().subscribe((cats) => {
      this.categories = cats;
    });
  }
}
