import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-name-input',
  templateUrl: './name-input.component.html',
  styleUrls: ['./name-input.component.scss'],
})
export class NameInputComponent implements OnInit {
  @Input() name: string = '';
  @Output() sendDataEvent = new EventEmitter();
  @Input() loading: boolean;
  @Input() edit: boolean;
  @Input() showButton: boolean = true;
  @Input() placeholder: string = 'Nombre';

  constructor() {}

  ngOnInit(): void {}

  sendData() {
    console.log(this.name);
    this.sendDataEvent.emit(this.name);
  }
}
