<ng-template #modalContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{edit ? 'Nuevo' : 'Editar'}} movimiento</h4>
        <button type="button" class="btn btn-dark btn-sm" (click)="clean()">
            <i class="fa-solid fa-circle-xmark"></i>
        </button>
    </div>
    <div class="modal-body">
        <app-save-movement [products]="products" [productsCopy]="productsCopy" [movementSelected]="movementSelected"
            [brands]="brands" [categories]="categories" [edit]="edit"
            (resetEditMovementData)="resetDatForEditMovement()" (clean)="clean()"></app-save-movement>
    </div>
</ng-template>

<div class="container">
    <div class="row">
        <div class="col-4">
            <div class="d-flex justify-content-start pt-2 pb-2">
                <app-back-button [mini]="true"></app-back-button>
            </div>
        </div>
        <div class="col-4">
            <div class="d-flex justify-content-center pt-2 pb-2">
                <button class="btn btn-primary btn-sm" (click)="openModal(modalContent)" *ngIf="!showOperations">
                    <i class="fa-solid fa-plus"></i>
                </button>
                <!-- <button class="btn btn-success btn-sm" (click)="openModal(mostProductSelledModalContent, 'lg')"
                    *ngIf="showOperations">
                    <i class="fas fa-chart-area"></i>
                </button> -->
            </div>
        </div>
        <div class="col-4">
            <div class="d-flex justify-content-end pt-2 pb-2">
                <button class="btn btn-secondary btn-sm" (click)="showTotalsMovements()" *ngIf="!loadingOperations">
                    <i class="fa-solid fa-eye" *ngIf="showOperations"></i>
                    <i class="fa-solid fa-eye-slash" *ngIf="!showOperations"></i>
                </button>
                <button class="btn btn-secondary" type="button" disabled *ngIf="loadingOperations">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="showOperations && !loadingOperations">
        <div class="col-md-8" [ngClass]="{'d-flex justify-content-center': !mobile}">
            <span class="badge bg-warning text-dark fs-6 me-3 mb-3">
                Inversión: <br><b>{{total | currency}}</b>
            </span>
            <span class="badge bg-dark text-white fs-6 me-2 mb-3">
                Venta: <br><b>{{totalSales | currency}}</b>
            </span>
            <span class="badge bg-warning text-dark fs-6 me-2 mb-3">
                Inversión actual: <br><b>{{movementsTotals.currentInvestment|currency}}</b>
            </span>
            <span class="badge bg-dark text-white fs-6 me-2 mb-3">
                Venta esperada: <br><b>{{movementsTotals.expectedSales | currency}}</b>
            </span>
            <span class="badge text-white fs-6 me-2 mb-3"
                [ngClass]="{'bg-danger': ((totalSales - total)) <= 0, 'bg-success': ((totalSales - total)) > 0 }">
                Utilidad: <br><b>{{totalSales - total | currency}}</b>
            </span>
            <span class="badge bg-primary text-white fs-6 me-2 mb-3">
                Utilidad esperada: <br><b>{{movementsTotals.expectedSales - movementsTotals.currentInvestment |
                    currency}}</b>
            </span>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-md-4 mb-2">
            <div class="row">
                <div class="col-6">
                    <div class="input-group w-100">
                        <input class="form-control" placeholder="Desde" name="dp" [(ngModel)]="startDate" ngbDatepicker
                            #d="ngbDatepicker" autocomplete="off" (ngModelChange)="filterDataByDate()" />
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="input-group w-100">
                        <input class="form-control" placeholder="Hasta" name="dp" [(ngModel)]="endDate" ngbDatepicker
                            #d2="ngbDatepicker" autocomplete="off" (ngModelChange)="filterDataByDate()" />
                        <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                            <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 mb-2" *ngIf="filterByProductName">
            <app-product-finder [options]="productsString" (sendData)="filterMovementsByProduct($event)"
                *ngIf="!productsFinderLoader"></app-product-finder>
        </div>
        <ng-container *ngIf="!filterByProductName">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <app-category-select (sendData)="filterBySelectedCategory($event)"
                            [categorieSelected]="selectedCategory">
                        </app-category-select>
                    </div>
                    <div class="col-md-6 mb-2">
                        <app-product-select [categoryUid]="selectedCategory"
                            (sendData)="filterMovementsByProductWithSelect($event)">
                        </app-product-select>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="col-md-3 mb-2">
            <button class="btn btn-light me-2" (click)="resetFilters()"
                [disabled]="selectedCategory === '' && (startDate === undefined && endDate === undefined)"
                *ngIf="!filterByProductName">
                Reiniciar
            </button>
            <button class="btn btn-light me-2" (click)="resetFilters()"
                [disabled]="productUid === '' && (startDate === undefined && endDate === undefined)"
                *ngIf="filterByProductName">
                Reiniciar
            </button>
            <button class="btn btn-info" (click)="activeFilterByProductName()" [disabled]="selectedCategory !== ''">
                <i class="fas fa-file-alt" *ngIf="!filterByProductName"></i>
                <i class="fas fa-filter" *ngIf="filterByProductName"></i>
            </button>
        </div>
    </div>
    <ul ngbNav #nav="ngbNav" [(activeId)]="tabActive" class="nav-tabs">
        <li [ngbNavItem]="1">
            <a ngbNavLink>Movimientos</a>
            <ng-template ngbNavContent>
                <div class="table-responsive">
                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                        <table class="table table-striped table-hover" *ngIf="!loading">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{{mobile ? '' : 'Tipo'}}</th>
                                    <th scope="col">Producto</th>
                                    <!-- <th scope="col">Marca</th>
                                    <th scope="col">Categoría</th> -->
                                    <th scope="col">Total</th>
                                    <th scope="col" *ngIf="!mobile">Fecha</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="movementsData.length > 0 && products.length > 0 && loading === false">
                                <tr *ngFor="let movement of movementsData; let i = index">
                                    <th scope="row">{{(((currentPage - 1) * pageSize) + (i + 1))}}</th>
                                    <td>
                                        <i class="fa-solid fa-angles-up text-success" *ngIf="movement.type == 1"></i>
                                        <i class="fa-solid fa-angles-down text-secondary"
                                            *ngIf="movement.type == 2"></i>
                                        <i class="fa-solid fa-arrow-right text-info" *ngIf="movement.type == 3"></i>
                                        <i class="fa-solid fa-xmark text-danger" *ngIf="movement.type == 4"></i>
                                    </td>
                                    <td [innerHtml]="_productsService.getProductName(movement.productUid, products)">
                                        {{_productsService.getProductName(movement.productUid, products)}}
                                    </td>
                                    <!-- <td>{{getBrandName(movement.productUid)}}</td>
                                    <td>{{getCategorieName(movement.productUid)}}</td> -->
                                    <td style="cursor: pointer;" (mouseenter)="onMouseEnter(i)"
                                        (mouseleave)="onMouseLeave()">
                                        <ng-container *ngIf="i !== currentIndex">
                                            {{movement.productsNumber}} <b>x</b> {{movement.costByUnit | currency}}
                                        </ng-container>
                                        <span class="w-100 text-center" *ngIf="i === currentIndex">
                                            <b>{{movement.productsNumber *
                                                movement.costByUnit
                                                | currency }}</b>
                                        </span>
                                    </td>
                                    <td *ngIf="!mobile">{{movement.created | date}}</td>
                                    <td>
                                        <button class="btn btn-info btn-sm me-2 mbSm" [disabled]="movement.type == 4"
                                            (click)="openModalEdit(modalContent, movement)">
                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </button>
                                        <button class="btn btn-danger btn-sm"
                                            [disabled]="movement.type == 4 || loadingTable || ((movement.ticketUid && movement.ticketUid.length > 0))"
                                            (click)="editOnePropertyValueOfMovement(movement, 'type', 4)">
                                            <i class="fa-solid fa-xmark"></i>
                                        </button>
                                        <!-- <button class="btn btn-danger btn-sm" (click)="deleteMovement(movement)"
                                            [disabled]="loadingTable">
                                            <i class="fa-solid fa-trash"></i>
                                        </button> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center mt-5 pt-5" *ngIf="loading">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row" *ngIf="showOperations && !loadingOperations">
                    <div class="col-lg-3">
                        <b>N° Movimientos</b><br>
                        <span class="me-3">
                            <i class="fa-solid fa-angles-up text-success"></i>
                            <b class="ms-1">{{getMovementsLengthByType(1)}}</b>
                        </span>
                        <span class="me-3">
                            <i class="fa-solid fa-angles-down text-secondary"></i>
                            <b class="ms-1">{{getMovementsLengthByType(2)}}</b>
                        </span>
                        <span class="me-3">
                            <i class="fa-solid fa-arrow-right text-info"></i>
                            <b class="ms-1">{{getMovementsLengthByType(3)}}</b>
                        </span>
                        <span class="me-3">
                            <i class="fa-solid fa-xmark text-danger"></i>
                            <b class="ms-1">{{getMovementsLengthByType(4)}}</b>
                        </span>
                        <span class="me-3">
                            <i class="fa-solid fa-equals text-secondary"></i>
                            <b class="ms-1">{{getMovementsLengthByType(1) + getMovementsLengthByType(2) +
                                getMovementsLengthByType(3) + getMovementsLengthByType(4)}}</b>
                        </span>
                    </div>
                    <div class="col-lg-3">
                        <b>N° Productos</b><br>
                        <span class="me-3">
                            <i class="fa-solid fa-angles-up text-success"></i>
                            <b class="ms-1">{{movementsTotals.productsEntries}}</b>
                        </span>
                        <span class="me-3">
                            <i class="fa-solid fa-angles-down text-secondary"></i>
                            <b class="ms-1">{{movementsTotals.productsRemovals}}</b>
                        </span>
                        <span class="me-3">
                            <i class="fa-solid fa-arrow-right text-info"></i>
                            <b class="ms-1">{{movementsTotals.productsSales}}</b>
                        </span>
                        <!-- <span class="me-3">
                            <i class="fa-solid fa-angles-down text-danger"></i>
                            <b class="ms-1">{{movementsTotals.productsCanceled}}</b>
                        </span> -->
                        <span class="me-3">
                            <i class="fa-solid fa-box-open" style="color: #dc9456;"></i>
                            <b class="ms-1">
                                {{movementsTotals.productsEntries - movementsTotals.productsRemovals -
                                movementsTotals.productsSales}}
                            </b>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center mb-3 pb-3">
                    <div class="col-md-4 mt-3"></div>
                    <div class="col-md-4 mt-3">
                        <div class="d-flex justify-content-center">
                            <span class="fw-bold me-3" style="display: flex; align-items: center;"> Página {{
                                currentPage }}
                            </span>
                            <button class="btn btn-primary btn-sm me-5" (click)="prevPage()"
                                [disabled]="currentPage === 1 || loading">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                    <path
                                        d="m432-480 156 156q11 11 11 28t-11 28q-11 11-28 11t-28-11L348-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q11-11 28-11t28 11q11 11 11 28t-11 28L432-480Z"
                                        fill="white" />
                                </svg>
                            </button>
                            <button class="btn btn-primary btn-sm" (click)="nextPage()"
                                [disabled]="movementsData.length != pageSize || loading">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                    <path
                                        d="M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z"
                                        fill="white" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="row justify-content-end">
                            <div class="col-md-6">
                                <select class="form-control" type="text" id="productCategory" [(ngModel)]="pageSize"
                                    (change)="changePageSize()">
                                    <option [value]="page" *ngFor="let page of pageSizeItems">
                                        <b>Items por página:</b> {{page}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="showOperations" (click)="activeColumn = 'totalSales'">
            <a ngbNavLink>Productos</a>
            <ng-template ngbNavContent>
                <app-sold-products-report [mostSelledProducts]="mostSelledProducts" [filterDesc]="filterDesc"
                    [sortFunction]="sortByField" [isActiveColumnFunction]="isColumnActive" [filters]="filters"
                    [products]="products" [totalSales]="totalSales"></app-sold-products-report>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" *ngIf="showOperations" (click)="activeColumn = 'total'">
            <a ngbNavLink>Categorías</a>
            <ng-template ngbNavContent>
                <div class="container">
                    <div class="table-responsive">
                        <div class="table-wrapper-scroll-y my-custom-scrollbar">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['categoryName']"
                                            (click)="sortByField(mostSelledByCategory, 'categoryName')">
                                            Nombre <span class="sort-arrow" *ngIf="isColumnActive('categoryName')">{{
                                                filterDesc['total'] ? '↓' : '↑' }}</span>
                                        </th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['total']"
                                            (click)="sortByField(mostSelledByCategory, 'total')">
                                            Venta <span class="sort-arrow" *ngIf="isColumnActive('total')">{{
                                                filterDesc['total'] ? '↓' : '↑' }}</span>
                                        </th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['investment']"
                                            (click)="sortByField(mostSelledByCategory, 'investment')">
                                            Inversión <span class="sort-arrow" *ngIf="isColumnActive('investment')">{{
                                                filterDesc['investment'] ? '↓' : '↑' }}</span>
                                        </th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['utility']"
                                            (click)="sortByField(mostSelledByCategory, 'utility')">
                                            Utilidad <span class="sort-arrow" *ngIf="isColumnActive('utility')">{{
                                                filterDesc['utility'] ? '↓' : '↑' }}</span>
                                        </th>
                                        <ng-container
                                            *ngIf="filters.length === 0 && !startDate && !endDate && !loadingOperations">
                                            <th scope="col" class="pointer"
                                                [class.sorted]="filterDesc['currentInvestment']"
                                                (click)="sortByField(mostSelledByCategory, 'currentInvestment')">
                                                Inv. Actual <span class="sort-arrow"
                                                    *ngIf="isColumnActive('currentInvestment')">{{
                                                    filterDesc['currentInvestment'] ? '↓' : '↑' }}</span>
                                            </th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let categorySelled of mostSelledByCategory; let i = index;">
                                        <th scope="row">{{i + 1}}</th>
                                        <td>
                                            {{categorySelled.categoryName}}
                                            <span class="badge bg-warning"
                                                *ngIf="doubleTheInvestmentWasSold(categorySelled.total, categorySelled.investment)">
                                                <i class="fas fa-award text-dark"></i>
                                            </span>
                                        </td>
                                        <td>
                                            {{categorySelled.total | currency}}
                                        </td>
                                        <td>
                                            {{categorySelled.investment | currency}}
                                        </td>
                                        <td class="text-white fw-bold"
                                            [ngClass]="{'bg-danger': categorySelled.total - categorySelled.investment < 0 , 'bg-success': (categorySelled.total - categorySelled.investment >= 0)}">
                                            <!-- {{categorySelled.total - categorySelled.investment | currency}} -->
                                            {{categorySelled.utility | currency}}
                                        </td>
                                        <td
                                            *ngIf="filters.length === 0 && !startDate && !endDate && !loadingOperations">
                                            {{categorySelled.currentInvestment | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="4" *ngIf="showOperations" (click)="activeColumn = 'total'">
            <a ngbNavLink>Categorías global</a>
            <ng-template ngbNavContent>
                <div class="container">
                    <div class="table-responsive">
                        <div class="table-wrapper-scroll-y my-custom-scrollbar">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['categoryName']"
                                            (click)="sortByField(mostSelledByMainCategory, 'categoryName')">
                                            Nombre <span class="sort-arrow" *ngIf="isColumnActive('categoryName')">{{
                                                filterDesc['categoryName'] ? '↓' : '↑' }}</span>
                                        </th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['total']"
                                            (click)="sortByField(mostSelledByMainCategory, 'total')">
                                            Venta <span class="sort-arrow" *ngIf="isColumnActive('total')">{{
                                                filterDesc['total'] ? '↓' : '↑' }}</span>
                                        </th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['investment']"
                                            (click)="sortByField(mostSelledByMainCategory, 'investment')">
                                            Inversión <span class="sort-arrow" *ngIf="isColumnActive('investment')">{{
                                                filterDesc['investment'] ? '↓' : '↑' }}</span>
                                        </th>

                                        <th scope="col" class="pointer" [class.sorted]="filterDesc['utility']"
                                            (click)="sortByField(mostSelledByMainCategory, 'utility')">
                                            Utilidad <span class="sort-arrow" *ngIf="isColumnActive('utility')">{{
                                                filterDesc['utility'] ? '↓' : '↑' }}</span>
                                        </th>
                                        <ng-container
                                            *ngIf="filters.length === 0 && !startDate && !endDate && !loadingOperations">
                                            <th scope="col" class="pointer"
                                                [class.sorted]="filterDesc['currentInvestment']"
                                                (click)="sortByField(mostSelledByMainCategory, 'currentInvestment')">
                                                Inv. Actual <span class="sort-arrow"
                                                    *ngIf="isColumnActive('currentInvestment')">{{
                                                    filterDesc['currentInvestment'] ? '↓' : '↑' }}</span>
                                            </th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let mainCategorySelled of mostSelledByMainCategory; let i = index;">
                                        <th scope="row">{{i + 1}}</th>
                                        <td>
                                            {{getMainCategoryName(mainCategorySelled.mainCategoryUid)}}
                                            <span class="badge bg-warning"
                                                *ngIf="doubleTheInvestmentWasSold(mainCategorySelled.total, mainCategorySelled.investment)">
                                                <i class="fas fa-award text-dark"></i>
                                            </span>
                                        </td>
                                        <td>{{mainCategorySelled.total | currency}}</td>
                                        <td>{{mainCategorySelled.investment | currency}}</td>
                                        <td class="text-white fw-bold"
                                            [ngClass]="{'bg-danger': mainCategorySelled.total - mainCategorySelled.investment < 0 , 'bg-success': (mainCategorySelled.total - mainCategorySelled.investment >= 0)}">
                                            <!-- {{mainCategorySelled.total - mainCategorySelled.investment | currency}} -->
                                            {{mainCategorySelled.utility | currency}}
                                        </td>
                                        <td
                                            *ngIf="filters.length === 0 && !startDate && !endDate && !loadingOperations">
                                            {{mainCategorySelled.currentInvestment | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="5"
            *ngIf="showOperations && filters.length === 0 && !startDate && !endDate && !loadingOperations">
            <a ngbNavLink>Agotados</a>
            <ng-template ngbNavContent>
                <app-stock [movements]="movementsArrayDataForTotals" [products]="products"
                    [categories]="categories"></app-stock>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>