<div class="container pb-1">
    <div class="row justify-content-center mb-2">
        <div class="col-md-4">
            <select class="form-control" (change)="selectCategory($event)">
                <option value="">Selecciona una categoría</option>
                <option [value]="c.uid" *ngFor="let c of categories | sortByLetter">{{c.name}}</option>
            </select>
        </div>
        <div class="col-md-4" *ngIf="categorySelected && categorySelected.length > 0 ">
            <select class="form-control" (change)="selectStock()" [(ngModel)]="fIlterOutOfStock">
                <option [value]="v" *ngFor="let v of valuesForFIlterOutOfStock">
                    <ng-container *ngIf="v == 0">
                        Agotados
                    </ng-container>
                    <ng-container *ngIf="v != 0">
                        <= {{v}} </ng-container>
                </option>
            </select>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-2 mb-2">
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="showOutOfStockCheck" [(ngModel)]="outOfStock"
                (change)="filterByOutOfStock()" style="cursor: pointer;">
        </div>
    </div>
    <div class="table-responsive">
        <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th></th>
                        <th scope="col">Nombre</th>
                        <th scope="col" (click)="customSort('stock')" style="cursor: pointer;">Stock</th>
                        <th scope="col" (click)="customSort('lastMovementDate')" style="cursor: pointer;">Fecha</th>
                    </tr>
                </thead>
                <tbody *ngIf="categorySelected && categorySelected.length > 0 && stockData.length > 0">
                    <tr *ngFor="let sd of stockData; let i = index">
                        <th scope="row">{{i + 1}}</th>
                        <td style="cursor: pointer;" (click)="openImage(sd.photoUrl)">
                            <img [src]="sd.photoUrl && sd.photoUrl.length > 1 ? sd.photoUrl : '../../../../assets/images/no-image-small.jpg'"
                                alt="profile image" class="img-fluid rounded-circle border-3"
                                style="width: 35px; height: 35px;">
                        </td>
                        <td [innerHtml]="_productsService.getProductName(sd.productUid, products)">
                            {{_productsService.getProductName(sd.productUid, products)}}
                        </td>
                        <td>
                            <span class="badge bg-danger text-white" *ngIf="sd.stock == 0">
                                {{sd.stock}}
                            </span>
                            <span class="badge bg-success text-white" *ngIf="sd.stock != 0">
                                {{sd.stock}}
                            </span>
                        </td>
                        <td>{{getDate(sd.lastMovementDate)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>