<!-- Category Section Start-->
<section class="mb-lg-10 mt-lg-14 my-8">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-6">
                <h3 class="mb-0">Categorías</h3>
            </div>
        </div>
        <ngx-slick-carousel class="carousel category-slider" #slickModal="slick-carousel" [config]="slideConfig"
            *ngIf="!loading">
            <div ngxSlickItem *ngFor="let categorie of categories" class="slide">
                <div class="item" (click)="goToCategory(categorie)">
                    <a class="text-decoration-none text-inherit">
                        <div class="card card-product mb-lg-4">
                            <div class="card-body text-center py-8">
                                <div class="w-100 d-flex justify-content-center">
                                    <img src="{{ categorie.photoURL }}" alt="{{categorie.name}}"
                                        class="mb-3 w-100 imageResponsive" height="125px" *ngIf="!loading">
                                    <div class="content-is-loading" *ngIf="loading">
                                    </div>
                                </div>
                                <div class="text-truncate" *ngIf="!loading">{{categorie.name}}</div>
                                <div class="content-is-loading2 mt-2" *ngIf="loading"></div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </ngx-slick-carousel>
        <ngx-slick-carousel class="carousel category-slider" #slickModal="slick-carousel" [config]="slideConfig"
            *ngIf="loading">
            <div ngxSlickItem *ngFor="let categorie of categories" class="slide">
                <div class="item">
                    <a class="text-decoration-none text-inherit">
                        <div class="card card-product mb-lg-4">
                            <div class="card-body text-center py-8">
                                <div class="w-100 d-flex justify-content-center">

                                    <div class="content-is-loading">
                                    </div>
                                </div>
                                <div class="content-is-loading2 mt-2"></div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</section>
<!-- Category Section End-->