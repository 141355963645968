import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss'],
})
export class WhatsappButtonComponent implements OnInit {
  sectionName: string = 'inicio';
  pathUid: string;

  constructor(private _router: Router, private _route: ActivatedRoute) {
    this._router.events.subscribe((val: any) => {
      if (val && val.url && val.url.includes('categorie')) {
        this.sectionName = 'categorie';
      } else if (val && val.url && val.url.includes('product')) {
        this.sectionName = 'product';
      } else {
        this.sectionName = 'inicio';
      }
    });
  }

  ngOnInit(): void {
    this.pathUid = this._route.snapshot.paramMap.get('uid');
  }

  imInterested() {
    let url = `https://api.whatsapp.com/send?phone=+524811390039&text=`;

    if (this.sectionName === 'product') {
      url =
        url +
        `Hola,%20me%20interesa%20este%20producto%0A%0Ahttps://mavostore-53c97.web.app/product/${this.pathUid}.`;
      window.open(url, '_blank');
    }
    if (this.sectionName === 'categorie') {
      url =
        url +
        `Hola,%20me%20interesa%20un%20producto%20de%20está%20categoría%0A%0Ahttps://mavostore-53c97.web.app/categorie/${this.pathUid}.`;
      window.open(url, '_blank');
    }
    if (this.sectionName === 'inicio') {
      url = url + `Hola,%20quiero%20informes...`;
      window.open(url, '_blank');
    }
  }
}
