import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { interval, Subscription, throwError } from 'rxjs';
import { Brand } from 'src/app/interfaces/brand.interface';
import { CardPaymentMethod } from 'src/app/interfaces/cardPaymentMethods.interface';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { Movement } from 'src/app/interfaces/movement';
import { Product } from 'src/app/interfaces/product.interface';
import { Ticket } from 'src/app/interfaces/ticket.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { BrandsService } from 'src/app/providers/brands.service';
import { CategoriesService } from 'src/app/providers/categories.service';
import { MovementsService } from 'src/app/providers/movements.service';
import { TicketsServiceService } from 'src/app/providers/tickets-service.service';
import { TicketsService } from 'src/app/providers/tickets.service';
moment.locale('es-mx');

@Component({
  selector: 'app-second-checkout-section',
  templateUrl: './second-checkout-section.component.html',
  styleUrls: ['./second-checkout-section.component.scss'],
})
export class SecondCheckoutSectionComponent implements OnInit, OnDestroy {
  @Input() products: Product[] = [];
  @Input() movements: Movement[] = [];
  @Input() movementsOriginalCopy: Movement[] = [];
  @Input() cashRegister: number;
  @Input() tickets: Ticket[] = [];
  @Input() ticket: Ticket;
  @Output() productUidForRemoveMovement: EventEmitter<string> =
    new EventEmitter();
  @Output() incrementOrDecrementProductsNumberProperty: EventEmitter<{
    value: string;
    productUid: string;
  }> = new EventEmitter();
  @Output() changeStateOfMovementDataByUid: EventEmitter<Movement> =
    new EventEmitter();
  @Output() selectCurrentTicket: EventEmitter<number> = new EventEmitter();
  @Output() changeStateOfTicketDataByUid: EventEmitter<Ticket> =
    new EventEmitter();
  @Output() cleanCash: EventEmitter<number> = new EventEmitter();
  @Output() cleanCashOnlyTicket: EventEmitter<number> = new EventEmitter();
  today: any = new Date();
  private subscription: Subscription;
  percents: number[] = [0, 5, 10, 15, 20, 25, 40, 50];
  percent: number = 0;
  productUidSelected: string;
  moneyInBoxFromPreviousTicket: number = 0;
  idGlobalOfPrevTicket: number;
  createdDate: { year: number; month: number; day: number } = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  createdDateTime: { hour: number; minute: number } = {
    hour: new Date().getHours(),
    minute: new Date().getMinutes(),
  };
  paymentOptions: boolean;
  bills: number[] = [20, 50, 100, 200, 500];
  otherCantity: number;
  change: number;
  billSelected: number;
  paymentCompleted: boolean;
  cardPaymentMethods: CardPaymentMethod[] = [];
  cardPaymentMethodSelected: string;
  saveTicketLoading: boolean;
  categories: Categorie[] = [];
  brands: Brand[] = [];
  prevTicket: Ticket;
  loadingWhileSearchingForPreviousTicket: boolean;

  constructor(
    public _ngbModalService: NgbModal,
    private _ticketsService: TicketsService,
    private _movementsService: MovementsService,
    private _alertsService: AlertsService,
    private _categoriesService: CategoriesService,
    private _brandsService: BrandsService,
    private _printTicketsService: TicketsServiceService
  ) {}

  ngOnInit(): void {
    this.getInitData();
    this.subscription = interval(1000).subscribe(() => {
      this.updateDate();
    });
    this.selectCurrentTicket.emit(this.cashRegister);
  }

  async getInitData(): Promise<void> {
    this.categories = await this._categoriesService.getCategoriesPromise();
    this.brands = await this._brandsService.getBrandsPromise();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onOtherCantityChange(): void {
    if (!this.paymentCompleted) {
      this.billSelected = null;
      if (!this.ticket.itsChange) {
        if (this.otherCantity >= this.calculateTotal()) {
          this.change = this.otherCantity - this.calculateTotal();
        } else {
          this.change = 0;
        }
      } else {
        const newValue: number =
          this.calculateTotal() - this.ticket.moneyInBoxFromPreviousTicket;
        if (this.otherCantity >= newValue) {
          this.change = this.otherCantity - newValue;
        } else {
          this.change = 0;
        }
      }
    }
  }

  onBillSelectedChange(bill: number): void {
    if (!this.paymentCompleted) {
      this.otherCantity = null;
      if (!this.ticket.itsChange) {
        if (bill >= this.calculateTotal()) {
          this.billSelected = bill;
          this.change = this.billSelected - this.calculateTotal();
        }
      } else {
        const newValue: number =
          this.calculateTotal() - this.ticket.moneyInBoxFromPreviousTicket;
        if (bill >= newValue) {
          this.billSelected = bill;
          this.change = this.billSelected - newValue;
        }
      }
    }
  }

  getDateWithMoment(today): any {
    return moment(today).format('LLLL');
  }

  private updateDate(): void {
    this.today = new Date();
  }

  getProductName(productUid: string): string {
    return this.products.find((p) => p.uid === productUid).name;
  }

  getProduct(productUid: string): Product {
    return this.products.find((p) => p.uid === productUid);
  }

  calculateTotal(): number {
    const movements = this.getMovementsForCashRegister();

    let totalCost = 0;

    if (movements && movements.length > 0) {
      totalCost = movements.reduce((accumulator, mov) => {
        return (
          accumulator +
          this.getRoundedNum(mov.productsNumber * this.getMovementPrice(mov))
        );
      }, 0);
    }

    return totalCost;
  }

  calculateSubTotal(): number {
    const movements = this.getMovementsForCashRegister();

    let totalCost = 0;

    if (movements && movements.length > 0) {
      totalCost = movements.reduce((accumulator, mov) => {
        return (
          accumulator +
          mov.productsNumber * this.getOriginalCostByUnit(mov.productUid)
        );
      }, 0);
    }

    return totalCost;
  }

  calculateCustomTotal(movements: Movement[]): number {
    let totalCost = 0;

    if (movements && movements.length > 0) {
      totalCost = movements.reduce((accumulator, mov) => {
        const realValue: number = mov.costByUnit * (mov.cartDiscount / 100);
        return (
          accumulator +
          this.getRoundedNum(mov.productsNumber * (mov.costByUnit - realValue))
        );
      }, 0);
    }

    return totalCost;
  }

  getOriginalCostByUnit(productUid: string): number {
    const product: Product = this.getProduct(productUid);
    if (!product.offSale) {
      return Number(
        this.movementsOriginalCopy.find((m) => m.productUid === productUid)
          .costByUnit
      );
    }
    if (product.offSale) {
      return Number(product.previousPrice);
    }
  }

  getMovementsForCashRegister(): Movement[] {
    return this.movements.filter((m) => m.cashRegister === this.cashRegister);
  }

  removeMovement(productUid: string): void {
    this.productUidForRemoveMovement.emit(productUid);
  }

  editProductsNumberProperty(
    value: 'increment' | 'decrement',
    productUid: string
  ) {
    const data = { value, productUid };
    this.incrementOrDecrementProductsNumberProperty.emit(data);
  }

  openPercentModal(modalContent: any, productUid: string): void {
    this.productUidSelected = productUid;
    const mov: Movement = this.getMovement(this.productUidSelected);
    if (mov.cartDiscount > 0) {
      this.percent = mov.cartDiscount;
    }
    this._ngbModalService.open(modalContent, {
      backdrop: 'static',
      size: 'sm',
    });
  }

  closePercentModal() {
    this.productUidSelected = '';
    this.percent = 0;
    this._ngbModalService.dismissAll();
  }

  applyPercent(confirm?: boolean): void {
    let movementPercentEdited: Movement = this.getMovement(
      this.productUidSelected
    );
    let newObjectMovement = { ...movementPercentEdited };

    this.changeStateOfMovementDataByUid.emit({
      ...newObjectMovement,
      cartDiscount: Number(this.percent),
    });
    if (confirm === true) {
      this.closePercentModal();
    }
  }

  getRoundedNum(value: number): number {
    return Math.round(Number(value.toFixed(2)));
  }

  getCurrentProductDiscount(showWithDiscount?: boolean): number {
    if (this.productUidSelected !== '') {
      const mov: Movement = this.getMovement(this.productUidSelected);
      if (showWithDiscount === true) {
        const realValue: number = mov.costByUnit * (mov.cartDiscount / 100);
        return this.getRoundedNum(mov.costByUnit - realValue);
      } else {
        return mov.costByUnit;
      }
    }
  }

  getMovementPrice(mov: Movement): number {
    const realValue: number = mov.costByUnit * (mov.cartDiscount / 100);
    return this.getRoundedNum(mov.costByUnit - realValue);
  }

  percentCancelled(): void {
    const mov: Movement = this.getMovement(this.productUidSelected);
    let newObjectMovement: Movement = { ...mov, cartDiscount: 0 };

    this.changeStateOfMovementDataByUid.emit(newObjectMovement);
    this.closePercentModal();
  }

  getMovement(productUid: string): Movement {
    return this.movements.find(
      (m) => m.productUid === productUid && m.cashRegister === this.cashRegister
    );
  }

  productIsOffSale(productUid: string): boolean {
    const product: Product = this.getProduct(productUid);

    if (product && product.offSale && product.offSale === true) {
      return true;
    }
    return false;
  }

  openChangeSectionModal(modalContent: any): void {
    if (
      this.ticket.idGlobalOfPrevTicket &&
      this.ticket.idGlobalOfPrevTicket > 0
    ) {
      this.idGlobalOfPrevTicket = this.ticket.idGlobalOfPrevTicket;
    }
    if (
      this.ticket.moneyInBoxFromPreviousTicket &&
      this.ticket.moneyInBoxFromPreviousTicket > 0
    ) {
      this.moneyInBoxFromPreviousTicket =
        this.ticket.moneyInBoxFromPreviousTicket;
    }
    this.editcreatedDate();
    this._ngbModalService.open(modalContent, {
      backdrop: 'static',
      size: 'md',
    });
  }

  closeChangeSectionModal() {
    let newObjectTicket = { ...this.ticket };

    this.changeStateOfTicketDataByUid.emit({
      ...newObjectTicket,
      itsChange: false,
      status: 1,
    });
    this._ngbModalService.dismissAll();
  }

  editcreatedDate(): void {
    const { created } = this.ticket;
    this.createdDate.year = new Date(created).getFullYear();
    this.createdDate.month = new Date(created).getMonth() + 1;
    this.createdDate.day = new Date(created).getDate();
    this.createdDateTime.hour = new Date(created).getHours();
    this.createdDateTime.minute = new Date(created).getMinutes();
  }

  saveDataOfTicketChange(): void {
    let newObjectTicket = { ...this.ticket };

    this.changeStateOfTicketDataByUid.emit({
      ...newObjectTicket,
      idGlobalOfPrevTicket: this.idGlobalOfPrevTicket,
      created: this.convertToTimestamp(),
      updated: new Date().getTime(),
      moneyInBoxFromPreviousTicket: this.moneyInBoxFromPreviousTicket,
    });
    this._ngbModalService.dismissAll();
  }

  convertToTimestamp(): number {
    const { year, month, day } = this.createdDate;
    const hours = this.createdDateTime.hour;
    const minutes = this.createdDateTime.minute;
    const seconds = new Date().getSeconds();
    const milliseconds = 0;

    const jsMonth = month - 1;
    const timestamp = new Date(
      year,
      jsMonth,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    ).getTime();

    return timestamp;
  }

  updateStatusOfTicketChange(event: any): void {
    this.changeStateOfTicketDataByUid.emit({
      ...this.ticket,
      itsChange: event.target.checked,
      status: event.target.checked === true ? 2 : 1,
    });
  }

  validateIfThePaymentCanBeCompleted(): boolean {
    const movements: Movement[] = this.getMovementsForCashRegister();
    if (movements.length > 0 && this.ticket.itsChange) {
      if (this.ticket.idGlobalOfPrevTicket) return true;
      return false;
    }
    if (movements.length > 0 && !this.ticket.itsChange) {
      return true;
    }
    return false;
  }

  async completePayment(): Promise<void> {
    try {
      const alertResponse = await this._alertsService.confirmation(
        'Completarás el pago'
      );
      if (alertResponse.isConfirmed) {
        this.saveTicketLoading = true;
        const ticketToSaveResponse: any =
          await this.getTicketCompleteDataToSave();
        if (ticketToSaveResponse === 'No hay conexión a internet') {
          throw ticketToSaveResponse;
        }

        if (
          typeof ticketToSaveResponse.globalTicketNumber !== 'number' ||
          typeof ticketToSaveResponse.dayTicketNumber !== 'number' ||
          isNaN(ticketToSaveResponse.globalTicketNumber) ||
          isNaN(ticketToSaveResponse.dayTicketNumber)
        ) {
          throw new Error(
            'globalTicketNumber y dayTicketNumber deben ser numéricos'
          );
        }

        if (ticketToSaveResponse && ticketToSaveResponse.dayTicketNumber > 0) {
          const ticketToSave: Ticket = ticketToSaveResponse;
          await this._ticketsService.saveTicket(ticketToSave);
          await this._movementsService.saveMovementsByTicket(
            this.getMovementsToSave(),
            ticketToSave.uid
          );
          this.paymentCompleted = true;
          this.saveTicketLoading = false;
          this._alertsService.toastAlert(
            'success',
            'Compra guardada con éxito'
          );
          this.printTicket(ticketToSave, this.getMovementsToSave());
        }
      }
    } catch (error) {
      this.saveTicketLoading = false;
      this._alertsService.toastAlert(
        'error',
        error === 'No hay conexión a internet'
          ? error
          : 'No se pudo guardar la compra'
      );
      this.closePaymentAndOnlyCleanTicketData(true);
    }
  }

  getMovementsToSave(): Movement[] {
    let movements: Movement[] = this.getMovementsForCashRegister();
    movements = movements.filter((m) => {
      const product: Product = this.getProduct(m.productUid);
      if (!product.notCountInInventory) {
        return m;
      }
    });
    // for (const mov of movements) {
    //   delete mov.cashRegister;
    // }
    return movements.map((m) => {
      const newMov: Movement = { ...m, costByUnit: this.getMovementPrice(m) };
      return newMov;
    });
  }

  async getTicketCompleteDataToSave(): Promise<Ticket> {
    try {
      let currentTicket: Ticket = this.ticket;
      if (!navigator.onLine) {
        throw 'No hay conexión a internet';
      }
      const lastTicketSave: Ticket | null =
        await this._ticketsService.getLastRecord();

      const lastTicketSaveOfToday: Ticket | null =
        await this._ticketsService.getTodayLastRecord();

      currentTicket.globalTicketNumber =
        lastTicketSave === null ? 1 : lastTicketSave.globalTicketNumber + 1;

      currentTicket.dayTicketNumber =
        lastTicketSaveOfToday === null
          ? 1
          : lastTicketSaveOfToday.dayTicketNumber + 1;

      currentTicket.subtotal = this.calculateSubTotal();
      currentTicket.total = this.calculateTotal();

      if (currentTicket.paymentMethod === 2) {
        currentTicket.cardPaymentMethodUid = this.cardPaymentMethodSelected;
      }

      let movements: Movement[] = this.getMovementsForCashRegister();
      movements = movements.filter((m) => {
        const product: Product = this.getProduct(m.productUid);
        if (product.notCountInInventory) {
          return m;
        }
      });
      if (movements.length > 0) {
        currentTicket.accumulatedSalesOfUninventoryedThings =
          this.calculateCustomTotal(movements);
      }

      currentTicket.created = new Date().getTime();

      this.changeStateOfTicketDataByUid.emit({
        ...currentTicket,
      });

      return currentTicket;
    } catch (error) {
      return error;
    }
  }

  showPaymentOptions(): void {
    this.paymentOptions = true;
  }

  revertPaymentOptions(): void {
    this.paymentOptions = false;
  }

  openPaymentModal(paymentSelected: number, paymentModalContent: any): void {
    let newObjectTicket = { ...this.ticket };

    this.changeStateOfTicketDataByUid.emit({
      ...newObjectTicket,
      paymentMethod: paymentSelected,
    });

    this.resetCompletePaymentData();

    if (paymentSelected === 2) {
      this._ticketsService
        .getCardPaymentMethods()
        .subscribe((cardPaymentMethods) => {
          this.cardPaymentMethods = cardPaymentMethods;
        });
    }

    this._ngbModalService.open(paymentModalContent, {
      backdrop: 'static',
      size: 'lg',
    });
  }

  closePayment(): void {
    this.resetCompletePaymentData();
    this.cleanCash.emit(this.cashRegister);
    this._ngbModalService.dismissAll();
    this.paymentCompleted = false;
    this.paymentOptions = false;
  }

  resetCompletePaymentData(): void {
    this.otherCantity = null;
    this.change = 0;
    this.billSelected = null;
    this.cardPaymentMethodSelected = null;
  }

  closePaymentAndOnlyCleanTicketData(all?: boolean): void {
    this.resetCompletePaymentData();
    this.cleanCashOnlyTicket.emit(this.cashRegister);
    this._ngbModalService.dismissAll();
    this.paymentCompleted = false;
    this.paymentOptions = false;
    if (all === true) {
      this.cleanCash.emit(this.cashRegister);
    }
  }

  selectCardPaymentMethod(uid: string): void {
    this.cardPaymentMethodSelected = uid;
  }

  getcardPaymentMethodName(): string {
    return this.cardPaymentMethods.find(
      (cpm) => cpm.uid === this.cardPaymentMethodSelected
    ).name;
  }

  async printTicket(ticket: Ticket, movements: Movement[]): Promise<void> {
    try {
      const printPromise = this._printTicketsService.printSale(
        ticket,
        this.categories,
        this.brands,
        movements,
        true
      );

      const maxTimeout = 5000;

      // Crear una promesa que se resuelve después del tiempo máximo de espera
      const timeoutPromise = new Promise<void>((_, reject) => {
        setTimeout(() => {
          reject(new Error('Tiempo de espera máximo excedido'));
        }, maxTimeout);
      });

      // Usar Promise.race para esperar la primera promesa que se resuelva
      await Promise.race([printPromise, timeoutPromise]);
    } catch (error) {
      this._alertsService.toastAlert('error', 'No se pudo imprimir el ticket');
    }
  }

  async findPrevTicket(): Promise<void> {
    try {
      this.prevTicket = null;
      this.loadingWhileSearchingForPreviousTicket = true;
      const prevTicketResponse: any =
        await this._ticketsService.getTicketByField(
          'globalTicketNumber',
          Number(this.idGlobalOfPrevTicket),
          '=='
        );
      if (prevTicketResponse && prevTicketResponse.length === 1) {
        this.prevTicket = prevTicketResponse[0];
        this.moneyInBoxFromPreviousTicket = this.prevTicket.total;
      } else {
        this.moneyInBoxFromPreviousTicket = 0;
      }
      this.loadingWhileSearchingForPreviousTicket = false;
    } catch (error) {
      this.loadingWhileSearchingForPreviousTicket = false;
    }
  }
}
